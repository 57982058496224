// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as $$Image from "../../../libs/Image.js";
import * as RRule from "../../../libs/RRule.js";
import * as Vessel from "../../vessel/Vessel.js";
import * as ApiMaker from "../../../libs/ApiMaker.js";
import * as Conditions from "../../common/Conditions.js";
import * as SectionType from "../../vessel/SectionType.js";
import * as UserProfile from "../../accounts/UserProfile.js";
import * as QuestionType from "../../survey/QuestionType.js";
import * as ComponentType from "../../vessel/ComponentType.js";
import * as InspectionAPI from "./InspectionAPI.js";
import * as ProcedureImage from "../../survey/ProcedureImage.js";

var fields = {
  TAG: "Object",
  _0: [
    [
      "id",
      "String"
    ],
    [
      "component_id",
      "String"
    ],
    [
      "name",
      "String"
    ],
    [
      "order",
      "Int"
    ],
    [
      "description",
      {
        TAG: "Optional",
        _0: "String"
      }
    ],
    [
      "position_diagram",
      {
        TAG: "Optional",
        _0: {
          TAG: "Deserializer",
          _0: {
            name: ProcedureImage.Deserializer.name,
            fromJSON: ProcedureImage.Deserializer.fromJSON,
            checkFieldsSanity: ProcedureImage.Deserializer.checkFieldsSanity
          }
        }
      }
    ],
    [
      "position_diagram_desc",
      {
        TAG: "Optional",
        _0: "String"
      }
    ],
    [
      "example_image",
      {
        TAG: "Optional",
        _0: {
          TAG: "Deserializer",
          _0: {
            name: ProcedureImage.Deserializer.name,
            fromJSON: ProcedureImage.Deserializer.fromJSON,
            checkFieldsSanity: ProcedureImage.Deserializer.checkFieldsSanity
          }
        }
      }
    ],
    [
      "example_image_desc",
      {
        TAG: "Optional",
        _0: "String"
      }
    ],
    [
      "question_type",
      QuestionType.Deserializer.fromJS
    ],
    [
      "rating_styling_rules_id",
      {
        TAG: "Optional",
        _0: "String"
      }
    ],
    [
      "require_image_conditions",
      {
        TAG: "DefaultWhenInvalid",
        _0: {
          TAG: "Optional",
          _0: {
            TAG: "Deserializer",
            _0: {
              name: Conditions.Deserializer.name,
              fromJSON: Conditions.Deserializer.fromJSON,
              checkFieldsSanity: Conditions.Deserializer.checkFieldsSanity
            }
          }
        },
        _1: undefined
      }
    ],
    [
      "recurrence",
      {
        TAG: "DefaultWhenInvalid",
        _0: {
          TAG: "Deserializer",
          _0: {
            name: RRule.Deserializer.name,
            fromJSON: RRule.Deserializer.fromJSON,
            checkFieldsSanity: RRule.Deserializer.checkFieldsSanity
          }
        },
        _1: RRule.Defaults.biweekly
      }
    ],
    [
      "ignore_overdue_status",
      {
        TAG: "DefaultWhenInvalid",
        _0: "Boolean",
        _1: false
      }
    ],
    [
      "vessel_position",
      {
        TAG: "Optional",
        _0: "String"
      }
    ],
    [
      "suggested_text_values",
      {
        TAG: "Optional",
        _0: {
          TAG: "Array",
          _0: "String"
        }
      }
    ],
    [
      "not_applicable_status",
      {
        TAG: "Optional",
        _0: "String"
      }
    ],
    [
      "allow_not_applicable_status",
      {
        TAG: "DefaultWhenInvalid",
        _0: "Boolean",
        _1: true
      }
    ],
    [
      "always_require_additional_annotation",
      {
        TAG: "DefaultWhenInvalid",
        _0: "Boolean",
        _1: false
      }
    ],
    [
      "rank",
      {
        TAG: "Optional",
        _0: UserProfile.Rank.deserFromJS
      }
    ]
  ]
};

var ProcedureDeserializerDef = {
  fields: fields
};

var ProcedureDeserializer = $$JSON.MakeDeserializer(ProcedureDeserializerDef);

var fields$1 = {
  TAG: "Object",
  _0: [
    [
      "id",
      "String"
    ],
    [
      "section_id",
      "String"
    ],
    [
      "name",
      "String"
    ],
    [
      "number",
      "Int"
    ],
    [
      "order",
      "Int"
    ],
    [
      "type",
      ComponentType.Deserializer.fromJS
    ],
    [
      "safety_type",
      {
        TAG: "DefaultWhenInvalid",
        _0: {
          TAG: "Optional",
          _0: ComponentType.Safety.Deserializer.fromJS
        },
        _1: undefined
      }
    ],
    [
      "suggested_annotations",
      {
        TAG: "Array",
        _0: "String"
      }
    ],
    [
      "require_image_conditions",
      {
        TAG: "DefaultWhenInvalid",
        _0: {
          TAG: "Optional",
          _0: {
            TAG: "Deserializer",
            _0: {
              name: Conditions.Deserializer.name,
              fromJSON: Conditions.Deserializer.fromJSON,
              checkFieldsSanity: Conditions.Deserializer.checkFieldsSanity
            }
          }
        },
        _1: undefined
      }
    ],
    [
      "inspection_procedures",
      {
        TAG: "Collection",
        _0: ProcedureDeserializer
      }
    ]
  ]
};

var ComponentDeserializerDef = {
  fields: fields$1
};

var ComponentDeserializer = $$JSON.MakeDeserializer(ComponentDeserializerDef);

var fields$2 = {
  TAG: "Object",
  _0: [
    [
      "id",
      "String"
    ],
    [
      "vessel_id",
      "String"
    ],
    [
      "name",
      "String"
    ],
    [
      "picture_prompt_every_n_inspections",
      {
        TAG: "Optional",
        _0: "Int"
      }
    ],
    [
      "type",
      SectionType.Deserializer.fromJS
    ],
    [
      "image",
      {
        TAG: "Optional",
        _0: "String"
      }
    ],
    [
      "rating_styling_rules_id",
      {
        TAG: "Optional",
        _0: "String"
      }
    ],
    [
      "require_image_conditions",
      {
        TAG: "DefaultWhenInvalid",
        _0: {
          TAG: "Optional",
          _0: {
            TAG: "Deserializer",
            _0: {
              name: Conditions.Deserializer.name,
              fromJSON: Conditions.Deserializer.fromJSON,
              checkFieldsSanity: Conditions.Deserializer.checkFieldsSanity
            }
          }
        },
        _1: undefined
      }
    ],
    [
      "components",
      {
        TAG: "Array",
        _0: {
          TAG: "Deserializer",
          _0: ComponentDeserializer
        }
      }
    ],
    [
      "guidance_document",
      {
        TAG: "Optional",
        _0: "String"
      }
    ],
    [
      "archived",
      {
        TAG: "OptionalWithDefault",
        _0: "Boolean",
        _1: false
      }
    ]
  ]
};

var SectionDeserializerDef = {
  fields: fields$2
};

var SectionDeserializer = $$JSON.MakeDeserializer(SectionDeserializerDef);

var fields$3 = {
  TAG: "Object",
  _0: [
    [
      "id",
      "String"
    ],
    [
      "name",
      "String"
    ],
    [
      "max_pic_resolution",
      {
        TAG: "OptionalWithDefault",
        _0: $$Image.Resolution.deserFromJS,
        _1: "FHD"
      }
    ],
    [
      "picture_prompt_every_n_inspections",
      {
        TAG: "OptionalWithDefault",
        _0: "Int",
        _1: 25
      }
    ],
    [
      "migrated",
      {
        TAG: "OptionalWithDefault",
        _0: "Boolean",
        _1: false
      }
    ],
    [
      "mandatory_findings_deadline",
      {
        TAG: "OptionalWithDefault",
        _0: "Boolean",
        _1: false
      }
    ],
    [
      "mandatory_findings_observations",
      {
        TAG: "OptionalWithDefault",
        _0: "Boolean",
        _1: false
      }
    ],
    [
      "hide_observations",
      {
        TAG: "OptionalWithDefault",
        _0: "Boolean",
        _1: true
      }
    ],
    [
      "company_logo",
      {
        TAG: "Optional",
        _0: "String"
      }
    ],
    [
      "sections",
      {
        TAG: "Array",
        _0: {
          TAG: "Deserializer",
          _0: SectionDeserializer
        }
      }
    ],
    [
      "use_embedded_camera",
      {
        TAG: "DefaultWhenInvalid",
        _0: Vessel.EmbeddedCameraConfig.deserFromJS,
        _1: Vessel.EmbeddedCameraConfig.$$default
      }
    ]
  ]
};

var VesselDeserializerDef = {
  fields: fields$3
};

var VesselDeserializer = $$JSON.MakeDeserializer(VesselDeserializerDef);

var fields$4 = {
  TAG: "Object",
  _0: [[
      "latest_inspections",
      {
        TAG: "Array",
        _0: {
          TAG: "Deserializer",
          _0: InspectionAPI.Read.Deserializer
        }
      }
    ]]
};

var TourDeserializerDef = {
  fields: fields$4
};

var TourDeserializer = $$JSON.MakeDeserializer(TourDeserializerDef);

var url = "/permissions/vessels";

var fields$5 = [
  [
    "vessel",
    {
      TAG: "Deserializer",
      _0: VesselDeserializer
    }
  ],
  [
    "tour",
    {
      TAG: "Deserializer",
      _0: TourDeserializer
    }
  ]
];

var EndpointDef = {
  url: url,
  fields: fields$5
};

var include = ApiMaker.MakeReadonlyAPIEndpoint({
      fields: fields$5,
      url: url
    });

var Deserializer = include.Deserializer;

var $$URL = include.$$URL;

var UnexpectedAPIPayload = include.UnexpectedAPIPayload;

var getMany = include.getMany;

var getAll = include.getAll;

var get = include.get;

var options = include.options;

export {
  ProcedureDeserializerDef ,
  ProcedureDeserializer ,
  ComponentDeserializerDef ,
  ComponentDeserializer ,
  SectionDeserializerDef ,
  SectionDeserializer ,
  VesselDeserializerDef ,
  VesselDeserializer ,
  TourDeserializerDef ,
  TourDeserializer ,
  EndpointDef ,
  Deserializer ,
  $$URL ,
  UnexpectedAPIPayload ,
  getMany ,
  getAll ,
  get ,
  options ,
}
/* fields Not a pure module */
