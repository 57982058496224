// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Webapi__Dom__Element from "../../../libs/rescript-webapi/src/Webapi/Dom/Webapi__Dom__Element.js";

function el(vnode) {
  var dom = vnode.dom;
  if (dom === undefined) {
    return ;
  }
  var dom$1 = Caml_option.valFromOption(dom);
  if (Prelude.default(vnode.domSize, 1) === 1) {
    return Webapi__Dom__Element.ofNode(dom$1);
  } else {
    return Caml_option.nullable_to_opt(dom$1.parentElement);
  }
}

function select(vnode, param) {
  var getId = Prelude.default(vnode.attrs.getName, (function (x) {
          return x.id;
        }));
  var selectedId = Mithril.$$Element.value(el(vnode));
  var selected = Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, vnode.attrs.items, (function (i) {
              return Caml_obj.equal(getId(i), selectedId);
            })));
  Curry._2(Prelude.OptionExported.$$Option.forEach, selected, vnode.attrs.onSelect);
  Mithril.setState(vnode, Curry._2(Prelude.OptionExported.$$Option.map, selected, getId));
}

function make(param) {
  return Mithril.view(Mithril.oncreate(Mithril.component(undefined), (function (vnode) {
                    vnode.state.data = vnode.attrs.selected;
                  })), (function (vnode) {
                var getId = Prelude.default(vnode.attrs.getName, (function (x) {
                        return x.id;
                      }));
                var getName = Prelude.default(vnode.attrs.getName, (function (x) {
                        return x.name;
                      }));
                var allowEmpty = Prelude.default(vnode.attrs.allowEmpty, true);
                var emptyMessage = Prelude.default(vnode.attrs.emptyMessage, "Select an option");
                var disabled = Prelude.default(vnode.attrs.disabled, false);
                var match = vnode.attrs.items;
                if (match.length !== 0) {
                  return ReactDOM.jsxs("select", {
                              children: [
                                allowEmpty ? ReactDOM.jsx("option", {
                                        children: emptyMessage,
                                        disabled: true,
                                        value: ""
                                      }) : Mithril.empty,
                                vnode.attrs.items.map(function (item) {
                                      return ReactDOM.jsxKeyed("option", {
                                                  children: getName(item),
                                                  value: getId(item)
                                                }, getId(item), undefined);
                                    })
                              ],
                              className: "form-select component-standard-selector",
                              id: vnode.attrs.id,
                              disabled: disabled,
                              name: "items",
                              value: Prelude.default(vnode.state.data, ""),
                              onChange: (function (extra) {
                                  return select(vnode, extra);
                                })
                            });
                } else {
                  return Mithril.empty;
                }
              }));
}

export {
  el ,
  select ,
  make ,
}
/* Mithril Not a pure module */
