// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";

function init(vnode) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return Mithril.setState(vnode, {
                TAG: "Loaded",
                _0: vnode.attrs.procedures.length
              });
  }
  
}

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), init), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object" || match._0 === 0) {
                  return Mithril.empty;
                } else {
                  return ReactDOM.jsxs("div", {
                              children: [
                                ReactDOM.jsx("h5", {
                                      children: "N/A"
                                    }),
                                ReactDOM.jsx("div", {
                                      children: "Notify shore that some procedures are either Not Available\n               or Not Applicable",
                                      className: "text-tiny"
                                    })
                              ],
                              className: "card card-shadow p-2"
                            });
                }
              }));
}

export {
  init ,
  make ,
}
/* Mithril Not a pure module */
