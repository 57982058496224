// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Webapi__Dom__EventTarget from "./Webapi__Dom__EventTarget.js";
import * as Webapi__Dom__GlobalEventHandlers from "./Webapi__Dom__GlobalEventHandlers.js";
import * as Webapi__WindowOrWorkerGlobalScope from "../Webapi__WindowOrWorkerGlobalScope.js";

function Impl(T) {
  return {};
}

Webapi__WindowOrWorkerGlobalScope.Impl({});

Webapi__Dom__EventTarget.Impl({});

Webapi__Dom__GlobalEventHandlers.Impl({});

export {
  Impl ,
}
/*  Not a pure module */
