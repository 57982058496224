// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppSchema from "../../AppSchema.js";
import * as Deficiency from "../../tasks/Deficiency.js";
import * as DeficiencyAPI from "../endpoints/DeficiencyAPI.js";
import * as ServerSyncPipeline from "../common/ServerSyncPipeline.js";

function convertDeficiency(state, deficiency) {
  var inspection = Prelude.$$Array.first(deficiency.inspections);
  if (inspection !== undefined) {
    var converted_id = deficiency.id;
    var converted_vessel_id = inspection.vessel_id;
    var converted_detected_with_id = deficiency.detected_with_id;
    var converted_resolved_with_id = deficiency.resolved_with_id;
    var converted_status = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, deficiency.resolved_with_id, (function (param) {
                return "rectified";
              })), "active");
    var converted_deadline = deficiency.deadline;
    var converted_comments = deficiency.comments;
    var converted_component_id = inspection.component_id;
    var converted_section_id = inspection.section_id;
    var converted_procedure_id = inspection.procedure_id;
    var converted = {
      id: converted_id,
      vessel_id: converted_vessel_id,
      detected_with_id: converted_detected_with_id,
      resolved_with_id: converted_resolved_with_id,
      status: converted_status,
      deadline: converted_deadline,
      comments: converted_comments,
      component_id: converted_component_id,
      section_id: converted_section_id,
      procedure_id: converted_procedure_id
    };
    var state$1 = AppSchema.Inspections.updateMany(state, deficiency.inspections);
    AppSchema.Deficiencies.update(state$1, converted);
  } else {
    console.error("Cannot save Finding without inspections", deficiency);
  }
  return state;
}

function convertDeficiencies(state, deficiencies) {
  return Curry._3(Prelude.$$Array.fold, deficiencies, state, convertDeficiency);
}

function $$do(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var deficiencyIds = Curry._2(Prelude.$$Array.keepMap, AppSchema.Inspections.all($$final), (function (inspection) {
            return inspection.deficiency_id;
          })).map(function (param) {
        return Curry._1(Deficiency.Id.toString, param);
      });
  if (Prelude.$$Array.isEmpty(deficiencyIds)) {
    return Promise.resolve({
                TAG: "Ok",
                _0: {
                  user: user,
                  vessels: vessels,
                  initial: initial,
                  final: $$final
                }
              });
  } else {
    return Prelude.PromisedResult.map(Prelude.PromisedResult.map(DeficiencyAPI.getMany(undefined, {
                        detected_with__vessel__id__in: vessels.join(",")
                      }, user, deficiencyIds), (function (extra) {
                      return convertDeficiencies($$final, extra);
                    })), (function ($$final) {
                  return {
                          user: user,
                          vessels: vessels,
                          initial: initial,
                          final: $$final
                        };
                }));
  }
}

var ReadServerState = {
  convertDeficiency: convertDeficiency,
  convertDeficiencies: convertDeficiencies,
  $$do: $$do
};

function $$do$1(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var deficiencyIds = Curry._2(Prelude.$$Array.keepMap, AppSchema.Inspections.all($$final), (function (inspection) {
          return inspection.deficiency_id;
        }));
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  return Prelude.PromisedResult.map(Query.Safe.read((newrecord.deficiencies = {
                    TAG: "In",
                    _0: deficiencyIds
                  }, newrecord.inspections = {
                    TAG: "In",
                    _0: AppSchema.Inspections.all($$final).map(function (param) {
                          return param.id;
                        })
                  }, newrecord)), (function (param) {
                Curry._2(Prelude.$$Array.forEach, param.deficiencies, (function (extra) {
                        return AppSchema.Deficiencies.update(initial, extra);
                      }));
                Curry._2(Prelude.$$Array.forEach, param.inspections, (function (extra) {
                        return AppSchema.Inspections.update(initial, extra);
                      }));
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var FillInitialStatus = {
  $$do: $$do$1
};

function $$do$2(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  newrecord.deficiencies = AppSchema.Deficiencies.getActions(initial, $$final);
  newrecord.inspections = AppSchema.Inspections.getActions(initial, $$final);
  Query.ActionLogging.info("ServerSyncFindings" + ": db actions: ", newrecord);
  return Prelude.PromisedResult.map(Query.Safe.write(newrecord), (function (param) {
                return {
                        user: user,
                        vessels: vessels,
                        initial: AppSchema.Deficiencies.updateMany(AppSchema.Inspections.updateMany(initial, AppSchema.Inspections.all($$final)), AppSchema.Deficiencies.all($$final)),
                        final: $$final
                      };
              }));
}

var UpdateDB = {
  $$do: $$do$2
};

var ApplyLocallyKnownState = ServerSyncPipeline.MakeNoOpPipelineStep({});

var views = [AppSchema.Deficiencies];

var Views = {
  views: views
};

var CollectFiles = ServerSyncPipeline.MakeFilesCollector(Views);

var CleanUpState = ServerSyncPipeline.MakeStandardCleanup(Views);

var include = ServerSyncPipeline.MakeStandardAPIPipeline({
      name: "ServerSyncFindings",
      ReadServerState: {
        $$do: $$do
      },
      FillInitialStatus: FillInitialStatus,
      ApplyLocallyKnownState: ApplyLocallyKnownState,
      UpdateDB: UpdateDB,
      CollectFiles: CollectFiles,
      CleanUpState: CleanUpState
    });

var $$do$3 = include.$$do;

export {
  ReadServerState ,
  FillInitialStatus ,
  UpdateDB ,
  $$do$3 as $$do,
}
/* include Not a pure module */
