// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$URL from "../../libs/URL.js";
import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as VesselId from "./VesselId.js";
import * as LocalFile from "../../libs/LocalFile.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";

var _map = {"ENABLED":"ENABLED","DISABLED":"DISABLED","CONDITIONAL":"CONDITIONAL"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

var deserFromJS = $$JSON.Field.variadicString("Expected a valid camera config", (function (x) {
        return _map[x];
      }));

var EmbeddedCameraConfig = {
  tToJs: tToJs,
  tFromJs: tFromJs,
  deserFromJS: deserFromJS,
  $$default: "CONDITIONAL"
};

var VesselDef = {};

var $$null = {
  checklist: false,
  structural: false,
  safety: false,
  companyForms: false,
  sire: false,
  sireVettingPeriods: false
};

var Modules = {
  $$null: $$null
};

var null_modules = $$null;

var null_use_embedded_camera = "CONDITIONAL";

var $$null$1 = {
  id: VesselId.$$null,
  name: "",
  max_pic_resolution: "ORIGINAL",
  picture_prompt_every_n_inspections: 25,
  modules: null_modules,
  migrated: false,
  mandatory_findings_deadline: false,
  mandatory_findings_observations: false,
  hide_observations: true,
  use_embedded_camera: null_use_embedded_camera
};

var Defaults = {
  Modules: Modules,
  $$null: $$null$1
};

var include = ReIndexed.MakeIdModel(VesselDef, {});

var IdComparator = include.IdComparator;

function getCompanyLogoFileUUID(param) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Curry._2(Prelude.OptionExported.$$Option.map, Curry._2(Prelude.OptionExported.$$Option.map, param.company_logo, $$URL.Utils.withoutSearch), (function (prim) {
                        return prim;
                      })), LocalFile.UUID.fromString), LocalFile.UUID.fromString("urn::vessel::logo::" + param.id));
}

function getImages(vessels) {
  return Curry._2(Prelude.$$Array.keepMap, vessels, (function (vessel) {
                  return Curry._2(Prelude.OptionExported.$$Option.map, vessel.company_logo, (function (param) {
                                return {
                                        id: getCompanyLogoFileUUID(vessel),
                                        file: vessel.company_logo,
                                        thumbnail: undefined
                                      };
                              }));
                })).map(function (v) {
              return {
                      TAG: "Cloud",
                      _0: v
                    };
            });
}

function ofSection(section) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, section, (function (section) {
                    return {
                            TAG: "Get",
                            _0: section.vessel_id
                          };
                  })), "NoOp");
}

function deduplicate(futures) {
  return Belt_Set.toArray(Belt_Set.mergeMany(Belt_Set.make(IdComparator), futures));
}

function getCameraConf(vessel) {
  return Prelude.default(vessel.use_embedded_camera, "CONDITIONAL");
}

var Identifier;

var clear = include.clear;

var save = include.save;

var $$delete = include.$$delete;

var remove = include.remove;

var Id = include.Id;

var Comparator;

export {
  Identifier ,
  EmbeddedCameraConfig ,
  VesselDef ,
  Defaults ,
  clear ,
  save ,
  $$delete ,
  remove ,
  IdComparator ,
  Id ,
  getCompanyLogoFileUUID ,
  getImages ,
  ofSection ,
  Comparator ,
  deduplicate ,
  getCameraConf ,
}
/* deserFromJS Not a pure module */
