// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Level from "../Level.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Answer from "../Answer.js";
import * as Feather from "../../common/views/Feather.js";
import * as Finding from "../Finding.js";
import * as LevelId from "../LevelId.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as AnswerId from "../AnswerId.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Question from "../Question.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as VesselId from "../../vessel/VesselId.js";
import * as ProfileId from "../../accounts/ProfileId.js";
import * as ListLayout from "../../common/views/ListLayout.js";
import * as LocalImage from "../../../libs/LocalImage.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CommentList from "../../comments/views/CommentList.js";
import * as UserProfile from "../../accounts/UserProfile.js";
import * as State__Memory from "../../state/State__Memory.js";

function update(vnode) {
  var answerId = AnswerId.Route.param("answerId");
  var levelId = LevelId.Route.param("levelId");
  var vesselId = VesselId.Route.param("vesselId");
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  Prelude.thenDo(Query.read((newrecord.answers = {
              TAG: "Get",
              _0: answerId
            }, newrecord.levels = {
              TAG: "Get",
              _0: levelId
            }, newrecord)), (function (param) {
          var levels = param.levels;
          var answer = Prelude.default(Prelude.$$Array.last(param.answers), Answer.Defaults.$$null);
          var level = Curry._2(Prelude.OptionExported.$$Option.getWithDefault, Prelude.$$Array.first(levels), Level.Defaults.$$null);
          var newrecord = Caml_obj.obj_dup(Query.makeRead());
          Prelude.thenDo(Query.read((newrecord.findings = Finding.createdBy(answerId), newrecord.questions = {
                      TAG: "Get",
                      _0: answer.question_id
                    }, newrecord.levelTypes = {
                      TAG: "Get",
                      _0: level.kind
                    }, newrecord.userProfiles = {
                      TAG: "Get",
                      _0: Prelude.default(answer.profile_id, ProfileId.$$null)
                    }, newrecord)), (function (param) {
                  Mithril.setState(vnode, {
                        TAG: "Loaded",
                        answer: answer,
                        question: Prelude.default(Prelude.$$Array.first(param.questions), Question.Defaults.$$null),
                        level: Curry._2(Prelude.OptionExported.$$Option.getWithDefault, Prelude.$$Array.first(levels), Level.Defaults.$$null),
                        userProfile: Prelude.$$Array.first(param.userProfiles),
                        vesselId: vesselId,
                        finding: Curry._2(Prelude.OptionExported.$$Option.getWithDefault, Prelude.$$Array.first(param.findings), Finding.Defaults.$$null),
                        kind: Prelude.$$Array.first(param.levelTypes)
                      });
                }));
        }));
}

function AnswerDetails(props) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    update(vnode);
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var kind = match.kind;
                var finding = match.finding;
                var vesselId = match.vesselId;
                var userProfile = match.userProfile;
                var level = match.level;
                var question = match.question;
                var answer = match.answer;
                var inspector = userProfile !== undefined ? UserProfile.nameWithRank(userProfile) : "Undefined";
                var evidence = Prelude.$$Array.first(answer.evidences);
                var currentProfile = State__Memory.Current.get().profile;
                var allowResolve = Answer.questionVisibilityGetter(question)(currentProfile, userProfile);
                var tmp;
                if (kind !== undefined) {
                  var match$1 = kind.kind;
                  tmp = match$1 === "sire" || match$1 === "sire_chapter" ? "Sire Chapter" : "Section: ";
                } else {
                  tmp = "Level: ";
                }
                return React.jsx(ListLayout.make, {
                            children: ReactDOM.jsx("div", {
                                  children: ReactDOM.jsxs("div", {
                                        children: [
                                          evidence !== undefined ? React.jsx(LocalImage.Resource.make, {
                                                  resource: evidence.id
                                                }) : Mithril.empty,
                                          ReactDOM.jsxs("div", {
                                                children: [
                                                  ReactDOM.jsx("strong", {
                                                        children: tmp
                                                      }),
                                                  ReactDOM.jsx("span", {
                                                        children: level.name,
                                                        className: "text-tiny mx-2"
                                                      })
                                                ]
                                              }),
                                          ReactDOM.jsxs("div", {
                                                children: [
                                                  ReactDOM.jsx("strong", {
                                                        children: "Question: "
                                                      }),
                                                  ReactDOM.jsx("span", {
                                                        children: question.name,
                                                        className: "text-tiny mx-2"
                                                      })
                                                ]
                                              }),
                                          ReactDOM.jsxs("div", {
                                                children: [
                                                  ReactDOM.jsx("strong", {
                                                        children: "Inspector: "
                                                      }),
                                                  ReactDOM.jsx("span", {
                                                        children: inspector,
                                                        className: "text-tiny mx-2"
                                                      })
                                                ]
                                              }),
                                          ReactDOM.jsxs("div", {
                                                children: [
                                                  ReactDOM.jsx("strong", {
                                                        children: "Remarks: "
                                                      }),
                                                  ReactDOM.jsx("span", {
                                                        children: answer.remarks,
                                                        className: "text-tiny mx-2"
                                                      })
                                                ]
                                              }),
                                          React.jsx(CommentList.make, {
                                                vessel_id: finding.vessel_id,
                                                object_app: Finding.appName,
                                                object_type: "finding",
                                                object_id: finding.id,
                                                comments: finding.comments
                                              })
                                        ],
                                        className: "column col-10 col-sm-12 col-mx-auto"
                                      }),
                                  className: "columns"
                                }),
                            title: "Finding Details",
                            showBackButton: true,
                            overNavbar: Caml_option.some(allowResolve ? ReactDOM.jsxs("button", {
                                        children: [
                                          "Rectify finding",
                                          React.jsx(Feather.make, {
                                                icon: "arrow_right",
                                                style: "margin-left: 0.2rem;"
                                              })
                                        ],
                                        className: "btn btn-block btn-success btn-shadow",
                                        onClick: (function (param) {
                                            Mithril$1.route.set(URLs.answerEditorUrl(true, true, level, question, vesselId), undefined, {
                                                  replace: true
                                                });
                                          })
                                      }) : Mithril.empty),
                            showSettings: true
                          });
              }));
}

var make = AnswerDetails;

export {
  update ,
  make ,
}
/* URLs Not a pure module */
