// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Completion from "../../common/Completion.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var completion = vnode.attrs.completion;
                var totalInspected = completion.done;
                var totalNested = completion.expected;
                var className = vnode.attrs.className;
                var counterOverlayClassName = vnode.attrs.counterOverlayClassName;
                var counterData = counterOverlayClassName !== undefined ? ({
                      incomplete: totalNested - totalInspected | 0,
                      completed: totalInspected,
                      expected: totalNested
                    }) : ({
                      dummy: 0
                    });
                return ReactDOM.jsx("div", {
                            children: Completion.Information.isFullyInspected(completion) ? React.jsx(Feather.make, {
                                    icon: "check_circle",
                                    className: "text-success"
                                  }) : ReactDOM.jsx("span", {
                                    children: String(totalInspected) + "/" + String(totalNested),
                                    className: completion.isOverdue ? "text-error" : ""
                                  }),
                            className: Mithril.$$class([
                                  [
                                    "tile-action",
                                    true
                                  ],
                                  [
                                    Prelude.default(counterOverlayClassName, ""),
                                    Curry._1(Prelude.OptionExported.$$Option.isSome, counterOverlayClassName)
                                  ],
                                  [
                                    Prelude.default(className, ""),
                                    Curry._1(Prelude.OptionExported.$$Option.isSome, className)
                                  ]
                                ], undefined),
                            data: counterData
                          });
              }));
}

export {
  make ,
}
/* React Not a pure module */
