// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ProfileId from "./ProfileId.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";

var _map = {"MASTER":"MASTER","CHIEF_OFFICER":"CHIEF_OFFICER","SECOND_OFFICER":"SECOND_OFFICER","THIRD_OFFICER":"THIRD_OFFICER","CHIEF_ENGINEER":"CHIEF_ENGINEER","SECOND_ENGINEER":"SECOND_ENGINEER","THIRD_ENGINEER":"THIRD_ENGINEER","FOURTH_ENGINEER":"FOURTH_ENGINEER","DECK_RATING":"DECK_RATING","ENGINE_RATING":"ENGINE_RATING","ETO_ELECTRICIAN":"ETO_ELECTRICIAN"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

function toString(rank) {
  if (rank === "DECK_RATING") {
    return "Deck Rating";
  } else if (rank === "FOURTH_ENGINEER") {
    return "Fourth Engineer";
  } else if (rank === "MASTER") {
    return "Master";
  } else if (rank === "ENGINE_RATING") {
    return "Engine Rating";
  } else if (rank === "CHIEF_OFFICER") {
    return "Chief Officer";
  } else if (rank === "SECOND_OFFICER") {
    return "Second Officer";
  } else if (rank === "THIRD_ENGINEER") {
    return "Third Engineer";
  } else if (rank === "CHIEF_ENGINEER") {
    return "Chief Engineer";
  } else if (rank === "SECOND_ENGINEER") {
    return "Second Engineer";
  } else if (rank === "ETO_ELECTRICIAN") {
    return "ETO/Electrician";
  } else {
    return "Third Officer";
  }
}

function fromString(rank) {
  switch (rank) {
    case "Chief Engineer" :
        return "CHIEF_ENGINEER";
    case "Chief Officer" :
        return "CHIEF_OFFICER";
    case "Deck Rating" :
        return "DECK_RATING";
    case "ETO/Electrician" :
        return "ETO_ELECTRICIAN";
    case "Engine Rating" :
        return "ENGINE_RATING";
    case "Fourth Engineer" :
        return "FOURTH_ENGINEER";
    case "Second Engineer" :
        return "SECOND_ENGINEER";
    case "Second Officer" :
        return "SECOND_OFFICER";
    case "Third Engineer" :
        return "THIRD_ENGINEER";
    case "Third Officer" :
        return "THIRD_OFFICER";
    default:
      return "MASTER";
  }
}

function toCompareValue(rank) {
  if (rank === "DECK_RATING") {
    return 300;
  } else if (rank === "FOURTH_ENGINEER") {
    return 400;
  } else if (rank === "MASTER") {
    return 1100;
  } else if (rank === "ENGINE_RATING") {
    return 200;
  } else if (rank === "CHIEF_OFFICER") {
    return 1000;
  } else if (rank === "SECOND_OFFICER") {
    return 900;
  } else if (rank === "THIRD_ENGINEER") {
    return 500;
  } else if (rank === "CHIEF_ENGINEER") {
    return 700;
  } else if (rank === "SECOND_ENGINEER") {
    return 600;
  } else if (rank === "ETO_ELECTRICIAN") {
    return 100;
  } else {
    return 800;
  }
}

function compareRank(rankA, rankB) {
  return Math.imul(-1, Caml.int_compare(toCompareValue(rankA), toCompareValue(rankB)));
}

var deserFromJS = $$JSON.Field.variadicString("Expected a valid Rank", (function (x) {
        return _map[x];
      }));

var Rank = {
  tToJs: tToJs,
  tFromJs: tFromJs,
  toString: toString,
  fromString: fromString,
  toCompareValue: toCompareValue,
  compareRank: compareRank,
  deserFromJS: deserFromJS
};

var ranks = [
  "MASTER",
  "CHIEF_OFFICER",
  "SECOND_OFFICER",
  "THIRD_OFFICER",
  "CHIEF_ENGINEER",
  "SECOND_ENGINEER",
  "THIRD_ENGINEER",
  "FOURTH_ENGINEER",
  "DECK_RATING",
  "ENGINE_RATING",
  "ETO_ELECTRICIAN"
];

var _map$1 = {"active":"active","inactive":"inactive","archived":"archived"};

function tToJs$1(param) {
  return param;
}

function tFromJs$1(param) {
  return _map$1[param];
}

var all = [
  "active",
  "inactive",
  "archived"
];

function toOptionText(state) {
  if (state === "archived") {
    return "Permanently remove profile";
  } else if (state === "active") {
    return "Active";
  } else {
    return "Inactive";
  }
}

var State = {
  tToJs: tToJs$1,
  tFromJs: tFromJs$1,
  all: all,
  toOptionText: toOptionText
};

var Definition = {};

var include = ReIndexed.MakeIdModel(Definition, {});

function name(profile) {
  var match = profile.first_name;
  var match$1 = profile.last_name;
  if (match === "") {
    if (match$1 === "") {
      return ;
    } else {
      return match$1;
    }
  } else if (match$1 === "") {
    return match;
  } else {
    return match + " " + match$1;
  }
}

function nameWithRank(profile) {
  return Prelude.default(name(profile), "") + ": " + toString(profile.rank);
}

function ofVessel(vesselId) {
  return {
          TAG: "Is",
          _0: "vessel",
          _1: vesselId
        };
}

function ofVessels(vesselIds) {
  return {
          TAG: "AnyOf",
          _0: "vessel",
          _1: vesselIds
        };
}

function make(user, vessel, first_name, last_name, avatars, rank, state) {
  return {
          id: ProfileId.make(),
          first_name: first_name,
          last_name: last_name,
          rank: rank,
          vessel: vessel,
          avatars: avatars,
          user: user,
          state: state
        };
}

function _getImages(wrapper, profiles) {
  return Prelude.$$Array.flatMap(profiles, (function (param) {
                  return param.avatars;
                })).map(function (param) {
              return wrapper({
                          id: param.id,
                          file: param.file,
                          thumbnail: param.thumbnail
                        });
            });
}

function getImages(extra) {
  return _getImages((function (i) {
                return {
                        TAG: "Cloud",
                        _0: i
                      };
              }), extra);
}

var Identifier;

var clear = include.clear;

var save = include.save;

var $$delete = include.$$delete;

var remove = include.remove;

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  Rank ,
  ranks ,
  State ,
  Identifier ,
  Definition ,
  clear ,
  save ,
  $$delete ,
  remove ,
  IdComparator ,
  Id ,
  name ,
  nameWithRank ,
  ofVessel ,
  ofVessels ,
  make ,
  _getImages ,
  getImages ,
}
/* deserFromJS Not a pure module */
