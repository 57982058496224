// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppSchema from "../../AppSchema.js";
import * as QuizzesAPI from "../endpoints/Questions/QuizzesAPI.js";
import * as IntQuestionsAPI from "../endpoints/Questions/IntQuestionsAPI.js";
import * as DateQuestionsAPI from "../endpoints/Questions/DateQuestionsAPI.js";
import * as TextQuestionsAPI from "../endpoints/Questions/TextQuestionsAPI.js";
import * as PhotoQuestionsAPI from "../endpoints/Questions/PhotoQuestionsAPI.js";
import * as YesNoQuestionsAPI from "../endpoints/Questions/YesNoQuestionsAPI.js";
import * as RatingQuestionsAPI from "../endpoints/Questions/RatingQuestionsAPI.js";
import * as ServerSyncPipeline from "../common/ServerSyncPipeline.js";
import * as OKNotOKQuestionsAPI from "../endpoints/Questions/OKNotOKQuestionsAPI.js";
import * as SignatureQuestionsAPI from "../endpoints/Questions/SignatureQuestionsAPI.js";
import * as WeightInfoQuestionsAPI from "../endpoints/Questions/WeightInfoQuestionsAPI.js";
import * as MultipleDatesQuestionsAPI from "../endpoints/Questions/MultipleDatesQuestionsAPI.js";
import * as MultipleTextsQuestionsAPI from "../endpoints/Questions/MultipleTextsQuestionsAPI.js";
import * as MultipleIntegersQuestionsAPI from "../endpoints/Questions/MultipleIntegersQuestionsAPI.js";
import * as MultipleDatesTextsQuestionsAPI from "../endpoints/Questions/MultipleDatesTextsQuestionsAPI.js";
import * as MultipleDatesYesNoQuestionsAPI from "../endpoints/Questions/MultipleDatesYesNoQuestionsAPI.js";
import * as MultipleDatesIntegersQuestionsAPI from "../endpoints/Questions/MultipleDatesIntegersQuestionsAPI.js";

function convertYesNoQuestion(state, question) {
  var appQuestion_id = question.id;
  var appQuestion_levels = question.levels;
  var appQuestion_level_ids = question.levels.map(function (l) {
        return l.level_id;
      });
  var appQuestion_level_paths = question.levels.map(function (l) {
        return l.level_path;
      });
  var appQuestion_vessel_id = question.vessel_id;
  var appQuestion_guidance_document_id = question.guidance_document_id;
  var appQuestion_name = question.name;
  var appQuestion_kind = {
    NAME: "YesNoQuestion",
    VAL: question.options
  };
  var appQuestion_require_image_conditions = question.require_image_conditions;
  var appQuestion_require_landscape_pictures = question.require_landscape_pictures;
  var appQuestion_answer_visibility = question.answer_visibility;
  var appQuestion_area = question.area;
  var appQuestion_ranks = question.ranks;
  var appQuestion = {
    id: appQuestion_id,
    levels: appQuestion_levels,
    level_ids: appQuestion_level_ids,
    level_paths: appQuestion_level_paths,
    vessel_id: appQuestion_vessel_id,
    guidance_document_id: appQuestion_guidance_document_id,
    name: appQuestion_name,
    kind: appQuestion_kind,
    require_image_conditions: appQuestion_require_image_conditions,
    require_landscape_pictures: appQuestion_require_landscape_pictures,
    answer_visibility: appQuestion_answer_visibility,
    area: appQuestion_area,
    ranks: appQuestion_ranks
  };
  AppSchema.Questions.update(state, appQuestion);
  return state;
}

function convertYesNoQuestions(state, questions) {
  return Curry._3(Prelude.$$Array.fold, questions, state, convertYesNoQuestion);
}

function convertOKNotOKQuestion(state, question) {
  var appQuestion_id = question.id;
  var appQuestion_levels = question.levels;
  var appQuestion_level_ids = question.levels.map(function (l) {
        return l.level_id;
      });
  var appQuestion_level_paths = question.levels.map(function (l) {
        return l.level_path;
      });
  var appQuestion_vessel_id = question.vessel_id;
  var appQuestion_guidance_document_id = question.guidance_document_id;
  var appQuestion_name = question.name;
  var appQuestion_kind = {
    NAME: "OKNotOKQuestion",
    VAL: question.options
  };
  var appQuestion_require_image_conditions = question.require_image_conditions;
  var appQuestion_require_landscape_pictures = question.require_landscape_pictures;
  var appQuestion_answer_visibility = question.answer_visibility;
  var appQuestion_area = question.area;
  var appQuestion_ranks = question.ranks;
  var appQuestion = {
    id: appQuestion_id,
    levels: appQuestion_levels,
    level_ids: appQuestion_level_ids,
    level_paths: appQuestion_level_paths,
    vessel_id: appQuestion_vessel_id,
    guidance_document_id: appQuestion_guidance_document_id,
    name: appQuestion_name,
    kind: appQuestion_kind,
    require_image_conditions: appQuestion_require_image_conditions,
    require_landscape_pictures: appQuestion_require_landscape_pictures,
    answer_visibility: appQuestion_answer_visibility,
    area: appQuestion_area,
    ranks: appQuestion_ranks
  };
  AppSchema.Questions.update(state, appQuestion);
  return state;
}

function convertOKNotOKQuestions(state, questions) {
  return Curry._3(Prelude.$$Array.fold, questions, state, convertOKNotOKQuestion);
}

function convertRatingQuestion(state, question) {
  var appQuestion_id = question.id;
  var appQuestion_levels = question.levels;
  var appQuestion_level_ids = question.levels.map(function (l) {
        return l.level_id;
      });
  var appQuestion_level_paths = question.levels.map(function (l) {
        return l.level_path;
      });
  var appQuestion_vessel_id = question.vessel_id;
  var appQuestion_guidance_document_id = question.guidance_document_id;
  var appQuestion_name = question.name;
  var appQuestion_kind = {
    NAME: "RatingQuestion",
    VAL: question.options
  };
  var appQuestion_require_image_conditions = question.require_image_conditions;
  var appQuestion_require_landscape_pictures = question.require_landscape_pictures;
  var appQuestion_answer_visibility = question.answer_visibility;
  var appQuestion_area = question.area;
  var appQuestion_ranks = question.ranks;
  var appQuestion = {
    id: appQuestion_id,
    levels: appQuestion_levels,
    level_ids: appQuestion_level_ids,
    level_paths: appQuestion_level_paths,
    vessel_id: appQuestion_vessel_id,
    guidance_document_id: appQuestion_guidance_document_id,
    name: appQuestion_name,
    kind: appQuestion_kind,
    require_image_conditions: appQuestion_require_image_conditions,
    require_landscape_pictures: appQuestion_require_landscape_pictures,
    answer_visibility: appQuestion_answer_visibility,
    area: appQuestion_area,
    ranks: appQuestion_ranks
  };
  AppSchema.Questions.update(state, appQuestion);
  return state;
}

function convertRatingQuestions(state, questions) {
  return Curry._3(Prelude.$$Array.fold, questions, state, convertRatingQuestion);
}

function convertTextQuestion(state, question) {
  var appQuestion_id = question.id;
  var appQuestion_levels = question.levels;
  var appQuestion_level_ids = question.levels.map(function (l) {
        return l.level_id;
      });
  var appQuestion_level_paths = question.levels.map(function (l) {
        return l.level_path;
      });
  var appQuestion_vessel_id = question.vessel_id;
  var appQuestion_guidance_document_id = question.guidance_document_id;
  var appQuestion_name = question.name;
  var appQuestion_require_image_conditions = question.require_image_conditions;
  var appQuestion_require_landscape_pictures = question.require_landscape_pictures;
  var appQuestion_answer_visibility = question.answer_visibility;
  var appQuestion_area = question.area;
  var appQuestion_ranks = question.ranks;
  var appQuestion = {
    id: appQuestion_id,
    levels: appQuestion_levels,
    level_ids: appQuestion_level_ids,
    level_paths: appQuestion_level_paths,
    vessel_id: appQuestion_vessel_id,
    guidance_document_id: appQuestion_guidance_document_id,
    name: appQuestion_name,
    kind: "TextQuestion",
    require_image_conditions: appQuestion_require_image_conditions,
    require_landscape_pictures: appQuestion_require_landscape_pictures,
    answer_visibility: appQuestion_answer_visibility,
    area: appQuestion_area,
    ranks: appQuestion_ranks
  };
  AppSchema.Questions.update(state, appQuestion);
  return state;
}

function convertTextQuestions(state, questions) {
  return Curry._3(Prelude.$$Array.fold, questions, state, convertTextQuestion);
}

function convertPhotoQuestion(state, question) {
  var appQuestion_id = question.id;
  var appQuestion_levels = question.levels;
  var appQuestion_level_ids = question.levels.map(function (l) {
        return l.level_id;
      });
  var appQuestion_level_paths = question.levels.map(function (l) {
        return l.level_path;
      });
  var appQuestion_vessel_id = question.vessel_id;
  var appQuestion_guidance_document_id = question.guidance_document_id;
  var appQuestion_name = question.name;
  var appQuestion_require_image_conditions = question.require_image_conditions;
  var appQuestion_require_landscape_pictures = question.require_landscape_pictures;
  var appQuestion_answer_visibility = question.answer_visibility;
  var appQuestion_area = question.area;
  var appQuestion_ranks = question.ranks;
  var appQuestion = {
    id: appQuestion_id,
    levels: appQuestion_levels,
    level_ids: appQuestion_level_ids,
    level_paths: appQuestion_level_paths,
    vessel_id: appQuestion_vessel_id,
    guidance_document_id: appQuestion_guidance_document_id,
    name: appQuestion_name,
    kind: "PhotoQuestion",
    require_image_conditions: appQuestion_require_image_conditions,
    require_landscape_pictures: appQuestion_require_landscape_pictures,
    answer_visibility: appQuestion_answer_visibility,
    area: appQuestion_area,
    ranks: appQuestion_ranks
  };
  AppSchema.Questions.update(state, appQuestion);
  return state;
}

function convertPhotoQuestions(state, questions) {
  return Curry._3(Prelude.$$Array.fold, questions, state, convertPhotoQuestion);
}

function convertSignatureQuestion(state, question) {
  var appQuestion_id = question.id;
  var appQuestion_levels = question.levels;
  var appQuestion_level_ids = question.levels.map(function (l) {
        return l.level_id;
      });
  var appQuestion_level_paths = question.levels.map(function (l) {
        return l.level_path;
      });
  var appQuestion_vessel_id = question.vessel_id;
  var appQuestion_guidance_document_id = question.guidance_document_id;
  var appQuestion_name = question.name;
  var appQuestion_require_image_conditions = question.require_image_conditions;
  var appQuestion_require_landscape_pictures = question.require_landscape_pictures;
  var appQuestion_answer_visibility = question.answer_visibility;
  var appQuestion_area = question.area;
  var appQuestion_ranks = question.ranks;
  var appQuestion = {
    id: appQuestion_id,
    levels: appQuestion_levels,
    level_ids: appQuestion_level_ids,
    level_paths: appQuestion_level_paths,
    vessel_id: appQuestion_vessel_id,
    guidance_document_id: appQuestion_guidance_document_id,
    name: appQuestion_name,
    kind: "SignatureQuestion",
    require_image_conditions: appQuestion_require_image_conditions,
    require_landscape_pictures: appQuestion_require_landscape_pictures,
    answer_visibility: appQuestion_answer_visibility,
    area: appQuestion_area,
    ranks: appQuestion_ranks
  };
  AppSchema.Questions.update(state, appQuestion);
  return state;
}

function convertSignatureQuestions(state, questions) {
  return Curry._3(Prelude.$$Array.fold, questions, state, convertSignatureQuestion);
}

function convertIntQuestion(state, question) {
  var appQuestion_id = question.id;
  var appQuestion_levels = question.levels;
  var appQuestion_level_ids = question.levels.map(function (l) {
        return l.level_id;
      });
  var appQuestion_level_paths = question.levels.map(function (l) {
        return l.level_path;
      });
  var appQuestion_vessel_id = question.vessel_id;
  var appQuestion_guidance_document_id = question.guidance_document_id;
  var appQuestion_name = question.name;
  var appQuestion_require_image_conditions = question.require_image_conditions;
  var appQuestion_require_landscape_pictures = question.require_landscape_pictures;
  var appQuestion_answer_visibility = question.answer_visibility;
  var appQuestion_area = question.area;
  var appQuestion_ranks = question.ranks;
  var appQuestion = {
    id: appQuestion_id,
    levels: appQuestion_levels,
    level_ids: appQuestion_level_ids,
    level_paths: appQuestion_level_paths,
    vessel_id: appQuestion_vessel_id,
    guidance_document_id: appQuestion_guidance_document_id,
    name: appQuestion_name,
    kind: "IntQuestion",
    require_image_conditions: appQuestion_require_image_conditions,
    require_landscape_pictures: appQuestion_require_landscape_pictures,
    answer_visibility: appQuestion_answer_visibility,
    area: appQuestion_area,
    ranks: appQuestion_ranks
  };
  AppSchema.Questions.update(state, appQuestion);
  return state;
}

function convertIntQuestions(state, questions) {
  return Curry._3(Prelude.$$Array.fold, questions, state, convertIntQuestion);
}

function convertDateQuestion(state, question) {
  var appQuestion_id = question.id;
  var appQuestion_levels = question.levels;
  var appQuestion_level_ids = question.levels.map(function (l) {
        return l.level_id;
      });
  var appQuestion_level_paths = question.levels.map(function (l) {
        return l.level_path;
      });
  var appQuestion_vessel_id = question.vessel_id;
  var appQuestion_guidance_document_id = question.guidance_document_id;
  var appQuestion_name = question.name;
  var appQuestion_answer_visibility = question.answer_visibility;
  var appQuestion_area = question.area;
  var appQuestion_ranks = question.ranks;
  var appQuestion = {
    id: appQuestion_id,
    levels: appQuestion_levels,
    level_ids: appQuestion_level_ids,
    level_paths: appQuestion_level_paths,
    vessel_id: appQuestion_vessel_id,
    guidance_document_id: appQuestion_guidance_document_id,
    name: appQuestion_name,
    kind: "DateQuestion",
    require_image_conditions: undefined,
    require_landscape_pictures: false,
    answer_visibility: appQuestion_answer_visibility,
    area: appQuestion_area,
    ranks: appQuestion_ranks
  };
  AppSchema.Questions.update(state, appQuestion);
  return state;
}

function convertDateQuestions(state, questions) {
  return Curry._3(Prelude.$$Array.fold, questions, state, convertDateQuestion);
}

function convertMultipleDateQuestion(state, question) {
  var appQuestion_id = question.id;
  var appQuestion_levels = question.levels;
  var appQuestion_level_ids = question.levels.map(function (l) {
        return l.level_id;
      });
  var appQuestion_level_paths = question.levels.map(function (l) {
        return l.level_path;
      });
  var appQuestion_vessel_id = question.vessel_id;
  var appQuestion_guidance_document_id = question.guidance_document_id;
  var appQuestion_name = question.name;
  var appQuestion_kind = {
    NAME: "MultipleDatesQuestion",
    VAL: question.options
  };
  var appQuestion_answer_visibility = question.answer_visibility;
  var appQuestion_area = question.area;
  var appQuestion_ranks = question.ranks;
  var appQuestion = {
    id: appQuestion_id,
    levels: appQuestion_levels,
    level_ids: appQuestion_level_ids,
    level_paths: appQuestion_level_paths,
    vessel_id: appQuestion_vessel_id,
    guidance_document_id: appQuestion_guidance_document_id,
    name: appQuestion_name,
    kind: appQuestion_kind,
    require_image_conditions: undefined,
    require_landscape_pictures: false,
    answer_visibility: appQuestion_answer_visibility,
    area: appQuestion_area,
    ranks: appQuestion_ranks
  };
  AppSchema.Questions.update(state, appQuestion);
  return state;
}

function convertMultipleDateQuestions(state, questions) {
  return Curry._3(Prelude.$$Array.fold, questions, state, convertMultipleDateQuestion);
}

function convertMultipleIntegersQuestion(state, question) {
  var appQuestion_id = question.id;
  var appQuestion_levels = question.levels;
  var appQuestion_level_ids = question.levels.map(function (l) {
        return l.level_id;
      });
  var appQuestion_level_paths = question.levels.map(function (l) {
        return l.level_path;
      });
  var appQuestion_vessel_id = question.vessel_id;
  var appQuestion_guidance_document_id = question.guidance_document_id;
  var appQuestion_name = question.name;
  var appQuestion_kind = {
    NAME: "MultipleIntegersQuestion",
    VAL: question.options
  };
  var appQuestion_answer_visibility = question.answer_visibility;
  var appQuestion_area = question.area;
  var appQuestion_ranks = question.ranks;
  var appQuestion = {
    id: appQuestion_id,
    levels: appQuestion_levels,
    level_ids: appQuestion_level_ids,
    level_paths: appQuestion_level_paths,
    vessel_id: appQuestion_vessel_id,
    guidance_document_id: appQuestion_guidance_document_id,
    name: appQuestion_name,
    kind: appQuestion_kind,
    require_image_conditions: undefined,
    require_landscape_pictures: false,
    answer_visibility: appQuestion_answer_visibility,
    area: appQuestion_area,
    ranks: appQuestion_ranks
  };
  AppSchema.Questions.update(state, appQuestion);
  return state;
}

function convertMultipleIntegersQuestions(state, questions) {
  return Curry._3(Prelude.$$Array.fold, questions, state, convertMultipleIntegersQuestion);
}

function convertMultipleTextsQuestion(state, question) {
  var appQuestion_id = question.id;
  var appQuestion_levels = question.levels;
  var appQuestion_level_ids = question.levels.map(function (l) {
        return l.level_id;
      });
  var appQuestion_level_paths = question.levels.map(function (l) {
        return l.level_path;
      });
  var appQuestion_vessel_id = question.vessel_id;
  var appQuestion_guidance_document_id = question.guidance_document_id;
  var appQuestion_name = question.name;
  var appQuestion_kind = {
    NAME: "MultipleTextsQuestion",
    VAL: question.options
  };
  var appQuestion_answer_visibility = question.answer_visibility;
  var appQuestion_area = question.area;
  var appQuestion_ranks = question.ranks;
  var appQuestion = {
    id: appQuestion_id,
    levels: appQuestion_levels,
    level_ids: appQuestion_level_ids,
    level_paths: appQuestion_level_paths,
    vessel_id: appQuestion_vessel_id,
    guidance_document_id: appQuestion_guidance_document_id,
    name: appQuestion_name,
    kind: appQuestion_kind,
    require_image_conditions: undefined,
    require_landscape_pictures: false,
    answer_visibility: appQuestion_answer_visibility,
    area: appQuestion_area,
    ranks: appQuestion_ranks
  };
  AppSchema.Questions.update(state, appQuestion);
  return state;
}

function convertMultipleTextsQuestions(state, questions) {
  return Curry._3(Prelude.$$Array.fold, questions, state, convertMultipleTextsQuestion);
}

function convertMultipleDatesYesNoQuestion(state, question) {
  var appQuestion_id = question.id;
  var appQuestion_levels = question.levels;
  var appQuestion_level_ids = question.levels.map(function (l) {
        return l.level_id;
      });
  var appQuestion_level_paths = question.levels.map(function (l) {
        return l.level_path;
      });
  var appQuestion_vessel_id = question.vessel_id;
  var appQuestion_guidance_document_id = question.guidance_document_id;
  var appQuestion_name = question.name;
  var appQuestion_kind = {
    NAME: "MultipleDatesYesNoQuestion",
    VAL: question.options
  };
  var appQuestion_answer_visibility = question.answer_visibility;
  var appQuestion_area = question.area;
  var appQuestion_ranks = question.ranks;
  var appQuestion = {
    id: appQuestion_id,
    levels: appQuestion_levels,
    level_ids: appQuestion_level_ids,
    level_paths: appQuestion_level_paths,
    vessel_id: appQuestion_vessel_id,
    guidance_document_id: appQuestion_guidance_document_id,
    name: appQuestion_name,
    kind: appQuestion_kind,
    require_image_conditions: undefined,
    require_landscape_pictures: false,
    answer_visibility: appQuestion_answer_visibility,
    area: appQuestion_area,
    ranks: appQuestion_ranks
  };
  AppSchema.Questions.update(state, appQuestion);
  return state;
}

function convertMultipleDatesYesNoQuestions(state, questions) {
  return Curry._3(Prelude.$$Array.fold, questions, state, convertMultipleDatesYesNoQuestion);
}

function convertMultipleDatesIntegersQuestion(state, question) {
  var appQuestion_id = question.id;
  var appQuestion_levels = question.levels;
  var appQuestion_level_ids = question.levels.map(function (l) {
        return l.level_id;
      });
  var appQuestion_level_paths = question.levels.map(function (l) {
        return l.level_path;
      });
  var appQuestion_vessel_id = question.vessel_id;
  var appQuestion_guidance_document_id = question.guidance_document_id;
  var appQuestion_name = question.name;
  var appQuestion_kind = {
    NAME: "MultipleDatesIntegersQuestion",
    VAL: question.options
  };
  var appQuestion_answer_visibility = question.answer_visibility;
  var appQuestion_area = question.area;
  var appQuestion_ranks = question.ranks;
  var appQuestion = {
    id: appQuestion_id,
    levels: appQuestion_levels,
    level_ids: appQuestion_level_ids,
    level_paths: appQuestion_level_paths,
    vessel_id: appQuestion_vessel_id,
    guidance_document_id: appQuestion_guidance_document_id,
    name: appQuestion_name,
    kind: appQuestion_kind,
    require_image_conditions: undefined,
    require_landscape_pictures: false,
    answer_visibility: appQuestion_answer_visibility,
    area: appQuestion_area,
    ranks: appQuestion_ranks
  };
  AppSchema.Questions.update(state, appQuestion);
  return state;
}

function convertMultipleDatesIntegersQuestions(state, questions) {
  return Curry._3(Prelude.$$Array.fold, questions, state, convertMultipleDatesIntegersQuestion);
}

function convertMultipleDatesTextsQuestion(state, question) {
  var appQuestion_id = question.id;
  var appQuestion_levels = question.levels;
  var appQuestion_level_ids = question.levels.map(function (l) {
        return l.level_id;
      });
  var appQuestion_level_paths = question.levels.map(function (l) {
        return l.level_path;
      });
  var appQuestion_vessel_id = question.vessel_id;
  var appQuestion_guidance_document_id = question.guidance_document_id;
  var appQuestion_name = question.name;
  var appQuestion_kind = {
    NAME: "MultipleDatesTextsQuestion",
    VAL: question.options
  };
  var appQuestion_answer_visibility = question.answer_visibility;
  var appQuestion_area = question.area;
  var appQuestion_ranks = question.ranks;
  var appQuestion = {
    id: appQuestion_id,
    levels: appQuestion_levels,
    level_ids: appQuestion_level_ids,
    level_paths: appQuestion_level_paths,
    vessel_id: appQuestion_vessel_id,
    guidance_document_id: appQuestion_guidance_document_id,
    name: appQuestion_name,
    kind: appQuestion_kind,
    require_image_conditions: undefined,
    require_landscape_pictures: false,
    answer_visibility: appQuestion_answer_visibility,
    area: appQuestion_area,
    ranks: appQuestion_ranks
  };
  AppSchema.Questions.update(state, appQuestion);
  return state;
}

function convertMultipleDatesTextsQuestions(state, questions) {
  return Curry._3(Prelude.$$Array.fold, questions, state, convertMultipleDatesTextsQuestion);
}

function convertQuiz(state, question) {
  var appQuestion_id = question.id;
  var appQuestion_levels = question.levels;
  var appQuestion_level_ids = question.levels.map(function (l) {
        return l.level_id;
      });
  var appQuestion_level_paths = question.levels.map(function (l) {
        return l.level_path;
      });
  var appQuestion_vessel_id = question.vessel_id;
  var appQuestion_guidance_document_id = question.guidance_document_id;
  var appQuestion_name = question.name;
  var appQuestion_kind = {
    NAME: "Quiz",
    VAL: question.options
  };
  var appQuestion_answer_visibility = question.answer_visibility;
  var appQuestion_area = question.area;
  var appQuestion_ranks = question.ranks;
  var appQuestion = {
    id: appQuestion_id,
    levels: appQuestion_levels,
    level_ids: appQuestion_level_ids,
    level_paths: appQuestion_level_paths,
    vessel_id: appQuestion_vessel_id,
    guidance_document_id: appQuestion_guidance_document_id,
    name: appQuestion_name,
    kind: appQuestion_kind,
    require_image_conditions: undefined,
    require_landscape_pictures: false,
    answer_visibility: appQuestion_answer_visibility,
    area: appQuestion_area,
    ranks: appQuestion_ranks
  };
  AppSchema.Questions.update(state, appQuestion);
  return state;
}

function convertQuizzes(state, questions) {
  return Curry._3(Prelude.$$Array.fold, questions, state, convertQuiz);
}

function convertWeightInfoQuestion(state, question) {
  var appQuestion_id = question.id;
  var appQuestion_levels = question.levels;
  var appQuestion_level_ids = question.levels.map(function (l) {
        return l.level_id;
      });
  var appQuestion_level_paths = question.levels.map(function (l) {
        return l.level_path;
      });
  var appQuestion_vessel_id = question.vessel_id;
  var appQuestion_guidance_document_id = question.guidance_document_id;
  var appQuestion_name = question.name;
  var appQuestion_kind = {
    NAME: "WeightInfoQuestion",
    VAL: question.options
  };
  var appQuestion_answer_visibility = question.answer_visibility;
  var appQuestion_area = question.area;
  var appQuestion_ranks = question.ranks;
  var appQuestion = {
    id: appQuestion_id,
    levels: appQuestion_levels,
    level_ids: appQuestion_level_ids,
    level_paths: appQuestion_level_paths,
    vessel_id: appQuestion_vessel_id,
    guidance_document_id: appQuestion_guidance_document_id,
    name: appQuestion_name,
    kind: appQuestion_kind,
    require_image_conditions: undefined,
    require_landscape_pictures: false,
    answer_visibility: appQuestion_answer_visibility,
    area: appQuestion_area,
    ranks: appQuestion_ranks
  };
  AppSchema.Questions.update(state, appQuestion);
  return state;
}

function convertWeightInfoQuestions(state, questions) {
  return Curry._3(Prelude.$$Array.fold, questions, state, convertWeightInfoQuestion);
}

function $$do(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var levels = AppSchema.Levels.all($$final);
  if (Prelude.$$Array.isEmpty(levels)) {
    return Promise.resolve({
                TAG: "Ok",
                _0: {
                  user: user,
                  vessels: vessels,
                  initial: initial,
                  final: $$final
                }
              });
  } else {
    return Prelude.PromisedResult.map(Prelude.PromisedResult.chain($$final, [
                    (function ($$final) {
                        return Prelude.PromisedResult.map(YesNoQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, user), (function (extra) {
                                      return convertYesNoQuestions($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(OKNotOKQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, user), (function (extra) {
                                      return convertOKNotOKQuestions($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(RatingQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, user), (function (extra) {
                                      return convertRatingQuestions($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(TextQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, user), (function (extra) {
                                      return convertTextQuestions($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(IntQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, user), (function (extra) {
                                      return convertIntQuestions($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(PhotoQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, user), (function (extra) {
                                      return convertPhotoQuestions($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(SignatureQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, user), (function (extra) {
                                      return convertSignatureQuestions($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(DateQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, user), (function (extra) {
                                      return convertDateQuestions($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(MultipleDatesQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, user), (function (extra) {
                                      return convertMultipleDateQuestions($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(MultipleDatesYesNoQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, user), (function (extra) {
                                      return convertMultipleDatesYesNoQuestions($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(MultipleDatesIntegersQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, user), (function (extra) {
                                      return convertMultipleDatesIntegersQuestions($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(MultipleDatesTextsQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, user), (function (extra) {
                                      return convertMultipleDatesTextsQuestions($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(MultipleIntegersQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, user), (function (extra) {
                                      return convertMultipleIntegersQuestions($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(MultipleTextsQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, user), (function (extra) {
                                      return convertMultipleTextsQuestions($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(QuizzesAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, user), (function (extra) {
                                      return convertQuizzes($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(WeightInfoQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, user), (function (extra) {
                                      return convertWeightInfoQuestions($$final, extra);
                                    }));
                      })
                  ]), (function ($$final) {
                  return {
                          user: user,
                          vessels: vessels,
                          initial: initial,
                          final: $$final
                        };
                }));
  }
}

var ReadServerState = {
  convertYesNoQuestion: convertYesNoQuestion,
  convertYesNoQuestions: convertYesNoQuestions,
  convertOKNotOKQuestion: convertOKNotOKQuestion,
  convertOKNotOKQuestions: convertOKNotOKQuestions,
  convertRatingQuestion: convertRatingQuestion,
  convertRatingQuestions: convertRatingQuestions,
  convertTextQuestion: convertTextQuestion,
  convertTextQuestions: convertTextQuestions,
  convertPhotoQuestion: convertPhotoQuestion,
  convertPhotoQuestions: convertPhotoQuestions,
  convertSignatureQuestion: convertSignatureQuestion,
  convertSignatureQuestions: convertSignatureQuestions,
  convertIntQuestion: convertIntQuestion,
  convertIntQuestions: convertIntQuestions,
  convertDateQuestion: convertDateQuestion,
  convertDateQuestions: convertDateQuestions,
  convertMultipleDateQuestion: convertMultipleDateQuestion,
  convertMultipleDateQuestions: convertMultipleDateQuestions,
  convertMultipleIntegersQuestion: convertMultipleIntegersQuestion,
  convertMultipleIntegersQuestions: convertMultipleIntegersQuestions,
  convertMultipleTextsQuestion: convertMultipleTextsQuestion,
  convertMultipleTextsQuestions: convertMultipleTextsQuestions,
  convertMultipleDatesYesNoQuestion: convertMultipleDatesYesNoQuestion,
  convertMultipleDatesYesNoQuestions: convertMultipleDatesYesNoQuestions,
  convertMultipleDatesIntegersQuestion: convertMultipleDatesIntegersQuestion,
  convertMultipleDatesIntegersQuestions: convertMultipleDatesIntegersQuestions,
  convertMultipleDatesTextsQuestion: convertMultipleDatesTextsQuestion,
  convertMultipleDatesTextsQuestions: convertMultipleDatesTextsQuestions,
  convertQuiz: convertQuiz,
  convertQuizzes: convertQuizzes,
  convertWeightInfoQuestion: convertWeightInfoQuestion,
  convertWeightInfoQuestions: convertWeightInfoQuestions,
  $$do: $$do
};

function $$do$1(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  return Prelude.PromisedResult.map(Query.Safe.read((newrecord.questions = {
                    TAG: "AnyOf",
                    _0: "vessel_id",
                    _1: vessels
                  }, newrecord)), (function (param) {
                Curry._2(Prelude.$$Array.forEach, param.questions, (function (extra) {
                        return AppSchema.Questions.update(initial, extra);
                      }));
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var FillInitialStatus = {
  $$do: $$do$1
};

function $$do$2(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  newrecord.questions = AppSchema.Questions.getActions(initial, $$final);
  Query.ActionLogging.info("ServerSyncQuestions", newrecord);
  return Prelude.PromisedResult.map(Query.Safe.write(newrecord), (function (param) {
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var UpdateDB = {
  $$do: $$do$2
};

var ApplyLocallyKnownState = ServerSyncPipeline.MakeNoOpPipelineStep({});

var views = [AppSchema.Questions];

var CollectFiles = ServerSyncPipeline.MakeFilesCollector({
      views: views
    });

var CleanUpState = ServerSyncPipeline.MakeNoOpPipelineStep({});

var include = ServerSyncPipeline.MakeStandardAPIPipeline({
      name: "ServerSyncQuestions",
      ReadServerState: {
        $$do: $$do
      },
      FillInitialStatus: FillInitialStatus,
      ApplyLocallyKnownState: ApplyLocallyKnownState,
      UpdateDB: UpdateDB,
      CollectFiles: CollectFiles,
      CleanUpState: CleanUpState
    });

var $$do$3 = include.$$do;

export {
  ReadServerState ,
  FillInitialStatus ,
  UpdateDB ,
  $$do$3 as $$do,
}
/* include Not a pure module */
