// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ApiMaker from "../../../libs/ApiMaker.js";
import * as UserProfile from "../../accounts/UserProfile.js";
import * as AttachmentAPI from "./AttachmentAPI.js";
import * as Browser from "@sentry/browser";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as SimpleSerializer from "../common/SimpleSerializer.js";

var SerializationError = /* @__PURE__ */Caml_exceptions.create("UserProfileAPI.SerializationError");

function convert(profile) {
  if (profile.first_name.trim() === "") {
    throw {
          RE_EXN_ID: SerializationError,
          code: 400,
          response: {},
          message: "Invalid first_name",
          Error: new Error()
        };
  }
  if (profile.last_name.trim() === "") {
    throw {
          RE_EXN_ID: SerializationError,
          code: 400,
          response: {},
          message: "Invalid last_name",
          Error: new Error()
        };
  }
  return {
          id: profile.id,
          first_name: profile.first_name,
          last_name: profile.last_name,
          rank: profile.rank,
          user: profile.user,
          vessel: profile.vessel,
          state: profile.state
        };
}

var Serializer = SimpleSerializer.MakeSimpleSerializer({
      convert: convert
    });

var fields = [
  [
    "id",
    "String"
  ],
  [
    "first_name",
    "String"
  ],
  [
    "last_name",
    "String"
  ],
  [
    "rank",
    "String"
  ],
  [
    "avatars",
    {
      TAG: "Collection",
      _0: AttachmentAPI.Basic.Deserializer
    }
  ],
  [
    "user",
    "String"
  ],
  [
    "vessel",
    "String"
  ],
  [
    "state",
    {
      TAG: "OptionalWithDefault",
      _0: $$JSON.Field.variadicString("Expected a profile state", (function (x) {
              return UserProfile.State.tFromJs(x);
            })),
      _1: "active"
    }
  ]
];

var Impl = ApiMaker.MakeAPIEndpoint({
      fields: fields,
      url: "/accounts/user-profiles"
    });

var InvalidProfile = /* @__PURE__ */Caml_exceptions.create("UserProfileAPI.InvalidProfile");

function post(user, profiles) {
  var profiles$1 = Prelude.ManyResults.bail(profiles.map(function (param) {
            return Curry._1(Serializer.serialize, param);
          }));
  if (profiles$1.TAG === "Ok") {
    return Impl.postMany(undefined, user, profiles$1._0);
  }
  var error = profiles$1._0;
  Browser.captureException(error);
  return Prelude.rejectWithError({
              RE_EXN_ID: InvalidProfile,
              code: 400,
              response: {
                value: Prelude.default(JSON.stringify(error), "Could not serialize")
              },
              message: Prelude.default(JSON.stringify(error), "Could not serialize")
            });
}

var getAll = Impl.getAll;

export {
  SerializationError ,
  Serializer ,
  Impl ,
  InvalidProfile ,
  post ,
  getAll ,
}
/* Serializer Not a pure module */
