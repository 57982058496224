// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Hashtbl from "rescript/lib/es6/hashtbl.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Webapi__Url from "./rescript-webapi/src/Webapi/Webapi__Url.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

var cmp = Caml_obj.compare;

var Comparator = Belt_Id.MakeComparable({
      cmp: cmp
    });

var hash = Hashtbl.hash;

var eq = Caml_obj.equal;

var Hasher = Belt_Id.MakeHashableU({
      hash: hash,
      eq: eq
    });

var DownloadError = /* @__PURE__ */Caml_exceptions.create("URL.DownloadError");

function fetcher(url, param) {
  return Prelude.catchResolve(Prelude.thenResolve(Prelude.then(fetch(url), (function (response) {
                        var status = response.status;
                        if (200 <= status && status < 300) {
                          return response.blob();
                        }
                        throw {
                              RE_EXN_ID: DownloadError,
                              _1: response,
                              Error: new Error()
                            };
                      })), (function (blob) {
                    return {
                            TAG: "Ok",
                            _0: blob
                          };
                  })), (function (error) {
                return {
                        TAG: "Error",
                        _0: error
                      };
              }));
}

var Internal = Webapi__Url;

function make(url) {
  return new URL(url);
}

function makeWith(url, base) {
  return new URL(url, base);
}

function hash$1(prim) {
  return prim.hash;
}

function setHash(prim0, prim1) {
  prim0.hash = prim1;
}

function host(prim) {
  return prim.host;
}

function setHost(prim0, prim1) {
  prim0.host = prim1;
}

function hostname(prim) {
  return prim.hostname;
}

function setHostname(prim0, prim1) {
  prim0.hostname = prim1;
}

function href(prim) {
  return prim.href;
}

function setHref(prim0, prim1) {
  prim0.href = prim1;
}

function origin(prim) {
  return prim.origin;
}

function password(prim) {
  return prim.password;
}

function setPassword(prim0, prim1) {
  prim0.password = prim1;
}

function pathname(prim) {
  return prim.pathname;
}

function setPathname(prim0, prim1) {
  prim0.pathname = prim1;
}

function port(prim) {
  return prim.port;
}

function setPort(prim0, prim1) {
  prim0.port = prim1;
}

function protocol(prim) {
  return prim.protocol;
}

function setProtocol(prim0, prim1) {
  prim0.protocol = prim1;
}

function search(prim) {
  return prim.search;
}

function setSearch(prim0, prim1) {
  prim0.search = prim1;
}

function searchParams(prim) {
  return prim.searchParams;
}

function username(prim) {
  return prim.username;
}

function setUsername(prim0, prim1) {
  prim0.username = prim1;
}

function toJson(prim0, prim1) {
  return prim0.toJSON();
}

function createObjectURL(prim) {
  return URL.createObjectURL(prim);
}

function revokeObjectURL(prim) {
  URL.revokeObjectURL(prim);
}

function withoutSearch(url) {
  var internal = new URL(url);
  internal.search = "";
  return internal.toString();
}

var Utils = {
  withoutSearch: withoutSearch
};

export {
  Comparator ,
  Hasher ,
  DownloadError ,
  fetcher ,
  Internal ,
  make ,
  makeWith ,
  hash$1 as hash,
  setHash ,
  host ,
  setHost ,
  hostname ,
  setHostname ,
  href ,
  setHref ,
  origin ,
  password ,
  setPassword ,
  pathname ,
  setPathname ,
  port ,
  setPort ,
  protocol ,
  setProtocol ,
  search ,
  setSearch ,
  searchParams ,
  username ,
  setUsername ,
  toJson ,
  createObjectURL ,
  revokeObjectURL ,
  Utils ,
}
/* Comparator Not a pure module */
