// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Forms from "../../common/Forms.js";
import * as Utils from "../../../libs/Utils.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as QuestionType from "../QuestionType.js";

var Id = Utils.MakeOpaqueIdentifier({});

function editInspection(vnode, $$event) {
  $$event.stopPropagation();
  var value = Curry._2(Prelude.OptionExported.$$Option.flatMap, $$event.target.value, Prelude.Int.fromString);
  vnode.state.data = value;
  vnode.attrs.setValue(value);
  var match = vnode.attrs.onEnter;
  var match$1 = $$event.key;
  if (match !== undefined && match$1 === "Enter") {
    return match(value);
  }
  
}

function make(param) {
  return Mithril.view(Mithril.onupdate(Mithril.oncreate(Mithril.oninit(Mithril.component(undefined), (function (vnode) {
                            var inspection = vnode.attrs.inspection;
                            Mithril.setState(vnode, QuestionType.Value.getInteger(inspection.value));
                          })), Forms.Input.focus), Forms.Input.focus), (function (vnode) {
                var procedure = vnode.attrs.procedure;
                var value = vnode.state.data;
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("label", {
                                    children: procedure.name,
                                    className: "form-label",
                                    htmlFor: "question-input-numeric"
                                  }),
                              ReactDOM.jsx("input", {
                                    className: "col-12",
                                    id: "question-input-numeric",
                                    autoComplete: "false",
                                    inputMode: "numeric",
                                    name: procedure.id,
                                    pattern: "\\d*",
                                    type: "text",
                                    value: Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, value, (function (v) {
                                                return String(v);
                                              })), ""),
                                    onKeyUp: (function (extra) {
                                        return editInspection(vnode, extra);
                                      })
                                  })
                            ],
                            className: "form-group"
                          });
              }));
}

export {
  Id ,
  editInspection ,
  make ,
}
/* Id Not a pure module */
