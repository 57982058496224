// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Conditional from "../../../libs/Conditional.js";
import * as PageAppView from "../../common/views/PageAppView.js";
import * as State__User from "../../state/State__User.js";
import * as UserProfile from "../UserProfile.js";
import * as ButtonAction from "../../common/components/ButtonAction.js";
import * as ProfileAvatar from "../components/ProfileAvatar.js";
import * as State__Memory from "../../state/State__Memory.js";
import * as FormInputProfile from "../components/FormInputProfile.js";
import * as TakePictureModal from "../../common/components/TakePictureModal.js";
import * as FormSelectProfile from "../components/FormSelectProfile.js";

function profileExists(curState) {
  if (typeof curState !== "object") {
    return false;
  }
  var rank = curState.rank;
  var lastName = curState.lastName;
  var firstName = curState.firstName;
  var vessel = curState.vessel;
  var user = curState.user;
  var curProfile = curState.curProfile;
  return Curry._2(Prelude.$$Array.some, curState.profiles, (function (p) {
                if (Caml_obj.notequal(p.id, curProfile.id) && p.first_name.toLowerCase() === firstName.toLowerCase() && p.last_name.toLowerCase() === lastName.toLowerCase() && Caml_obj.equal(p.user, user.id) && Caml_obj.equal(p.vessel, vessel.id)) {
                  return p.rank === rank;
                } else {
                  return false;
                }
              }));
}

function initialize(vnode) {
  var vesselId = Curry._2(Prelude.OptionExported.$$Option.getWithDefault, Mithril$1.route.param("vesselId"), "");
  var profileId = Curry._2(Prelude.OptionExported.$$Option.getWithDefault, Mithril$1.route.param("profileId"), "");
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  Prelude.thenDo(Query.read((newrecord.newUserProfiles = "All", newrecord.userProfiles = "All", newrecord.vessels = {
              TAG: "Get",
              _0: vesselId
            }, newrecord)), (function (param) {
          var newUserProfiles = param.newUserProfiles;
          var userProfiles = param.userProfiles;
          var vessels = param.vessels;
          Prelude.thenDo(State__User.getCurrent(), (function (user) {
                  if (user !== undefined) {
                    var vessel = Prelude.$$Array.first(vessels);
                    var profiles = Curry._2(Prelude.$$Array.keep, userProfiles.concat(newUserProfiles), (function (p) {
                            return Caml_obj.equal(p.vessel, vessel.id);
                          }));
                    var curProfile = Prelude.$$Array.last(profiles.filter(function (p) {
                              return Caml_obj.equal(p.id, profileId);
                            }));
                    if (curProfile !== undefined) {
                      return Mithril.setState(vnode, {
                                  TAG: "Loaded",
                                  profiles: profiles,
                                  curProfile: curProfile,
                                  user: user,
                                  vessel: vessel,
                                  firstName: curProfile.first_name,
                                  lastName: curProfile.last_name,
                                  rank: curProfile.rank,
                                  status: curProfile.state,
                                  image: "ProfileImage",
                                  exists: false,
                                  showModal: false
                                });
                    } else {
                      return Mithril.setState(vnode, "NotReady");
                    }
                  }
                  State__User.logout();
                }));
        }));
}

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), initialize), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return React.jsx(PageAppView.make, {
                              children: Mithril.empty,
                              headerContent: {
                                NAME: "Title",
                                VAL: "Edit Profile"
                              }
                            });
                }
                var exists = match.exists;
                var image = match.image;
                var status = match.status;
                var lastName = match.lastName;
                var firstName = match.firstName;
                var curProfile = match.curProfile;
                var hasChanges = curProfile.first_name !== firstName.trim() || curProfile.last_name !== lastName.trim() || curProfile.state !== status || image !== "ProfileImage";
                var saveReady = !exists && hasChanges && firstName.trim() !== "" && lastName.trim() !== "";
                var tmp;
                tmp = typeof image !== "object" ? (
                    image === "ProfileImage" ? React.jsx(ProfileAvatar.make, {
                            style: "profilePage",
                            profile: curProfile
                          }) : React.jsx(ProfileAvatar.make, {
                            style: "profilePage",
                            rank: curProfile.rank
                          })
                  ) : React.jsx(ProfileAvatar.make, {
                        style: "profilePage",
                        image: image._0
                      });
                return React.jsxs(PageAppView.make, {
                            children: [
                              React.jsx(TakePictureModal.make, {
                                    showModal: match.showModal,
                                    onClose: (function (_event) {
                                        var curState = vnode.state.data;
                                        if (typeof curState !== "object") {
                                          return ;
                                        } else {
                                          return Mithril.setState(vnode, {
                                                      TAG: "Loaded",
                                                      profiles: curState.profiles,
                                                      curProfile: curState.curProfile,
                                                      user: curState.user,
                                                      vessel: curState.vessel,
                                                      firstName: curState.firstName,
                                                      lastName: curState.lastName,
                                                      rank: curState.rank,
                                                      status: curState.status,
                                                      image: curState.image,
                                                      exists: curState.exists,
                                                      showModal: false
                                                    });
                                        }
                                      }),
                                    setImage: (function (extra, extra$1, extra$2) {
                                        return Prelude.thenDo(extra$2, (function () {
                                                      var curState = vnode.state.data;
                                                      if (typeof curState !== "object") {
                                                        return ;
                                                      } else {
                                                        return Mithril.setState(vnode, {
                                                                    TAG: "Loaded",
                                                                    profiles: curState.profiles,
                                                                    curProfile: curState.curProfile,
                                                                    user: curState.user,
                                                                    vessel: curState.vessel,
                                                                    firstName: curState.firstName,
                                                                    lastName: curState.lastName,
                                                                    rank: curState.rank,
                                                                    status: curState.status,
                                                                    image: {
                                                                      TAG: "TakenImage",
                                                                      _0: extra$1
                                                                    },
                                                                    exists: curState.exists,
                                                                    showModal: false
                                                                  });
                                                      }
                                                    }));
                                      }),
                                    setRankImage: (function (_event) {
                                        var curState = vnode.state.data;
                                        if (typeof curState !== "object") {
                                          return ;
                                        } else {
                                          return Mithril.setState(vnode, {
                                                      TAG: "Loaded",
                                                      profiles: curState.profiles,
                                                      curProfile: curState.curProfile,
                                                      user: curState.user,
                                                      vessel: curState.vessel,
                                                      firstName: curState.firstName,
                                                      lastName: curState.lastName,
                                                      rank: curState.rank,
                                                      status: curState.status,
                                                      image: "RankImage",
                                                      exists: curState.exists,
                                                      showModal: false
                                                    });
                                        }
                                      })
                                  }),
                              ReactDOM.jsxs("div", {
                                    children: [
                                      ReactDOM.jsxs("div", {
                                            children: [
                                              ReactDOM.jsx("div", {
                                                    children: tmp,
                                                    className: "tw-h-[180px] tw-w-[180px] tw-m-1"
                                                  }),
                                              React.jsx(ButtonAction.make, {
                                                    children: "Edit photo",
                                                    onClick: (function (_event) {
                                                        var curState = vnode.state.data;
                                                        if (typeof curState !== "object") {
                                                          return ;
                                                        } else {
                                                          return Mithril.setState(vnode, {
                                                                      TAG: "Loaded",
                                                                      profiles: curState.profiles,
                                                                      curProfile: curState.curProfile,
                                                                      user: curState.user,
                                                                      vessel: curState.vessel,
                                                                      firstName: curState.firstName,
                                                                      lastName: curState.lastName,
                                                                      rank: curState.rank,
                                                                      status: curState.status,
                                                                      image: curState.image,
                                                                      exists: curState.exists,
                                                                      showModal: true
                                                                    });
                                                        }
                                                      })
                                                  })
                                            ],
                                            className: Mithril.classList([
                                                  "tw-flex tw-flex-col tw-gap-1 tw-items-center",
                                                  "tw-mt-2.5 tw-mx-0 tw-mb-3"
                                                ], undefined)
                                          }),
                                      React.jsx(FormInputProfile.make, {
                                            title: "First Name",
                                            id: "first-name",
                                            value: firstName,
                                            placeholder: "Enter first name",
                                            onInput: (function (extra) {
                                                var value = extra.target.value;
                                                if (value === undefined) {
                                                  return ;
                                                }
                                                var value$1 = Caml_option.valFromOption(value);
                                                var curState = vnode.state.data;
                                                if (typeof curState !== "object") {
                                                  return ;
                                                }
                                                var exists = profileExists({
                                                      TAG: "Loaded",
                                                      profiles: curState.profiles,
                                                      curProfile: curState.curProfile,
                                                      user: curState.user,
                                                      vessel: curState.vessel,
                                                      firstName: value$1,
                                                      lastName: curState.lastName,
                                                      rank: curState.rank,
                                                      status: curState.status,
                                                      image: curState.image,
                                                      exists: curState.exists,
                                                      showModal: curState.showModal
                                                    });
                                                return Mithril.setState(vnode, {
                                                            TAG: "Loaded",
                                                            profiles: curState.profiles,
                                                            curProfile: curState.curProfile,
                                                            user: curState.user,
                                                            vessel: curState.vessel,
                                                            firstName: value$1,
                                                            lastName: curState.lastName,
                                                            rank: curState.rank,
                                                            status: curState.status,
                                                            image: curState.image,
                                                            exists: exists,
                                                            showModal: curState.showModal
                                                          });
                                              })
                                          }),
                                      React.jsx(FormInputProfile.make, {
                                            title: "Last Name",
                                            id: "last-name",
                                            value: lastName,
                                            placeholder: "Enter last name",
                                            onInput: (function (extra) {
                                                var value = extra.target.value;
                                                if (value === undefined) {
                                                  return ;
                                                }
                                                var value$1 = Caml_option.valFromOption(value);
                                                var curState = vnode.state.data;
                                                if (typeof curState !== "object") {
                                                  return ;
                                                }
                                                var exists = profileExists({
                                                      TAG: "Loaded",
                                                      profiles: curState.profiles,
                                                      curProfile: curState.curProfile,
                                                      user: curState.user,
                                                      vessel: curState.vessel,
                                                      firstName: curState.firstName,
                                                      lastName: value$1,
                                                      rank: curState.rank,
                                                      status: curState.status,
                                                      image: curState.image,
                                                      exists: curState.exists,
                                                      showModal: curState.showModal
                                                    });
                                                return Mithril.setState(vnode, {
                                                            TAG: "Loaded",
                                                            profiles: curState.profiles,
                                                            curProfile: curState.curProfile,
                                                            user: curState.user,
                                                            vessel: curState.vessel,
                                                            firstName: curState.firstName,
                                                            lastName: value$1,
                                                            rank: curState.rank,
                                                            status: curState.status,
                                                            image: curState.image,
                                                            exists: exists,
                                                            showModal: curState.showModal
                                                          });
                                              }),
                                            className: "tw-mt-2"
                                          }),
                                      React.jsx(FormSelectProfile.make, {
                                            id: "rank",
                                            title: "Rank",
                                            onChange: (function (_event) {
                                                
                                              }),
                                            options: [],
                                            default: {
                                              value: UserProfile.Rank.toString(match.rank),
                                              selected: true
                                            },
                                            disabled: true,
                                            className: "tw-mt-2"
                                          }),
                                      React.jsx(FormSelectProfile.make, {
                                            id: "status",
                                            title: "Status",
                                            onChange: (function (extra) {
                                                var value = extra.target.value;
                                                if (value === undefined) {
                                                  return ;
                                                }
                                                var newStatus = UserProfile.State.tFromJs(Caml_option.valFromOption(value));
                                                var match = vnode.state.data;
                                                if (typeof match !== "object") {
                                                  return ;
                                                }
                                                if (newStatus !== undefined) {
                                                  var exists = profileExists({
                                                        TAG: "Loaded",
                                                        profiles: match.profiles,
                                                        curProfile: match.curProfile,
                                                        user: match.user,
                                                        vessel: match.vessel,
                                                        firstName: match.firstName,
                                                        lastName: match.lastName,
                                                        rank: match.rank,
                                                        status: newStatus,
                                                        image: match.image,
                                                        exists: match.exists,
                                                        showModal: match.showModal
                                                      });
                                                  return Mithril.setState(vnode, {
                                                              TAG: "Loaded",
                                                              profiles: match.profiles,
                                                              curProfile: match.curProfile,
                                                              user: match.user,
                                                              vessel: match.vessel,
                                                              firstName: match.firstName,
                                                              lastName: match.lastName,
                                                              rank: match.rank,
                                                              status: newStatus,
                                                              image: match.image,
                                                              exists: exists,
                                                              showModal: match.showModal
                                                            });
                                                }
                                                console.error("Accounts_EditProfile", "Invalid status value", (function (prim) {
                                                        return prim.state.data;
                                                      }));
                                              }),
                                            options: UserProfile.State.all.map(function (s) {
                                                  return {
                                                          value: UserProfile.State.tToJs(s),
                                                          selected: s === status,
                                                          displayVal: UserProfile.State.toOptionText(s)
                                                        };
                                                }),
                                            className: "tw-mt-2"
                                          }),
                                      React.jsx(Conditional.make, {
                                            cond: exists,
                                            children: ReactDOM.jsx("div", {
                                                  children: "This profile already exists.",
                                                  className: "tw-text-error"
                                                })
                                          })
                                    ],
                                    className: "tw-flex tw-flex-col tw-pt-1 tw-px-3"
                                  }),
                              ReactDOM.jsx("div", {
                                    className: "tw-grow"
                                  }),
                              ReactDOM.jsx("div", {
                                    children: React.jsx(ButtonAction.make, {
                                          children: status !== "archived" ? "Save" : "Confirm permanent profile removal",
                                          onClick: (function (_event) {
                                              var curState = vnode.state.data;
                                              if (typeof curState !== "object") {
                                                return ;
                                              }
                                              var firstName = curState.firstName.trim();
                                              var lastName = curState.lastName.trim();
                                              var exists = profileExists({
                                                    TAG: "Loaded",
                                                    profiles: curState.profiles,
                                                    curProfile: curState.curProfile,
                                                    user: curState.user,
                                                    vessel: curState.vessel,
                                                    firstName: firstName,
                                                    lastName: lastName,
                                                    rank: curState.rank,
                                                    status: curState.status,
                                                    image: curState.image,
                                                    exists: curState.exists,
                                                    showModal: curState.showModal
                                                  });
                                              if (exists) {
                                                return Mithril.setState(vnode, {
                                                            TAG: "Loaded",
                                                            profiles: curState.profiles,
                                                            curProfile: curState.curProfile,
                                                            user: curState.user,
                                                            vessel: curState.vessel,
                                                            firstName: firstName,
                                                            lastName: lastName,
                                                            rank: curState.rank,
                                                            status: curState.status,
                                                            image: curState.image,
                                                            exists: exists,
                                                            showModal: curState.showModal
                                                          });
                                              }
                                              var init = curState.curProfile;
                                              var attachment = curState.image;
                                              var tmp;
                                              tmp = typeof attachment !== "object" ? (
                                                  attachment === "ProfileImage" ? curState.curProfile.avatars : []
                                                ) : [attachment._0];
                                              var profile_id = init.id;
                                              var profile_rank = init.rank;
                                              var profile_vessel = init.vessel;
                                              var profile_user = init.user;
                                              var profile_state = curState.status;
                                              var profile = {
                                                id: profile_id,
                                                first_name: firstName,
                                                last_name: lastName,
                                                rank: profile_rank,
                                                vessel: profile_vessel,
                                                avatars: tmp,
                                                user: profile_user,
                                                state: profile_state
                                              };
                                              var newrecord = Caml_obj.obj_dup(Query.makeWrite());
                                              Prelude.thenDo(Query.write((newrecord.newUserProfiles = [{
                                                            TAG: "Save",
                                                            _0: profile
                                                          }], newrecord)), (function (param) {
                                                      var match = profile_state;
                                                      if (match === "archived") {
                                                        State__Memory.Persisted.store(State__Memory.Transformations.run(State__Memory.Transformations.setToast(State__Memory.Transformations.removeCurrentProfile(State__Memory.Transformations.make(State__Memory.Current.get())), "Profile removed")));
                                                        Mithril$1.route.set(URLs.userProfiles());
                                                      } else if (match === "active") {
                                                        State__Memory.Persisted.store(State__Memory.Transformations.run(State__Memory.Transformations.setToast(State__Memory.Transformations.setCurrentProfile(State__Memory.Transformations.make(State__Memory.Current.get()), profile), "Profile activated")));
                                                        window.history.back();
                                                      } else {
                                                        State__Memory.Persisted.store(State__Memory.Transformations.run(State__Memory.Transformations.setToast(State__Memory.Transformations.removeCurrentProfile(State__Memory.Transformations.make(State__Memory.Current.get())), "Profile deactivated")));
                                                        Mithril$1.route.set(URLs.userProfiles());
                                                      }
                                                    }));
                                            }),
                                          disabled: !saveReady,
                                          style: saveReady ? "primary" : "gray"
                                        }),
                                    className: "tw-flex tw-pt-5 tw-px-3 tw-pb-4"
                                  })
                            ],
                            headerContent: {
                              NAME: "Title",
                              VAL: "Edit Profile"
                            },
                            headerNav: "all",
                            navbarDisabled: true
                          });
              }));
}

export {
  make ,
}
/* URLs Not a pure module */
