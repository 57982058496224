// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ApiMaker from "../../../libs/ApiMaker.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as SimpleSerializer from "../common/SimpleSerializer.js";

function convert(comment) {
  return {
          id: comment.id,
          replies_to_id: comment.replies_to_id,
          body: comment.body,
          author_id: comment.author.id,
          profile_id: Curry._2(Prelude.OptionExported.$$Option.map, comment.profile, (function (p) {
                  return p.id;
                })),
          object_app: comment.object_app,
          object_type: comment.object_type,
          object_id: comment.object_id,
          timestamp: comment.timestamp.toISOString()
        };
}

var Serializer = SimpleSerializer.MakeSimpleSerializer({
      convert: convert
    });

var fields = [[
    "id",
    "String"
  ]];

var Impl = ApiMaker.MakeAPIEndpoint({
      fields: fields,
      url: "/comments/"
    });

var InvalidLocalCommentData = /* @__PURE__ */Caml_exceptions.create("FindingCommentAPI.InvalidLocalCommentData");

function post(params, user, comments) {
  var comments$1 = Prelude.ManyResults.bail(comments.map(function (param) {
            return Curry._1(Serializer.serialize, param);
          }));
  if (comments$1.TAG === "Ok") {
    return Impl.postMany(params, user, comments$1._0);
  }
  var error = comments$1._0;
  return Prelude.rejectWithError({
              RE_EXN_ID: InvalidLocalCommentData,
              code: 400,
              response: {
                value: Prelude.default(JSON.stringify(error), "Could not serialize")
              },
              message: Prelude.default(JSON.stringify(error), "Could not serialize")
            });
}

export {
  Serializer ,
  Impl ,
  InvalidLocalCommentData ,
  post ,
}
/* Serializer Not a pure module */
