// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Level from "../Level.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Answer from "../Answer.js";
import * as Vessel from "../../vessel/Vessel.js";
import * as LevelId from "../LevelId.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Question from "../Question.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as QuestionId from "../QuestionId.js";
import * as QuizWidget from "./questions/QuizWidget.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as State__Memory from "../../state/State__Memory.js";
import * as IntQuestionWidget from "./questions/IntQuestionWidget.js";
import * as DateQuestionWidget from "./questions/DateQuestionWidget.js";
import * as TextQuestionWidget from "./questions/TextQuestionWidget.js";
import * as PhotoQuestionWidget from "./questions/PhotoQuestionWidget.js";
import * as YesNoQuestionWidget from "./questions/YesNoQuestionWidget.js";
import * as RatingQuestionWidget from "./questions/RatingQuestionWidget.js";
import * as OKNotOKQuestionWidget from "./questions/OKNotOKQuestionWidget.js";
import * as SignatureQuestionWidget from "./questions/SignatureQuestionWidget.js";
import * as WeightInfoQuestionWidget from "./questions/WeightInfoQuestionWidget.js";
import * as MultipleDatesQuestionWidget from "./questions/MultipleDatesQuestionWidget.js";
import * as MultipleTextsQuestionWidget from "./questions/MultipleTextsQuestionWidget.js";
import * as MultipleIntegersQuestionWidget from "./questions/MultipleIntegersQuestionWidget.js";
import * as MultipleDatesTextsQuestionWidget from "./questions/MultipleDatesTextsQuestionWidget.js";
import * as MultipleDatesYesNoQuestionWidget from "./questions/MultipleDatesYesNoQuestionWidget.js";
import * as MultipleDatesIntegersQuestionWidget from "./questions/MultipleDatesIntegersQuestionWidget.js";

function isAnswerVisible(question, answer, profiles, currentProfile) {
  return Answer.questionVisibilityGetter(question)(currentProfile, Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, profiles, (function (p) {
                        return Caml_obj.equal(Caml_option.some(p.id), answer.profile_id);
                      }))));
}

function getLastOfMap(question, getter, profiles, currentProfile) {
  return Prelude.$$Array.last(Curry._2(Prelude.$$Array.keep, Prelude.$$Array.sort(Prelude.default(QuestionId.$$Map.get(getter, question.id), []), (function (a) {
                        return a.timestamp;
                      }), undefined), (function (a) {
                    return isAnswerVisible(question, a, profiles, currentProfile);
                  })));
}

async function load(vnode) {
  var system = State__Memory.Current.get();
  var currentProfile = system.profile;
  var levelId = vnode.attrs.levelId;
  var vesselId = vnode.attrs.vesselId;
  var risksFilter = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("risks"), (function (p) {
          return Prelude.$$Array.keepSome(p.split(",").map(Level.riskStatusFromJs));
        }));
  var areasFilter = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("areas"), (function (p) {
          return Prelude.$$Array.keepSome(p.split(",").map(function (x) {
                          return Question.QuestionArea.fromJs(x);
                        }));
        }));
  var answeredFilter = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("answered"), (function (p) {
          return Prelude.$$Array.keepSome(p.split(",").map(function (x) {
                          return Question.Answered.fromJs(x);
                        }));
        }));
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match = await Query.read((newrecord.questions = Question.ofLevel(levelId), newrecord.levels = {
          TAG: "Get",
          _0: levelId
        }, newrecord.newUserProfiles = "All", newrecord.userProfiles = "All", newrecord.vessels = {
          TAG: "Get",
          _0: vesselId
        }, newrecord));
  var questions = match.questions;
  var questionsIds = Question.ids(questions);
  var newrecord$1 = Caml_obj.obj_dup(Query.makeRead());
  var match$1 = await Query.read((newrecord$1.newAnswers = {
          TAG: "And",
          _0: Answer.ofQuestions(questionsIds),
          _1: Answer.ofLevel(levelId)
        }, newrecord$1.answers = {
          TAG: "And",
          _0: Answer.ofQuestions(questionsIds),
          _1: Answer.ofLevel(levelId)
        }, newrecord$1));
  var profiles = Curry._2(Prelude.$$Array.concat, match.userProfiles, match.newUserProfiles);
  var questions$1 = Curry._2(Prelude.$$Array.keep, questions, (function (q) {
          if (Question.filterByQuestionArea(q, areasFilter)) {
            return Question.filterByQuestionRank(q, currentProfile);
          } else {
            return false;
          }
        }));
  var answersByQuestion = QuestionId.$$Array.group(match$1.answers, (function (i) {
          return i.question_id;
        }));
  var newAnswersByQuestion = QuestionId.$$Array.group(match$1.newAnswers, (function (i) {
          return i.question_id;
        }));
  var currentAnswers = Curry._2(Prelude.$$Array.keep, questions$1.map(function (q) {
            var lastAnswer = getLastOfMap(q, answersByQuestion, profiles, currentProfile);
            var lastNewAnswer = getLastOfMap(q, newAnswersByQuestion, profiles, currentProfile);
            if (Curry._1(Prelude.OptionExported.$$Option.isSome, lastNewAnswer)) {
              return lastNewAnswer;
            } else {
              return lastAnswer;
            }
          }), Prelude.OptionExported.$$Option.isSome);
  var questions$2;
  if (Prelude.$$Array.isNotEmpty(currentAnswers)) {
    var answeredQuestionIds = currentAnswers.map(function (a) {
          return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, a, (function (a) {
                            return a.question_id;
                          })), QuestionId.$$null);
        });
    questions$2 = Curry._2(Prelude.$$Array.keep, questions$1, (function (q) {
            return Question.filterByAnswered(q, answeredFilter, answeredQuestionIds);
          }));
  } else {
    questions$2 = questions$1;
  }
  var level = Prelude.default(Prelude.$$Array.first(match.levels), Level.Defaults.$$null);
  var vessel = Prelude.default(Prelude.$$Array.first(match.vessels), Vessel.Defaults.$$null);
  var newrecord$2 = Caml_obj.obj_dup(Query.makeRead());
  var match$2 = await Query.read((newrecord$2.newAnswers = Answer.ofVessel(vesselId), newrecord$2));
  return Mithril.setState(vnode, {
              TAG: "Loaded",
              vessel: vessel,
              level: level,
              lastAnswersQuestions: questions$2.map(function (q) {
                    return {
                            question: q,
                            lastNewAnswer: getLastOfMap(q, newAnswersByQuestion, profiles, currentProfile),
                            lastAnswer: getLastOfMap(q, answersByQuestion, profiles, currentProfile)
                          };
                  }),
              allNewAnswers: match$2.newAnswers,
              risksFilter: risksFilter,
              areasFilter: areasFilter,
              answeredFilter: answeredFilter
            });
}

function make(param) {
  return Mithril.view(Mithril.onupdate(Mithril.oninitAsync(Mithril.component("NotReady"), load), (function (vnode) {
                    var currentRisksFilter = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("risks"), (function (p) {
                            return Prelude.$$Array.keepSome(p.split(",").map(Level.riskStatusFromJs));
                          }));
                    var currentAreasFilter = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("areas"), (function (p) {
                            return Prelude.$$Array.keepSome(p.split(",").map(function (x) {
                                            return Question.QuestionArea.fromJs(x);
                                          }));
                          }));
                    var currentAnsweredFilter = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("answered"), (function (p) {
                            return Prelude.$$Array.keepSome(p.split(",").map(function (x) {
                                            return Question.Answered.fromJs(x);
                                          }));
                          }));
                    var currentLevel = LevelId.Route.param("levelId");
                    var match = vnode.state.data;
                    if (typeof match !== "object" || !(Caml_obj.notequal(currentLevel, match.level.id) || Caml_obj.notequal(match.risksFilter, currentRisksFilter) || Caml_obj.notequal(match.areasFilter, currentAreasFilter) || Caml_obj.notequal(match.answeredFilter, currentAnsweredFilter))) {
                      return ;
                    } else {
                      load(vnode);
                      return ;
                    }
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var lastAnswersQuestions = match.lastAnswersQuestions;
                var level = match.level;
                var vessel = match.vessel;
                if (Prelude.$$Array.isEmpty(lastAnswersQuestions)) {
                  return ReactDOM.jsx("div", {
                              children: ReactDOM.jsx("p", {
                                    children: "No results",
                                    className: "empty-title h5"
                                  }),
                              className: "empty"
                            });
                }
                var widgets = [ReactDOM.jsx("h5", {
                        children: level.name,
                        className: "p-2 chapter-title"
                      })];
                return Curry._2(Prelude.$$Array.concat, widgets, lastAnswersQuestions.map(function (param) {
                                var lastAnswer = param.lastAnswer;
                                var lastNewAnswer = param.lastNewAnswer;
                                var question = param.question;
                                var match = question.kind;
                                if (typeof match !== "object") {
                                  if (match === "PhotoQuestion") {
                                    return React.jsx(PhotoQuestionWidget.make, {
                                                question: question,
                                                level: level,
                                                vessel: vessel,
                                                lastNewAnswer: lastNewAnswer,
                                                lastAnswer: lastAnswer
                                              });
                                  } else if (match === "TextQuestion") {
                                    return React.jsx(TextQuestionWidget.make, {
                                                question: question,
                                                level: level,
                                                vessel: vessel,
                                                lastNewAnswer: lastNewAnswer,
                                                lastAnswer: lastAnswer
                                              });
                                  } else if (match === "DateQuestion") {
                                    return React.jsx(DateQuestionWidget.make, {
                                                question: question,
                                                level: level,
                                                vessel: vessel,
                                                lastNewAnswer: lastNewAnswer,
                                                lastAnswer: lastAnswer
                                              });
                                  } else if (match === "SignatureQuestion") {
                                    return React.jsx(SignatureQuestionWidget.make, {
                                                question: question,
                                                level: level,
                                                vessel: vessel,
                                                lastNewAnswer: lastNewAnswer,
                                                lastAnswer: lastAnswer
                                              });
                                  } else {
                                    return React.jsx(IntQuestionWidget.make, {
                                                question: question,
                                                level: level,
                                                vessel: vessel,
                                                lastNewAnswer: lastNewAnswer,
                                                lastAnswer: lastAnswer
                                              });
                                  }
                                }
                                var variant = match.NAME;
                                if (variant === "OKNotOKQuestion") {
                                  return React.jsx(OKNotOKQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else if (variant === "MultipleTextsQuestion") {
                                  return React.jsx(MultipleTextsQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else if (variant === "RatingQuestion") {
                                  return React.jsx(RatingQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else if (variant === "WeightInfoQuestion") {
                                  return React.jsx(WeightInfoQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else if (variant === "MultipleDatesTextsQuestion") {
                                  return React.jsx(MultipleDatesTextsQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else if (variant === "MultipleDatesYesNoQuestion") {
                                  return React.jsx(MultipleDatesYesNoQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else if (variant === "MultipleIntegersQuestion") {
                                  return React.jsx(MultipleIntegersQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else if (variant === "YesNoQuestion") {
                                  return React.jsx(YesNoQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else if (variant === "MultipleDatesQuestion") {
                                  return React.jsx(MultipleDatesQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else if (variant === "Quiz") {
                                  return React.jsx(QuizWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                } else {
                                  return React.jsx(MultipleDatesIntegersQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer
                                            });
                                }
                              }));
              }));
}

export {
  isAnswerVisible ,
  getLastOfMap ,
  load ,
  make ,
}
/* Level Not a pure module */
