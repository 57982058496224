// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as UploadStages__Filtering from "./stages/UploadStages__Filtering.js";
import * as UploadStages__Preparing from "./stages/UploadStages__Preparing.js";
import * as UploadStages__Requested from "./stages/UploadStages__Requested.js";
import * as UploadStages__Uploading from "./stages/UploadStages__Uploading.js";
import * as UploadStages__Finalizing from "./stages/UploadStages__Finalizing.js";

function next(stage) {
  switch (stage.TAG) {
    case "Requested" :
        return UploadStages__Requested.next(stage._0);
    case "Filtering" :
        return UploadStages__Filtering.next(stage._0);
    case "Preparing" :
        return UploadStages__Preparing.next(stage._0);
    case "Uploading" :
        return UploadStages__Uploading.next(stage.started, stage.uploaded, stage.size, stage.actionables, stage.results);
    case "Finalizing" :
        return UploadStages__Finalizing.next(stage._1);
    case "Done" :
        throw {
              RE_EXN_ID: Prelude.AssertionError,
              _1: "You should not call next on Done.",
              Error: new Error()
            };
    
  }
}

var Base;

var Requested;

var Filtering;

var Preparing;

var Uploading;

var Finalizing;

export {
  Base ,
  Requested ,
  Filtering ,
  Preparing ,
  Uploading ,
  Finalizing ,
  next ,
}
/* UploadStages__Filtering Not a pure module */
