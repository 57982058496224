// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Rating from "../../survey/Rating.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as LocalImage from "../../../libs/LocalImage.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as QuestionType from "../../survey/QuestionType.js";
import * as NewInspection from "../../survey/NewInspection.js";
import * as ProcedureAnswer from "../../survey/ProcedureAnswer.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var procedure = vnode.attrs.procedure;
                var inspection = vnode.attrs.inspection;
                var ratingStylingRules = vnode.attrs.ratingStylingRules;
                var match = procedure.question_type;
                var match$1 = procedure.question_type;
                var tmp;
                if (match$1 === "RatingQuestion") {
                  var match$2 = Rating.describe(QuestionType.Value.rate(inspection.value), ratingStylingRules);
                  tmp = ReactDOM.jsx("div", {
                        children: "(" + String(match$2.number) + ") " + match$2.text,
                        className: "label label-" + match$2.color
                      });
                } else if (match$1 === "WeightDeltaQuestion") {
                  var match$3 = ProcedureAnswer.description(ProcedureAnswer.fromRating(QuestionType.Value.rate(inspection.value)));
                  var color = match$3.color;
                  tmp = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, QuestionType.Value.getWeightDelta(inspection.value), (function (componentWeightValue) {
                              return ReactDOM.jsxs("div", {
                                          children: [
                                            componentWeightValue.manufacturer.trim().length > 0 ? ReactDOM.jsxs("div", {
                                                    children: [
                                                      ReactDOM.jsx("span", {
                                                            children: "Maker / Type: ",
                                                            className: "text-bold col-auto mr-2"
                                                          }),
                                                      ReactDOM.jsx("span", {
                                                            children: componentWeightValue.manufacturer,
                                                            className: "col-auto"
                                                          })
                                                    ],
                                                    className: "columns col-oneline"
                                                  }) : Mithril.empty,
                                            ReactDOM.jsxs("div", {
                                                  children: [
                                                    ReactDOM.jsx("span", {
                                                          children: "Initial weight: ",
                                                          className: "text-bold col-auto mr-2"
                                                        }),
                                                    ReactDOM.jsxs("span", {
                                                          children: [
                                                            componentWeightValue.initial_weight,
                                                            "kg"
                                                          ],
                                                          className: "col-auto"
                                                        })
                                                  ],
                                                  className: "columns col-oneline"
                                                }),
                                            ReactDOM.jsxs("div", {
                                                  children: [
                                                    ReactDOM.jsx("span", {
                                                          children: "Current weight: ",
                                                          className: "text-bold col-auto mr-1"
                                                        }),
                                                    ReactDOM.jsxs("span", {
                                                          children: [
                                                            componentWeightValue.current_weight,
                                                            "kg"
                                                          ],
                                                          className: "col-auto label label-" + color
                                                        })
                                                  ],
                                                  className: "columns col-oneline"
                                                }),
                                            inspection.additional_annotation.trim().length > 0 ? ReactDOM.jsxs("div", {
                                                    children: [
                                                      ReactDOM.jsx("span", {
                                                            children: "Comment: ",
                                                            className: "text-bold col-auto mr-2"
                                                          }),
                                                      ReactDOM.jsx("span", {
                                                            children: inspection.additional_annotation,
                                                            className: "col-auto",
                                                            style: {
                                                              width: "90%"
                                                            }
                                                          })
                                                    ],
                                                    className: "columns"
                                                  }) : Mithril.empty
                                          ],
                                          className: "text-left container p-2"
                                        });
                            })), Mithril.empty);
                } else if (match$1 === "OkNotOkQuestion") {
                  var match$4 = ProcedureAnswer.description(QuestionType.Value.getAnswer(inspection.value));
                  tmp = ReactDOM.jsx("div", {
                        children: match$4.text,
                        className: "label label-" + match$4.color
                      });
                } else if (match$1 === "YesNoQuestion") {
                  var match$5 = ProcedureAnswer.description(QuestionType.Value.getAnswer(inspection.value));
                  tmp = ReactDOM.jsx("div", {
                        children: Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, QuestionType.Value.getYesNo(inspection.value), (function (b) {
                                    if (b) {
                                      return "Yes";
                                    } else {
                                      return "No";
                                    }
                                  })), "No"),
                        className: "label label-" + match$5.color
                      });
                } else {
                  tmp = match$1 === "TextQuestion" ? ReactDOM.jsx("div", {
                          children: ReactDOM.jsx("pre", {
                                children: Prelude.default(QuestionType.Value.getText(inspection.value), ""),
                                className: "text-question-body text-small"
                              })
                        }) : (
                      match$1 === "DateQuestion" ? ReactDOM.jsx("div", {
                              children: Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, QuestionType.Value.getDate(inspection.value), (function (d) {
                                          return Prelude.default(Prelude.$$Array.first(d.toISOString().split("T")), "");
                                        })), "")
                            }) : (
                          match$1 === "PhotoAlbumQuestion" ? Mithril.empty : (
                              match$1 === "SignatureQuestion" ? Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, NewInspection.getSignature(inspection), (function (resource) {
                                            return ReactDOM.jsx("div", {
                                                        children: React.jsx(LocalImage.Resource.make, {
                                                              resource: resource
                                                            }),
                                                        className: "card-image"
                                                      });
                                          })), Mithril.empty) : ReactDOM.jsx("div", {
                                      children: Prelude.default(QuestionType.Value.getInteger(inspection.value), 0),
                                      className: "label"
                                    })
                            )
                        )
                    );
                }
                return ReactDOM.jsxs("div", {
                            children: [
                              Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, NewInspection.getMainImage(inspection), (function (resource) {
                                          return ReactDOM.jsx("div", {
                                                      children: React.jsx(LocalImage.Resource.make, {
                                                            resource: resource
                                                          }),
                                                      className: "card-image"
                                                    });
                                        })), Mithril.empty),
                              ReactDOM.jsxs("div", {
                                    children: [
                                      match === "WeightDeltaQuestion" ? ReactDOM.jsx("div", {
                                              children: React.jsx(Mithril.Link.make, {
                                                    href: URLs.startWeightMeasurementInspectionProcedure(procedure.id, Caml_option.some(Caml_option.some(inspection.id)), -1, true, undefined, undefined),
                                                    className: "btn btn-link btn-action float-right",
                                                    children: Caml_option.some(React.jsx(Feather.make, {
                                                              icon: "edit"
                                                            }))
                                                  })
                                            }) : (
                                          match === "SignatureQuestion" ? ReactDOM.jsx("div", {
                                                  children: React.jsx(Mithril.Link.make, {
                                                        href: URLs.startSignatureInspection(procedure.id, Caml_option.some(Caml_option.some(inspection.id)), true, undefined, undefined),
                                                        className: "btn btn-link btn-action float-right",
                                                        children: Caml_option.some(React.jsx(Feather.make, {
                                                                  icon: "edit"
                                                                }))
                                                      })
                                                }) : React.jsx(Mithril.Link.make, {
                                                  href: URLs.editInspection(inspection.id),
                                                  className: "btn btn-link btn-action float-right",
                                                  children: Caml_option.some(React.jsx(Feather.make, {
                                                            icon: "edit"
                                                          }))
                                                })
                                        ),
                                      ReactDOM.jsx("div", {
                                            children: procedure.name,
                                            className: "card-subtitle text-small"
                                          })
                                    ],
                                    className: "card-header"
                                  }),
                              ReactDOM.jsx("div", {
                                    children: tmp,
                                    className: "card-body p-centered text-small text-center"
                                  })
                            ],
                            className: "card card-shadow m-2"
                          });
              }));
}

export {
  make ,
}
/* URLs Not a pure module */
