// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as ButtonFilterModal from "../../common/components/ButtonFilterModal.js";
import * as FilterModalContainer from "../../common/components/FilterModalContainer.js";

function anyFilter(state) {
  if (state.hardware || state.human || state.process || state.high || state.medium || state.low || state.answered) {
    return true;
  } else {
    return state.unanswered;
  }
}

function isOn(filter, state) {
  if (filter === "answered") {
    return state.answered;
  } else if (filter === "unanswered") {
    return state.unanswered;
  } else if (filter === "process") {
    return state.process;
  } else if (filter === "hardware") {
    return state.hardware;
  } else if (filter === "medium") {
    return state.medium;
  } else if (filter === "low") {
    return state.low;
  } else if (filter === "human") {
    return state.human;
  } else {
    return state.high;
  }
}

function activeFilters(state) {
  var filtersOn = [];
  if (state.hardware) {
    filtersOn.push("hardware");
  }
  if (state.human) {
    filtersOn.push("human");
  }
  if (state.process) {
    filtersOn.push("process");
  }
  if (state.high) {
    filtersOn.push("high");
  }
  if (state.medium) {
    filtersOn.push("medium");
  }
  if (state.low) {
    filtersOn.push("low");
  }
  if (state.answered) {
    filtersOn.push("answered");
  }
  if (state.unanswered) {
    filtersOn.push("unanswered");
  }
  return filtersOn;
}

function toString(filter) {
  if (filter === "answered") {
    return "Answered";
  } else if (filter === "unanswered") {
    return "Unanswered";
  } else if (filter === "process") {
    return "Process";
  } else if (filter === "hardware") {
    return "Hardware";
  } else if (filter === "medium") {
    return "Medium / Rotational I";
  } else if (filter === "low") {
    return "Low / Rotational II";
  } else if (filter === "human") {
    return "Human";
  } else {
    return "High / Core";
  }
}

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                var onClose = match.onClose;
                var filterUpdate = match.filterUpdate;
                var filterState = match.filterState;
                var toButtonFilter = function (filter, icon) {
                  var onTheme = ButtonFilterModal.Theme.on;
                  var offTheme = ButtonFilterModal.Theme.off;
                  return React.jsx(ButtonFilterModal.make, {
                              text: toString(filter),
                              onClick: (function (_event) {
                                  filterUpdate(filter, !isOn(filter, filterState));
                                }),
                              icon: icon,
                              theme: isOn(filter, filterState) ? onTheme : offTheme
                            });
                };
                return React.jsx(FilterModalContainer.make, {
                            children: ReactDOM.jsxs("div", {
                                  children: [
                                    ReactDOM.jsxs("div", {
                                          children: [
                                            ReactDOM.jsx("div", {
                                                  children: ReactDOM.jsx("label", {
                                                        children: "Area:",
                                                        className: "tw-font-medium"
                                                      }),
                                                  className: "tw-flex tw-flex-row"
                                                }),
                                            ReactDOM.jsx("div", {
                                                  children: toButtonFilter("hardware", "hardware"),
                                                  className: "tw-flex tw-flex-row"
                                                }),
                                            ReactDOM.jsx("div", {
                                                  children: toButtonFilter("human", "hr"),
                                                  className: "tw-flex tw-flex-row"
                                                }),
                                            ReactDOM.jsx("div", {
                                                  children: toButtonFilter("process", "process"),
                                                  className: "tw-flex tw-flex-row"
                                                })
                                          ],
                                          className: "tw-flex tw-flex-col tw-justify-start tw-gap-2"
                                        }),
                                    ReactDOM.jsxs("div", {
                                          children: [
                                            ReactDOM.jsx("div", {
                                                  children: ReactDOM.jsx("label", {
                                                        children: "Type/Risk:",
                                                        className: "tw-font-medium"
                                                      }),
                                                  className: "tw-flex tw-flex-row"
                                                }),
                                            ReactDOM.jsx("div", {
                                                  children: toButtonFilter("high", undefined),
                                                  className: "tw-flex tw-flex-row"
                                                }),
                                            ReactDOM.jsx("div", {
                                                  children: toButtonFilter("medium", undefined),
                                                  className: "tw-flex tw-flex-row"
                                                }),
                                            ReactDOM.jsx("div", {
                                                  children: toButtonFilter("low", undefined),
                                                  className: "tw-flex tw-flex-row"
                                                })
                                          ],
                                          className: "tw-flex tw-flex-col tw-justify-start tw-gap-2"
                                        }),
                                    ReactDOM.jsxs("div", {
                                          children: [
                                            ReactDOM.jsx("div", {
                                                  children: ReactDOM.jsx("label", {
                                                        children: "Status:",
                                                        className: "tw-font-medium"
                                                      }),
                                                  className: "tw-flex tw-flex-row"
                                                }),
                                            ReactDOM.jsxs("div", {
                                                  children: [
                                                    toButtonFilter("answered", undefined),
                                                    toButtonFilter("unanswered", undefined)
                                                  ],
                                                  className: "tw-flex tw-flex-row tw-justify-start tw-gap-2"
                                                })
                                          ],
                                          className: "tw-flex tw-flex-col tw-justify-start tw-gap-2"
                                        })
                                  ],
                                  className: "tw-flex tw-flex-col tw-justify-start tw-gap-4"
                                }),
                            showModal: match.showModal,
                            textApplyButton: "Apply",
                            onClear: match.onClear,
                            onClose: onClose,
                            onApplyFilter: onClose
                          });
              }));
}

var Filter_defaultFalse = {
  hardware: false,
  human: false,
  process: false,
  high: false,
  medium: false,
  low: false,
  answered: false,
  unanswered: false
};

var Filter = {
  defaultFalse: Filter_defaultFalse,
  anyFilter: anyFilter,
  activeFilters: activeFilters,
  toString: toString
};

export {
  Filter ,
  make ,
}
/* React Not a pure module */
