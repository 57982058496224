// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$URL from "../../libs/URL.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Component from "./Component.js";
import * as LocalFile from "../../libs/LocalFile.js";
import * as Procedure from "../survey/Procedure.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as SectionId from "./SectionId.js";
import * as Completion from "../common/Completion.js";

var SectionDef = {};

var include = ReIndexed.MakeIdModel(SectionDef, {});

function _getSectionLocalFileUUID(param) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Curry._2(Prelude.OptionExported.$$Option.map, Curry._2(Prelude.OptionExported.$$Option.map, param.image, $$URL.Utils.withoutSearch), (function (prim) {
                        return prim;
                      })), LocalFile.UUID.fromString), LocalFile.UUID.fromString("urn::section::" + param.id));
}

function normalizeURLs(record) {
  return {
          id: record.id,
          vessel_id: record.vessel_id,
          name: record.name,
          type: record.type,
          image: Curry._2(Prelude.OptionExported.$$Option.map, record.image, $$URL.Utils.withoutSearch),
          picture_prompt_every_n_inspections: record.picture_prompt_every_n_inspections,
          rating_styling_rules_id: record.rating_styling_rules_id,
          require_image_conditions: record.require_image_conditions,
          guidance_document: record.guidance_document,
          archived: record.archived
        };
}

function ofVessel(vesselId) {
  return {
          TAG: "Is",
          _0: "vessel_id",
          _1: vesselId
        };
}

function ofComponent(component) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, component, (function (component) {
                    return {
                            TAG: "Get",
                            _0: component.section_id
                          };
                  })), "NoOp");
}

function unarchived(sections) {
  return Curry._2(Prelude.$$Array.keep, sections, (function (s) {
                return !s.archived;
              }));
}

function ids(__x) {
  return __x.map(function (section) {
              return section.id;
            });
}

function byName(section) {
  return section.name.toLowerCase();
}

function getImage(section) {
  return {
          id: _getSectionLocalFileUUID(section),
          file: section.image,
          thumbnail: undefined
        };
}

function getImages(sections) {
  return Curry._2(Prelude.$$Array.keepMap, sections, (function (section) {
                return Curry._2(Prelude.OptionExported.$$Option.map, section.image, (function (param) {
                              return {
                                      TAG: "Cloud",
                                      _0: getImage(section)
                                    };
                            }));
              }));
}

function isAnyChecklist(sections) {
  return Curry._2(Prelude.$$Array.some, sections, (function (section) {
                return section.type === "checklist";
              }));
}

function getCompletion(section, components, procedures) {
  var components$1 = Curry._2(Prelude.$$Array.keep, components, (function (param) {
          return Caml_obj.equal(param.section_id, section.id);
        }));
  var completion = Completion.Information.make(components$1.length, 0, Procedure.areOverdue(procedures));
  return Curry._3(Prelude.$$Array.fold, components$1, completion, (function (param, component) {
                return {
                        expected: param.expected,
                        done: param.done + (
                          Completion.Information.isFullyInspected(Component.getCompletion(component, procedures)) ? 1 : 0
                        ) | 0,
                        isOverdue: param.isOverdue
                      };
              }));
}

var Identifier;

var clear = include.clear;

var save = include.save;

var $$delete = include.$$delete;

var remove = include.remove;

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  Identifier ,
  SectionDef ,
  clear ,
  save ,
  $$delete ,
  remove ,
  IdComparator ,
  Id ,
  normalizeURLs ,
  ofVessel ,
  ofComponent ,
  unarchived ,
  ids ,
  byName ,
  getImage ,
  getImages ,
  isAnyChecklist ,
  getCompletion ,
}
/* include Not a pure module */
