// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Webapi__Canvas from "./rescript-webapi/src/Webapi/Webapi__Canvas.js";

function toBlob(canvas, options) {
  return Prelude.thenResolve(new Promise((function (resolve, reject) {
                    var ctype = options.type;
                    var quality = options.quality;
                    canvas.toBlob((function (x) {
                            resolve(x);
                          }), ctype, quality);
                  })), (function (x) {
                return x;
              }));
}

var Promises = {
  toBlob: toBlob
};

var Canvas2d = Webapi__Canvas.Canvas2d;

var WebGl = Webapi__Canvas.WebGl;

var $$Blob = Webapi__Canvas.$$Blob;

var CanvasElement = Webapi__Canvas.CanvasElement;

var $$OffscreenCanvas = Webapi__Canvas.$$OffscreenCanvas;

export {
  Canvas2d ,
  WebGl ,
  $$Blob ,
  CanvasElement ,
  $$OffscreenCanvas ,
  Promises ,
}
/* No side effect */
