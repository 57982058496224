// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as $$Image from "../../../libs/Image.js";
import * as Query from "../../Query.js";
import * as Vessel from "../../vessel/Vessel.js";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as AttachmentId from "../../attachment_chunks/AttachmentId.js";
import * as FutureAttachment from "../../attachment_chunks/FutureAttachment.js";

function cmp(source1, source2) {
  switch (source1.TAG) {
    case "UserProfile" :
        switch (source2.TAG) {
          case "UserProfile" :
              return Caml_obj.compare(source1._1.id, source2._1.id);
          case "Inspection" :
          case "Answer" :
              return 1;
          case "Comment" :
          case "UpdatedComment" :
          case "CompanyFormSubmission" :
          case "LevelSubmission" :
          case "ProcedureUpdate" :
          case "ReadNotificationEntry" :
          case "FutureAttachment" :
              return -1;
          
        }
    case "Inspection" :
        switch (source2.TAG) {
          case "Inspection" :
              return Caml_obj.compare(source1._1.id, source2._1.id);
          case "UserProfile" :
          case "Answer" :
          case "Comment" :
          case "UpdatedComment" :
          case "CompanyFormSubmission" :
          case "LevelSubmission" :
          case "ProcedureUpdate" :
          case "ReadNotificationEntry" :
          case "FutureAttachment" :
              return -1;
          
        }
    case "Answer" :
        switch (source2.TAG) {
          case "Inspection" :
              return 1;
          case "Answer" :
              return Caml_obj.compare(source1._1.id, source2._1.id);
          case "UserProfile" :
          case "Comment" :
          case "UpdatedComment" :
          case "CompanyFormSubmission" :
          case "LevelSubmission" :
          case "ProcedureUpdate" :
          case "ReadNotificationEntry" :
          case "FutureAttachment" :
              return -1;
          
        }
    case "Comment" :
        if (source2.TAG === "Comment") {
          return Caml_obj.compare(source1._1.id, source2._1.id);
        } else {
          return 1;
        }
    case "UpdatedComment" :
        switch (source2.TAG) {
          case "Comment" :
              return -1;
          case "UpdatedComment" :
              return Caml_obj.compare(source1._1.id, source2._1.id);
          default:
            return 1;
        }
    case "CompanyFormSubmission" :
        switch (source2.TAG) {
          case "CompanyFormSubmission" :
              return Caml_obj.compare(source1._1.id, source2._1.id);
          case "Comment" :
          case "UpdatedComment" :
          case "ProcedureUpdate" :
          case "ReadNotificationEntry" :
          case "FutureAttachment" :
              return -1;
          default:
            return 1;
        }
    case "LevelSubmission" :
        switch (source2.TAG) {
          case "LevelSubmission" :
              return Caml_obj.compare(source1._1.id, source2._1.id);
          case "Comment" :
          case "UpdatedComment" :
          case "CompanyFormSubmission" :
          case "ProcedureUpdate" :
          case "ReadNotificationEntry" :
          case "FutureAttachment" :
              return -1;
          default:
            return 1;
        }
    case "ProcedureUpdate" :
        switch (source2.TAG) {
          case "ProcedureUpdate" :
              return Caml_obj.compare(source1._1.id, source2._1.id);
          case "Comment" :
          case "UpdatedComment" :
          case "ReadNotificationEntry" :
              return -1;
          default:
            return 1;
        }
    case "ReadNotificationEntry" :
        switch (source2.TAG) {
          case "Comment" :
          case "UpdatedComment" :
              return -1;
          case "ReadNotificationEntry" :
              return Caml_obj.compare(source1._1.id, source2._1.id);
          default:
            return 1;
        }
    case "FutureAttachment" :
        switch (source2.TAG) {
          case "Comment" :
          case "UpdatedComment" :
          case "ProcedureUpdate" :
          case "ReadNotificationEntry" :
              return -1;
          case "FutureAttachment" :
              return Caml_obj.compare(source1._1.id, source2._1.id);
          default:
            return 1;
        }
    
  }
}

var Comparator = Belt_Id.MakeComparableU({
      cmp: cmp
    });

async function make(request) {
  switch (request.TAG) {
    case "UserProfile" :
        var profile = request._1;
        var vessel = request._0;
        var required = profile.avatars.map(function (param) {
              return param.id;
            });
        var newrecord = Caml_obj.obj_dup(Query.makeRead());
        var match = await Query.read((newrecord.futureAttachments = {
                TAG: "In",
                _0: required
              }, newrecord));
        var existing = AttachmentId.$$Set.fromArray(match.futureAttachments.map(function (param) {
                  return param.id;
                }));
        var missing = Curry._2(Prelude.$$Array.keep, profile.avatars, (function (a) {
                return !AttachmentId.$$Set.has(existing, a.id);
              }));
        if (!Prelude.$$Array.isNotEmpty(missing)) {
          return [{
                    TAG: "UserProfile",
                    _0: vessel,
                    _1: profile
                  }];
        }
        var resolution = vessel.max_pic_resolution;
        var buildGroup = function (attachment) {
          return async function () {
            var match = await $$Image.Compression.compressLocalImage(attachment.id, resolution);
            if (match !== undefined) {
              return await FutureAttachment.fromUserProfileBlob(profile, attachment.id, match.file);
            }
            
          };
        };
        var groups = await Prelude.Promises.iter(missing.map(buildGroup));
        var groups$1 = Prelude.$$Array.keepSome(groups);
        if (Prelude.$$Array.isNotEmpty(groups$1)) {
          var newrecord$1 = Caml_obj.obj_dup(Query.makeWrite());
          await Query.write((newrecord$1.futureAttachments = groups$1.map(function (g) {
                      return {
                              TAG: "Save",
                              _0: g
                            };
                    }), newrecord$1));
        }
        return Curry._2(Prelude.$$Array.concat, [{
                      TAG: "UserProfile",
                      _0: vessel,
                      _1: profile
                    }], groups$1.map(function (group) {
                        return {
                                TAG: "FutureAttachment",
                                _0: vessel,
                                _1: group
                              };
                      }));
    case "Inspection" :
        var inspection = request._1;
        var vessel$1 = request._0;
        var required$1 = inspection.attachments.map(function (param) {
              return param.id;
            });
        var newrecord$2 = Caml_obj.obj_dup(Query.makeRead());
        var match$1 = await Query.read((newrecord$2.futureAttachments = {
                TAG: "In",
                _0: required$1
              }, newrecord$2));
        var existing$1 = AttachmentId.$$Set.fromArray(match$1.futureAttachments.map(function (param) {
                  return param.id;
                }));
        var missing$1 = Curry._2(Prelude.$$Array.keep, inspection.attachments, (function (a) {
                return !AttachmentId.$$Set.has(existing$1, a.id);
              }));
        if (!Prelude.$$Array.isNotEmpty(missing$1)) {
          return [{
                    TAG: "Inspection",
                    _0: vessel$1,
                    _1: inspection
                  }];
        }
        var resolution$1 = vessel$1.max_pic_resolution;
        var buildGroup$1 = function (attachment) {
          return async function () {
            var match = await $$Image.Compression.compressLocalImage(attachment.id, resolution$1);
            if (match !== undefined) {
              return await FutureAttachment.fromInspectionAttachmentBlob(inspection, attachment.id, attachment.role, match.file);
            }
            
          };
        };
        var groups$2 = await Prelude.Promises.iter(missing$1.map(buildGroup$1));
        var groups$3 = Prelude.$$Array.keepSome(groups$2);
        if (Prelude.$$Array.isNotEmpty(groups$3)) {
          var newrecord$3 = Caml_obj.obj_dup(Query.makeWrite());
          await Query.write((newrecord$3.futureAttachments = groups$3.map(function (g) {
                      return {
                              TAG: "Save",
                              _0: g
                            };
                    }), newrecord$3));
        }
        return Curry._2(Prelude.$$Array.concat, [{
                      TAG: "Inspection",
                      _0: vessel$1,
                      _1: inspection
                    }], groups$3.map(function (group) {
                        return {
                                TAG: "FutureAttachment",
                                _0: vessel$1,
                                _1: group
                              };
                      }));
    case "Answer" :
        var answer = request._1;
        var vessel$2 = request._0;
        var required$2 = answer.evidences.map(function (param) {
              return param.id;
            });
        var newrecord$4 = Caml_obj.obj_dup(Query.makeRead());
        var match$2 = await Query.read((newrecord$4.futureAttachments = {
                TAG: "In",
                _0: required$2
              }, newrecord$4));
        var existing$2 = AttachmentId.$$Set.fromArray(match$2.futureAttachments.map(function (param) {
                  return param.id;
                }));
        var missing$2 = Curry._2(Prelude.$$Array.keep, answer.evidences, (function (a) {
                return !AttachmentId.$$Set.has(existing$2, a.id);
              }));
        if (!Prelude.$$Array.isNotEmpty(missing$2)) {
          return [{
                    TAG: "Answer",
                    _0: vessel$2,
                    _1: answer
                  }];
        }
        var resolution$2 = vessel$2.max_pic_resolution;
        var buildGroup$2 = function (attachment) {
          return async function () {
            var match = await $$Image.Compression.compressLocalImage(attachment.id, resolution$2);
            if (match !== undefined) {
              return await FutureAttachment.fromAnswerAttachmentBlob(answer, attachment.id, attachment.role, match.file);
            }
            
          };
        };
        var groups$4 = await Prelude.Promises.iter(missing$2.map(buildGroup$2));
        var groups$5 = Prelude.$$Array.keepSome(groups$4);
        if (Prelude.$$Array.isNotEmpty(groups$5)) {
          var newrecord$5 = Caml_obj.obj_dup(Query.makeWrite());
          await Query.write((newrecord$5.futureAttachments = groups$5.map(function (g) {
                      return {
                              TAG: "Save",
                              _0: g
                            };
                    }), newrecord$5));
        }
        return Curry._2(Prelude.$$Array.concat, [{
                      TAG: "Answer",
                      _0: vessel$2,
                      _1: answer
                    }], groups$5.map(function (group) {
                        return {
                                TAG: "FutureAttachment",
                                _0: vessel$2,
                                _1: group
                              };
                      }));
    case "Comment" :
        return [{
                  TAG: "Comment",
                  _0: request._0,
                  _1: request._1
                }];
    case "UpdatedComment" :
        return [{
                  TAG: "UpdatedComment",
                  _0: request._0,
                  _1: request._1
                }];
    case "CompanyFormSubmission" :
        return [{
                  TAG: "CompanyFormSubmission",
                  _0: request._0,
                  _1: request._1,
                  _2: request._2
                }];
    case "IncompleteInspection" :
    case "IncompleteCompanyForm" :
        return [];
    case "LevelSubmission" :
        return [{
                  TAG: "LevelSubmission",
                  _0: request._0,
                  _1: request._1
                }];
    case "ProcedureUpdate" :
        return [{
                  TAG: "ProcedureUpdate",
                  _0: request._0,
                  _1: request._1
                }];
    case "ReadNotificationEntry" :
        return [{
                  TAG: "ReadNotificationEntry",
                  _0: request._0,
                  _1: request._1,
                  _2: request._2
                }];
    case "FutureAttachment" :
        return [{
                  TAG: "FutureAttachment",
                  _0: request._0,
                  _1: request._1
                }];
    
  }
}

function userProfiles(payloads) {
  return Curry._2(Prelude.$$Array.keepMap, payloads, (function (payload) {
                if (payload.TAG === "UserProfile") {
                  return {
                          TAG: "UserProfile",
                          _0: payload._0,
                          _1: payload._1
                        };
                }
                
              }));
}

function inspections(payloads) {
  return Curry._2(Prelude.$$Array.keepMap, payloads, (function (payload) {
                if (payload.TAG === "Inspection") {
                  return {
                          TAG: "Inspection",
                          _0: payload._0,
                          _1: payload._1
                        };
                }
                
              }));
}

function getAnswerRequests(payloads) {
  return Curry._2(Prelude.$$Array.keepMap, payloads, (function (payload) {
                if (payload.TAG === "Answer") {
                  return {
                          TAG: "Answer",
                          _0: payload._0,
                          _1: payload._1
                        };
                }
                
              }));
}

function getFutureAttachments(payloads) {
  return FutureAttachment.deduplicate(Curry._2(Prelude.$$Array.keepMap, payloads, (function (payload) {
                    if (payload.TAG === "FutureAttachment") {
                      return payload._1;
                    }
                    
                  })));
}

function comments(payloads) {
  return Curry._2(Prelude.$$Array.keepMap, payloads, (function (payload) {
                if (payload.TAG === "Comment") {
                  return {
                          TAG: "Comment",
                          _0: payload._0,
                          _1: payload._1
                        };
                }
                
              }));
}

function updatedComments(payloads) {
  return Curry._2(Prelude.$$Array.keepMap, payloads, (function (payload) {
                if (payload.TAG === "UpdatedComment") {
                  return {
                          TAG: "UpdatedComment",
                          _0: payload._0,
                          _1: payload._1
                        };
                }
                
              }));
}

function companyFormSubmissions(payloads) {
  return Curry._2(Prelude.$$Array.keepMap, payloads, (function (payload) {
                if (payload.TAG === "CompanyFormSubmission") {
                  return {
                          TAG: "CompanyFormSubmission",
                          _0: payload._0,
                          _1: payload._1,
                          _2: payload._2
                        };
                }
                
              }));
}

function levelSubmissions(payloads) {
  return Curry._2(Prelude.$$Array.keepMap, payloads, (function (payload) {
                if (payload.TAG === "LevelSubmission") {
                  return {
                          TAG: "LevelSubmission",
                          _0: payload._0,
                          _1: payload._1
                        };
                }
                
              }));
}

function procedureUpdates(payloads) {
  return Curry._2(Prelude.$$Array.keepMap, payloads, (function (payload) {
                if (payload.TAG === "ProcedureUpdate") {
                  return {
                          TAG: "ProcedureUpdate",
                          _0: payload._0,
                          _1: payload._1
                        };
                }
                
              }));
}

function size(payloads) {
  return Curry._3(Prelude.$$Array.fold, getFutureAttachments(payloads), 0.0, (function (r, group) {
                return r + group.size;
              }));
}

function getVessel(p) {
  return p._0;
}

function getVessels(payloads) {
  return Vessel.deduplicate(payloads.map(getVessel));
}

function getInspection(payload) {
  if (payload.TAG === "Inspection") {
    return payload._1;
  }
  
}

function getUserProfile(payload) {
  if (payload.TAG === "UserProfile") {
    return payload._1;
  }
  
}

function getNewComment(payload) {
  if (payload.TAG === "Comment") {
    return payload._1;
  }
  
}

function getUpdatedComment(payload) {
  if (payload.TAG === "UpdatedComment") {
    return payload._1;
  }
  
}

function getCompanyFormSubmission(payload) {
  if (payload.TAG === "CompanyFormSubmission") {
    return payload._1;
  }
  
}

function getLevelSubmission(payload) {
  if (payload.TAG === "LevelSubmission") {
    return payload._1;
  }
  
}

function getAnswer(payload) {
  if (payload.TAG === "Answer") {
    return payload._1;
  }
  
}

function getReadNotificationEntry(payload) {
  if (payload.TAG === "ReadNotificationEntry") {
    return payload._1;
  }
  
}

function getProcedureUpdate(payload) {
  if (payload.TAG === "ProcedureUpdate") {
    return payload._1;
  }
  
}

function getFutureAttachment(payload) {
  if (payload.TAG === "FutureAttachment") {
    return payload._1;
  }
  
}

function timestamp(request) {
  switch (request.TAG) {
    case "Inspection" :
        return Caml_option.some(request._1.timestamp);
    case "Answer" :
        return Caml_option.some(request._1.timestamp);
    case "Comment" :
        return Caml_option.some(request._1.timestamp);
    case "CompanyFormSubmission" :
    case "LevelSubmission" :
        return Caml_option.some(request._1.timestamp);
    default:
      return ;
  }
}

function deduplicate(futures) {
  return Belt_Set.toArray(Belt_Set.mergeMany(Belt_Set.make(Comparator), futures));
}

export {
  make ,
  userProfiles ,
  inspections ,
  getAnswerRequests ,
  getFutureAttachments ,
  comments ,
  updatedComments ,
  companyFormSubmissions ,
  levelSubmissions ,
  procedureUpdates ,
  size ,
  getVessel ,
  getVessels ,
  timestamp ,
  getInspection ,
  getUserProfile ,
  getNewComment ,
  getUpdatedComment ,
  getCompanyFormSubmission ,
  getLevelSubmission ,
  getAnswer ,
  getProcedureUpdate ,
  getReadNotificationEntry ,
  getFutureAttachment ,
  Comparator ,
  deduplicate ,
}
/* Comparator Not a pure module */
