// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$URL from "../../libs/URL.js";
import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Network from "../../libs/Network.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Settings from "../Settings.js";
import * as LocalFile from "../../libs/LocalFile.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Browser from "@sentry/browser";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

function getImages(images) {
  return images.map(function (i) {
              return {
                      TAG: "Cloud",
                      _0: i
                    };
            });
}

function loadFile(image) {
  var id = image.id;
  return Prelude.thenResolve(LocalFile.getResource(id), (function (res) {
                return [
                        id,
                        Curry._2(Prelude.OptionExported.$$Option.map, res, (function (param) {
                                return param.file;
                              }))
                      ];
              }));
}

function getFile(image) {
  return Prelude.thenResolve(Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, image, loadFile), Promise.resolve([
                      LocalFile.UUID.$$null,
                      undefined
                    ])), (function (param) {
                return param[1];
              }));
}

function getSelector(instance, items) {
  return Curry._2(Prelude.$$Array.getBy, items, (function (image) {
                return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, instance, (function (p) {
                                  return Caml_obj.equal(p.id, image.id);
                                })), false);
              }));
}

function request(image) {
  var exit = 0;
  var val;
  var val$1;
  var val$2;
  try {
    val = Settings.DeveloperDebug.skipThumbnails;
    val$1 = image.thumbnail;
    val$2 = image.file;
    exit = 1;
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    console.error("Cannot build Network Request for image", image, e);
    Browser.captureException(e);
    return ;
  }
  if (exit === 1) {
    var exit$1 = 0;
    var exit$2 = 0;
    if (val || val$1 === undefined) {
      exit$2 = 3;
    } else {
      if (val$2 !== undefined) {
        return Network.$$Request.add(Network.$$Request.make(Caml_option.valFromOption(val$1)), Caml_option.valFromOption(val$2));
      }
      exit$1 = 2;
    }
    if (exit$2 === 3) {
      if (val$2 !== undefined) {
        return Network.$$Request.make(Caml_option.valFromOption(val$2));
      }
      exit$1 = 2;
    }
    if (exit$1 === 2) {
      if (val$1 !== undefined) {
        return Network.$$Request.make(Caml_option.valFromOption(val$1));
      } else {
        return ;
      }
    }
    
  }
  
}

var fields = {
  TAG: "Object",
  _0: [
    [
      "id",
      "String"
    ],
    [
      "file",
      "String"
    ],
    [
      "thumbnail",
      {
        TAG: "Optional",
        _0: "String"
      }
    ]
  ]
};

var ImplDef = {
  fields: fields
};

var Impl = $$JSON.MakeDeserializer(ImplDef);

var fields$1 = {
  TAG: "Object",
  _0: [
    [
      "id",
      "String"
    ],
    [
      "file",
      {
        TAG: "Optional",
        _0: "String"
      }
    ],
    [
      "thumbnail",
      {
        TAG: "Optional",
        _0: "String"
      }
    ]
  ]
};

function fromJSON(data) {
  return Curry._2(Prelude.Result.map, Impl.fromJSON(data), (function (param) {
                return {
                        id: param.id,
                        file: Caml_option.some(param.file),
                        thumbnail: param.thumbnail
                      };
              }));
}

var Deserializer_checkFieldsSanity = Impl.checkFieldsSanity;

var Deserializer = {
  ImplDef: ImplDef,
  Impl: Impl,
  name: "ProcedureImage.Deserializer",
  fields: fields$1,
  fromJSON: fromJSON,
  checkFieldsSanity: Deserializer_checkFieldsSanity
};

function normalizeURLs(record) {
  return {
          id: record.id,
          file: Curry._2(Prelude.OptionExported.$$Option.map, record.file, $$URL.Utils.withoutSearch),
          thumbnail: Curry._2(Prelude.OptionExported.$$Option.map, record.thumbnail, $$URL.Utils.withoutSearch)
        };
}

export {
  getImages ,
  loadFile ,
  getFile ,
  getSelector ,
  request ,
  Deserializer ,
  normalizeURLs ,
}
/* Impl Not a pure module */
