// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as ButtonAction from "./ButtonAction.js";
import * as ModalBackdrop from "./ModalBackdrop.js";
import * as ButtonSlimIcon from "./ButtonSlimIcon.js";
import * as ButtonSlimText from "./ButtonSlimText.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                var onClose = match.onClose;
                var showModal = match.showModal;
                return ReactDOM.jsxs("div", {
                            children: [
                              React.jsx(ModalBackdrop.make, {
                                    onClick: onClose,
                                    showModal: showModal
                                  }),
                              ReactDOM.jsxs("div", {
                                    children: [
                                      ReactDOM.jsxs("div", {
                                            children: [
                                              React.jsx(ButtonSlimText.make, {
                                                    text: "Clear Filter",
                                                    onClick: match.onClear,
                                                    className: "tw-pl-2 tw-font-medium tw-text-xs"
                                                  }),
                                              React.jsx(ButtonSlimIcon.make, {
                                                    icon: "x",
                                                    onClick: onClose,
                                                    size: 18,
                                                    className: "tw-pr-2"
                                                  })
                                            ],
                                            className: "tw-flex tw-flex-row tw-items-center tw-justify-between"
                                          }),
                                      ReactDOM.jsx("div", {
                                            children: ReactDOM.jsx("label", {
                                                  children: "Filter",
                                                  className: "tw-text-xl tw-font-medium"
                                                }),
                                            className: "tw-flex tw-flex-row tw-px-4"
                                          }),
                                      ReactDOM.jsxs("div", {
                                            children: [
                                              vnode.children,
                                              React.jsx(ButtonAction.make, {
                                                    children: match.textApplyButton,
                                                    onClick: match.onApplyFilter,
                                                    style: "primary"
                                                  })
                                            ],
                                            className: "tw-flex tw-flex-col tw-justify-start tw-gap-4 tw-px-4"
                                          })
                                    ],
                                    className: Mithril.$$class([
                                          [
                                            "tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-w-full",
                                            true
                                          ],
                                          [
                                            "tw-flex tw-flex-col tw-overflow-y-auto tw-max-h-[95vh]",
                                            true
                                          ],
                                          [
                                            "tw-shadow-sm tw-bg-white tw-z-1501 tw-gap-2 tw-pb-5",
                                            true
                                          ],
                                          [
                                            "tw-block",
                                            showModal
                                          ],
                                          [
                                            "tw-hidden",
                                            !showModal
                                          ]
                                        ], undefined)
                                  })
                            ],
                            className: "t-modal-findings-filter"
                          });
              }));
}

export {
  make ,
}
/* React Not a pure module */
