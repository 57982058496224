// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Mithril from "mithril";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Webapi__Dom__Document from "../../rescript-webapi/src/Webapi/Dom/Webapi__Dom__Document.js";

function setPrefix(prefix) {
  Mithril.route.prefix = prefix;
}

function paramInt(name) {
  return Belt_Option.flatMap(Mithril.route.param(name), Belt_Int.fromString);
}

function paramBool(name) {
  return Belt_Option.map(Mithril.route.param(name), (function (prim) {
                return Boolean(prim);
              }));
}

function parameters(names) {
  return Belt_Array.map(names, (function (prim) {
                return Mithril.route.param(prim);
              }));
}

function title() {
  return Belt_Option.getWithDefault(Belt_Option.map(Webapi__Dom__Document.asHtmlDocument(document), (function (doc) {
                    return doc.title;
                  })), "");
}

function setTitle(newTitle) {
  Belt_Option.map(Webapi__Dom__Document.asHtmlDocument(document), (function (doc) {
          doc.title = newTitle;
        }));
}

export {
  setPrefix ,
  paramInt ,
  paramBool ,
  parameters ,
  title ,
  setTitle ,
}
/* mithril Not a pure module */
