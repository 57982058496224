// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as PageHeader from "./PageHeader.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.js";
import * as ProtectedView from "../../accounts/views/ProtectedView.js";
import * as KeepScrollLink from "./KeepScrollLink.js";
import * as PageNavigation from "./PageNavigation.js";
import * as PageLayoutTheme from "./PageLayoutTheme.js";
import * as ToastNotification from "../components/ToastNotification.js";

function make(param) {
  return Mithril.view(Mithril.oncreate(Mithril.component(), (function (_vnode) {
                    KeepScrollLink.restoreScroll(undefined);
                  })), (function (vnode) {
                var header = Core__Option.getOr(vnode.attrs.header, React.jsx(PageHeader.make, {}));
                var footer = Core__Option.getOr(vnode.attrs.footer, React.jsx(PageNavigation.make, {}));
                var theme = Core__Option.getOr(vnode.attrs.theme, PageLayoutTheme.default);
                var newrecord = Caml_obj.obj_dup(PageLayoutTheme.mergeProps(theme, "root", {
                          className: "t-app-view tw-flex tw-flex-col tw-h-full"
                        }));
                var newrecord$1 = Caml_obj.obj_dup(PageLayoutTheme.mergeProps(theme, "main", {
                          className: "tw-relative tw-flex tw-flex-col tw-flex-grow tw-overflow-auto"
                        }));
                newrecord.children = [
                  header,
                  ReactDOM.jsxs("main", (newrecord$1.children = [
                          React.jsx(ToastNotification.make, {}),
                          vnode.children
                        ], newrecord$1)),
                  footer
                ];
                return React.jsx(ProtectedView.make, {
                            children: ReactDOM.jsxs("div", newrecord)
                          });
              }));
}

export {
  make ,
}
/* React Not a pure module */
