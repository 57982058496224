// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Rating from "../../survey/Rating.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ApiMaker from "../../../libs/ApiMaker.js";
import * as UserAgent from "../../runtime/UserAgent.js";
import * as QuestionType from "../../survey/QuestionType.js";
import * as AttachmentAPI from "./AttachmentAPI.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as SimpleSerializer from "../common/SimpleSerializer.js";

function convert(inspection) {
  return {
          id: inspection.id,
          timestamp: inspection.timestamp.toISOString(),
          procedure: inspection.procedure_id,
          inspector: inspection.inspector_id,
          rating: QuestionType.Value.rate(inspection.value),
          annotations: inspection.annotations,
          additional_annotation: inspection.additional_annotation,
          deadline: Curry._2(Prelude.OptionExported.$$Option.flatMap, inspection.deadline, (function (date) {
                  return Prelude.$$Array.first(date.toISOString().split("T"));
                })),
          picture_prompted: inspection.picture_prompted,
          walked_steps: inspection.walked_steps,
          app_version: inspection.app_version,
          component_weight_value: QuestionType.Value.getWeightDelta(inspection.value),
          date_value: Curry._2(Prelude.OptionExported.$$Option.flatMap, QuestionType.Value.getDate(inspection.value), (function (date) {
                  return Prelude.$$Array.first(date.toISOString().split("T"));
                })),
          text_value: QuestionType.Value.getText(inspection.value),
          integer_value: QuestionType.Value.getInteger(inspection.value),
          is_yes_value: QuestionType.Value.getYesNo(inspection.value),
          expected_attachment_ids: inspection.attachments.map(function (param) {
                return param.id;
              }),
          profile: inspection.profile,
          app_user_agent: UserAgent.getUA(),
          app_browser_name: UserAgent.getBrowser().name,
          app_browser_version: UserAgent.getBrowser().version,
          app_device_name: UserAgent.getDeviceString().trim(),
          app_device_cpu: UserAgent.getCPU().architecture,
          app_device_os: UserAgent.getOS().name
        };
}

var Serializer = SimpleSerializer.MakeSimpleSerializer({
      convert: convert
    });

var fields = [[
    "id",
    "String"
  ]];

var Impl = ApiMaker.MakeAPIEndpoint({
      fields: fields,
      url: "/survey/inspections"
    });

var InvalidLocalInspectionData = /* @__PURE__ */Caml_exceptions.create("InspectionAPI.InvalidLocalInspectionData");

function post(params, user, inspections) {
  var inspections$1 = Prelude.ManyResults.bail(inspections.map(function (param) {
            return Curry._1(Serializer.serialize, param);
          }));
  if (inspections$1.TAG === "Ok") {
    return Impl.postMany(params, user, inspections$1._0);
  }
  var message = inspections$1._0;
  return Prelude.rejectWithError({
              RE_EXN_ID: InvalidLocalInspectionData,
              code: 400,
              response: {
                value: Prelude.default(JSON.stringify(message), "Could not serialize")
              },
              message: Prelude.default(JSON.stringify(message), "Could not serialize")
            });
}

var fields$1 = {
  TAG: "Object",
  _0: [
    [
      "manufacturer",
      "String"
    ],
    [
      "initial_weight",
      "Float"
    ],
    [
      "current_weight",
      "Float"
    ]
  ]
};

var WeightDeltaDeserializer = $$JSON.MakeDeserializer({
      fields: fields$1
    });

var url = "/survey/read-inspections";

var fields$2 = [
  [
    "id",
    "String"
  ],
  [
    "procedure_id",
    "String"
  ],
  [
    "component_id",
    "String"
  ],
  [
    "section_id",
    "String"
  ],
  [
    "vessel_id",
    "String"
  ],
  [
    "inspector_id",
    {
      TAG: "Optional",
      _0: "String"
    }
  ],
  [
    "rating",
    Rating.deserFromJS
  ],
  [
    "timestamp",
    "Date"
  ],
  [
    "code",
    {
      TAG: "Optional",
      _0: "String"
    }
  ],
  [
    "annotations",
    {
      TAG: "Array",
      _0: "String"
    }
  ],
  [
    "additional_annotation",
    "String"
  ],
  [
    "picture_prompted",
    {
      TAG: "OptionalWithDefault",
      _0: "Boolean",
      _1: false
    }
  ],
  [
    "walked_steps",
    {
      TAG: "OptionalWithDefault",
      _0: "Int",
      _1: 0
    }
  ],
  [
    "app_version",
    {
      TAG: "OptionalWithDefault",
      _0: "String",
      _1: "<?>"
    }
  ],
  [
    "deficiency_id",
    {
      TAG: "Optional",
      _0: "String"
    }
  ],
  [
    "attachments",
    {
      TAG: "Collection",
      _0: AttachmentAPI.Basic.Deserializer
    }
  ],
  [
    "component_weight_value",
    {
      TAG: "Optional",
      _0: {
        TAG: "Deserializer",
        _0: WeightDeltaDeserializer
      }
    }
  ],
  [
    "date_value",
    {
      TAG: "Optional",
      _0: "Date"
    }
  ],
  [
    "text_value",
    {
      TAG: "Optional",
      _0: "String"
    }
  ],
  [
    "integer_value",
    {
      TAG: "Optional",
      _0: "Int"
    }
  ],
  [
    "is_yes_value",
    {
      TAG: "Optional",
      _0: "Boolean"
    }
  ],
  [
    "profile",
    {
      TAG: "Optional",
      _0: "String"
    }
  ]
];

var ReadInspectionDefinition = {
  url: url,
  fields: fields$2
};

var Read = ApiMaker.MakeReadonlyAPIEndpoint({
      fields: fields$2,
      url: url
    });

export {
  Serializer ,
  Impl ,
  InvalidLocalInspectionData ,
  post ,
  WeightDeltaDeserializer ,
  ReadInspectionDefinition ,
  Read ,
}
/* Serializer Not a pure module */
