// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$URL from "../../../libs/URL.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as LocalFile from "../../../libs/LocalFile.js";
import * as ListLayout from "../../common/views/ListLayout.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import MarkdownIt from "markdown-it";
import * as ProcedureImage from "../../survey/ProcedureImage.js";
import * as MarkdownDocumentId from "../MarkdownDocumentId.js";

function createImageURLs(vnode) {
  var match = vnode.state.data;
  if (typeof match === "object") {
    return ;
  }
  var $$document = vnode.attrs.document;
  Prelude.thenDo(Promise.all($$document.attachments.map(ProcedureImage.loadFile)), (function (results) {
          var loaded = LocalFile.UUID.$$Map.fromArray(Curry._2(Prelude.$$Array.keepMap, results, (function (param) {
                      var id = param[0];
                      return Curry._2(Prelude.OptionExported.$$Option.map, param[1], (function (f) {
                                    return [
                                            id,
                                            URL.createObjectURL(f)
                                          ];
                                  }));
                    })));
          Mithril.setState(vnode, {
                TAG: "Loaded",
                _0: loaded
              });
        }));
}

function destroyImageURLs(vnode) {
  var urls = vnode.state.data;
  if (typeof urls !== "object") {
    return ;
  }
  LocalFile.UUID.$$Map.valuesToArray(urls._0).map(function (prim) {
          return prim;
        }).map($$URL.revokeObjectURL);
}

var imageRegExp = /\!\[kaiko\.attachment::([0-9a-f\-]+)\]\([^\)]+\)/g;

function make(param) {
  return Mithril.view(Mithril.onremove(Mithril.oninit(Mithril.component("Init"), createImageURLs), destroyImageURLs), (function (vnode) {
                var dataUrls = vnode.state.data;
                if (typeof dataUrls !== "object") {
                  return Mithril.empty;
                }
                var dataUrls$1 = dataUrls._0;
                var $$document = vnode.attrs.document;
                var contents = $$document.contents.replaceAll(imageRegExp, (function (image, uuid) {
                        var dataURL = LocalFile.UUID.$$Map.get(dataUrls$1, LocalFile.UUID.fromString(uuid));
                        if (dataURL !== undefined) {
                          return "![replaced::" + uuid + "](" + Caml_option.valFromOption(dataURL) + ")";
                        } else {
                          return image;
                        }
                      }));
                var parsed = new MarkdownIt().render(contents);
                return ReactDOM.jsx("div", {
                            children: Mithril$1.trust(parsed),
                            className: "markdown-container"
                          });
              }));
}

var MarkdownDocumentView = {
  createImageURLs: createImageURLs,
  destroyImageURLs: destroyImageURLs,
  make: make
};

function loadDocument(vnode) {
  var match = vnode.state.data;
  if (typeof match === "object") {
    return ;
  }
  if (match !== "Init") {
    return ;
  }
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  Prelude.thenDo(Query.read((newrecord.documents = {
              TAG: "Get",
              _0: vnode.attrs.documentId
            }, newrecord)), (function (param) {
          var documents = param.documents;
          if (documents.length !== 1) {
            return Mithril.setState(vnode, "NotFound");
          }
          var $$document = documents[0];
          Mithril.setState(vnode, {
                TAG: "Loaded",
                _0: $$document
              });
        }));
}

function make$1(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("Init"), loadDocument), (function (vnode) {
                var $$document = vnode.state.data;
                if (typeof $$document !== "object") {
                  if ($$document === "Init") {
                    return Mithril.empty;
                  } else {
                    return React.jsx(ListLayout.make, {
                                children: ReactDOM.jsx("p", {
                                      children: "The document was not found locally.  This is probably a synchronization issue.\n                   If you keep experiencing this error, please contact Kaiko Systems."
                                    }),
                                title: "404: Not found"
                              });
                  }
                }
                var $$document$1 = $$document._0;
                var title = Prelude.$$Array.keepSome([
                        Curry._2(Prelude.OptionExported.$$Option.map, $$document$1.title, (function (prim) {
                                return prim.trim();
                              })),
                        vnode.attrs.defaultTitle,
                        "Document"
                      ])[0];
                return React.jsx(ListLayout.make, {
                            children: React.jsx(make, {
                                  document: $$document$1
                                }),
                            title: title
                          });
              }));
}

var MarkdownDocumentPage = {
  loadDocument: loadDocument,
  make: make$1
};

function make$2(param) {
  return Mithril.view(Mithril.component(), (function (param) {
                var documentId = MarkdownDocumentId.Route.paramOr("documentId", MarkdownDocumentId.$$null);
                return React.jsx(make$1, {
                            documentId: documentId
                          });
              }));
}

export {
  MarkdownDocumentView ,
  MarkdownDocumentPage ,
  make$2 as make,
}
/* URL Not a pure module */
