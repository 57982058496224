// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as Migration_000 from "./migrations/Migration_000.js";
import * as Migration_001 from "./migrations/Migration_001.js";
import * as Migration_002 from "./migrations/Migration_002.js";
import * as Migration_003 from "./migrations/Migration_003.js";
import * as Migration_004 from "./migrations/Migration_004.js";
import * as Migration_005 from "./migrations/Migration_005.js";
import * as Migration_006 from "./migrations/Migration_006.js";
import * as Migration_007 from "./migrations/Migration_007.js";
import * as Migration_008 from "./migrations/Migration_008.js";
import * as Migration_009 from "./migrations/Migration_009.js";
import * as Migration_010 from "./migrations/Migration_010.js";
import * as Migration_011 from "./migrations/Migration_011.js";
import * as Migration_012 from "./migrations/Migration_012.js";
import * as Migration_013 from "./migrations/Migration_013.js";
import * as Migration_014 from "./migrations/Migration_014.js";
import * as Migration_015 from "./migrations/Migration_015.js";
import * as Migration_016 from "./migrations/Migration_016.js";
import * as Migration_017 from "./migrations/Migration_017.js";
import * as Migration_018 from "./migrations/Migration_018.js";
import * as Migration_019 from "./migrations/Migration_019.js";
import * as Migration_020 from "./migrations/Migration_020.js";
import * as Migration_021 from "./migrations/Migration_021.js";
import * as Migration_022 from "./migrations/Migration_022.js";
import * as Migration_023 from "./migrations/Migration_023.js";
import * as Migration_024 from "./migrations/Migration_024.js";
import * as Migration_025 from "./migrations/Migration_025.js";
import * as Migration_026 from "./migrations/Migration_026.js";
import * as Migration_027 from "./migrations/Migration_027.js";
import * as Migration_028 from "./migrations/Migration_028.js";
import * as Migration_029 from "./migrations/Migration_029.js";
import * as Migration_030 from "./migrations/Migration_030.js";
import * as Migration_031 from "./migrations/Migration_031.js";
import * as Migration_032 from "./migrations/Migration_032.js";
import * as Migration_033 from "./migrations/Migration_033.js";
import * as Migration_034 from "./migrations/Migration_034.js";
import * as Migration_035 from "./migrations/Migration_035.js";
import * as Migration_036 from "./migrations/Migration_036.js";
import * as Migration_037 from "./migrations/Migration_037.js";
import * as Migration_038 from "./migrations/Migration_038.js";
import * as Migration_039 from "./migrations/Migration_039.js";
import * as Migration_040 from "./migrations/Migration_040.js";
import * as Migration_041 from "./migrations/Migration_041.js";
import * as Migration_042 from "./migrations/Migration_042.js";
import * as Migration_043 from "./migrations/Migration_043.js";
import * as Migration_044 from "./migrations/Migration_044.js";
import * as Migration_045 from "./migrations/Migration_045.js";
import * as Migration_046 from "./migrations/Migration_046.js";
import * as Migration_047 from "./migrations/Migration_047.js";
import * as Migration_048 from "./migrations/Migration_048.js";
import * as Migration_049 from "./migrations/Migration_049.js";
import * as Migration_050 from "./migrations/Migration_050.js";
import * as Migration_051 from "./migrations/Migration_051.js";
import * as Migration_052 from "./migrations/Migration_052.js";
import * as Migration_053 from "./migrations/Migration_053.js";
import * as Migration_054 from "./migrations/Migration_054.js";
import * as Migration_055 from "./migrations/Migration_055.js";
import * as Migration_056 from "./migrations/Migration_056.js";
import * as Migration_057 from "./migrations/Migration_057.js";
import * as Migration_058 from "./migrations/Migration_058.js";
import * as Migration_059 from "./migrations/Migration_059.js";
import * as Migration_060 from "./migrations/Migration_060.js";
import * as Migration_061 from "./migrations/Migration_061.js";
import * as Migration_062 from "./migrations/Migration_062.js";
import * as Migration_063 from "./migrations/Migration_063.js";
import * as Migration_064 from "./migrations/Migration_064.js";
import * as Migration_065 from "./migrations/Migration_065.js";
import * as Migration_066 from "./migrations/Migration_066.js";
import * as Migration_067 from "./migrations/Migration_067.js";
import * as Migration_068 from "./migrations/Migration_068.js";
import * as Migration_069 from "./migrations/Migration_069.js";
import * as Migration_070 from "./migrations/Migration_070.js";
import * as Migration_071 from "./migrations/Migration_071.js";
import * as Migration_072 from "./migrations/Migration_072.js";
import * as Migration_073 from "./migrations/Migration_073.js";
import * as Migration_074 from "./migrations/Migration_074.js";
import * as Migration_075 from "./migrations/Migration_075.js";
import * as Migration_076 from "./migrations/Migration_076.js";
import * as Migration_077 from "./migrations/Migration_077.js";
import * as Migration_078 from "./migrations/Migration_078.js";
import * as Migration_079 from "./migrations/Migration_079.js";
import * as Migration_080 from "./migrations/Migration_080.js";
import * as Migration_081 from "./migrations/Migration_081.js";
import * as Migration_082 from "./migrations/Migration_082.js";
import * as Migration_083 from "./migrations/Migration_083.js";
import * as Migration_084 from "./migrations/Migration_084.js";
import * as Migration_085 from "./migrations/Migration_085.js";
import * as Migration_086 from "./migrations/Migration_086.js";
import * as Migration_087 from "./migrations/Migration_087.js";
import * as Migration_088 from "./migrations/Migration_088.js";
import * as Migration_089 from "./migrations/Migration_089.js";
import * as Migration_090 from "./migrations/Migration_090.js";
import * as Migration_091 from "./migrations/Migration_091.js";
import * as Migration_092 from "./migrations/Migration_092.js";
import * as Migration_093 from "./migrations/Migration_093.js";
import * as Migration_094 from "./migrations/Migration_094.js";
import * as Migration_095 from "./migrations/Migration_095.js";
import * as Migration_096 from "./migrations/Migration_096.js";
import * as Migration_097 from "./migrations/Migration_097.js";
import * as Migration_098 from "./migrations/Migration_098.js";
import * as Migration_099 from "./migrations/Migration_099.js";
import * as Migration_100 from "./migrations/Migration_100.js";
import * as Migration_101 from "./migrations/Migration_101.js";
import * as ReIndexed_Transaction from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed_Transaction.js";

function migrations() {
  return [
          Migration_000.make,
          Migration_001.make,
          Migration_002.make,
          Migration_003.make,
          Migration_004.make,
          Migration_005.make,
          Migration_006.make,
          Migration_007.make,
          Migration_008.make,
          Migration_009.make,
          Migration_010.make,
          Migration_011.make,
          Migration_012.make,
          Migration_013.make,
          Migration_014.make,
          Migration_015.make,
          Migration_016.make,
          Migration_017.make,
          Migration_018.make,
          Migration_019.make,
          Migration_020.make,
          Migration_021.make,
          Migration_022.make,
          Migration_023.make,
          Migration_024.make,
          Migration_025.make,
          Migration_026.make,
          Migration_027.make,
          Migration_028.make,
          Migration_029.make,
          Migration_030.make,
          Migration_031.make,
          Migration_032.make,
          Migration_033.make,
          Migration_034.make,
          Migration_035.make,
          Migration_036.make,
          Migration_037.make,
          Migration_038.make,
          Migration_039.make,
          Migration_040.make,
          Migration_041.make,
          Migration_042.make,
          Migration_043.make,
          Migration_044.make,
          Migration_045.make,
          Migration_046.make,
          Migration_047.make,
          Migration_048.make,
          Migration_049.make,
          Migration_050.make,
          Migration_051.make,
          Migration_052.make,
          Migration_053.make,
          Migration_054.make,
          Migration_055.make,
          Migration_056.make,
          Migration_057.make,
          Migration_058.make,
          Migration_059.make,
          Migration_060.make,
          Migration_061.make,
          Migration_062.make,
          Migration_063.make,
          Migration_064.make,
          Migration_065.make,
          Migration_066.make,
          Migration_067.make,
          Migration_068.make,
          Migration_069.make,
          Migration_070.make,
          Migration_071.make,
          Migration_072.make,
          Migration_073.make,
          Migration_074.make,
          Migration_075.make,
          Migration_076.make,
          Migration_077.make,
          Migration_078.make,
          Migration_079.make,
          Migration_080.make,
          Migration_081.make,
          Migration_082.make,
          Migration_083.make,
          Migration_084.make,
          Migration_085.make,
          Migration_086.make,
          Migration_087.make,
          Migration_088.make,
          Migration_089.make,
          Migration_090.make,
          Migration_091.make,
          Migration_092.make,
          Migration_093.make,
          Migration_094.make,
          Migration_095.make,
          Migration_096.make,
          Migration_097.make,
          Migration_098.make,
          Migration_099.make,
          Migration_100.make,
          Migration_101.make
        ];
}

var Database = ReIndexed.MakeDatabase({
      migrations: migrations
    });

function makeRead() {
  return {
          users: "NoOp",
          vessels: "NoOp",
          sections: "NoOp",
          components: "NoOp",
          procedures: "NoOp",
          plannedProcedures: "NoOp",
          inspections: "NoOp",
          draftInspections: "NoOp",
          newInspections: "NoOp",
          deficiencies: "NoOp",
          ratingStylingRules: "NoOp",
          newComments: "NoOp",
          updatedComments: "NoOp",
          userProfiles: "NoOp",
          newUserProfiles: "NoOp",
          companyForms: "NoOp",
          companyFormSubmissions: "NoOp",
          newCompanyFormSubmissions: "NoOp",
          draftCompanyFormSubmissions: "NoOp",
          documents: "NoOp",
          levels: "NoOp",
          levelTypes: "NoOp",
          levelSubmissions: "NoOp",
          newLevelSubmissions: "NoOp",
          draftLevelSubmissions: "NoOp",
          questions: "NoOp",
          answers: "NoOp",
          newAnswers: "NoOp",
          findings: "NoOp",
          stepsStates: "NoOp",
          procedureUpdates: "NoOp",
          notificationEntries: "NoOp",
          readNotificationEntries: "NoOp",
          futureAttachments: "NoOp",
          sireVettingPeriods: "NoOp",
          sire2VettingPeriods: "NoOp",
          staffers: "NoOp"
        };
}

function makeWrite() {
  return {
          users: [],
          vessels: [],
          sections: [],
          components: [],
          procedures: [],
          plannedProcedures: [],
          inspections: [],
          draftInspections: [],
          newInspections: [],
          deficiencies: [],
          ratingStylingRules: [],
          newComments: [],
          updatedComments: [],
          userProfiles: [],
          newUserProfiles: [],
          companyForms: [],
          companyFormSubmissions: [],
          newCompanyFormSubmissions: [],
          draftCompanyFormSubmissions: [],
          documents: [],
          levels: [],
          levelTypes: [],
          levelSubmissions: [],
          newLevelSubmissions: [],
          draftLevelSubmissions: [],
          questions: [],
          answers: [],
          newAnswers: [],
          findings: [],
          stepsStates: [],
          procedureUpdates: [],
          notificationEntries: [],
          readNotificationEntries: [],
          futureAttachments: [],
          sireVettingPeriods: [],
          sire2VettingPeriods: [],
          staffers: []
        };
}

function makeResponse() {
  return {
          users: [],
          vessels: [],
          sections: [],
          components: [],
          procedures: [],
          plannedProcedures: [],
          inspections: [],
          draftInspections: [],
          newInspections: [],
          deficiencies: [],
          ratingStylingRules: [],
          newComments: [],
          updatedComments: [],
          userProfiles: [],
          newUserProfiles: [],
          companyForms: [],
          companyFormSubmissions: [],
          newCompanyFormSubmissions: [],
          draftCompanyFormSubmissions: [],
          documents: [],
          levels: [],
          levelTypes: [],
          levelSubmissions: [],
          newLevelSubmissions: [],
          draftLevelSubmissions: [],
          questions: [],
          answers: [],
          newAnswers: [],
          findings: [],
          stepsStates: [],
          procedureUpdates: [],
          notificationEntries: [],
          readNotificationEntries: [],
          futureAttachments: [],
          sireVettingPeriods: [],
          sire2VettingPeriods: [],
          staffers: []
        };
}

var QueryDef = {
  makeRead: makeRead,
  makeWrite: makeWrite,
  makeResponse: makeResponse
};

var include = Curry._1(Database.MakeQuery, QueryDef);

var defaultDescription_removing = [];

var defaultDescription = {
  saving: 0,
  removing: defaultDescription_removing,
  clearing: false
};

function desc(actions) {
  return actions.reduce((function (param, action) {
                var clearing = param.clearing;
                var removing = param.removing;
                var saving = param.saving;
                if (typeof action !== "object") {
                  return {
                          saving: saving,
                          removing: removing,
                          clearing: true
                        };
                } else if (action.TAG === "Save") {
                  return {
                          saving: saving + 1 | 0,
                          removing: removing,
                          clearing: clearing
                        };
                } else {
                  return {
                          saving: saving,
                          removing: Prelude.$$Array.append(removing, action._0),
                          clearing: clearing
                        };
                }
              }), defaultDescription);
}

function describe(actions) {
  var actions$1 = ReIndexed_Transaction._simplifyWriteRequest(actions);
  var result = Curry._1(Prelude.Dict.fromArray, Curry._1(Prelude.Dict.entries, actions$1).map(function (param) {
            return [
                    param[0],
                    desc(param[1])
                  ];
          }));
  if (Prelude.$$Array.isEmpty(Object.keys(result))) {
    return ;
  } else {
    return result;
  }
}

function info(msg, actions) {
  console.log(msg, describe(actions));
}

function warn(msg, actions) {
  console.warn(msg, describe(actions));
}

function error(msg, actions) {
  console.error(msg, describe(actions));
}

var ActionLogging = {
  info: info,
  warn: warn,
  error: error
};

var makeRead$1 = include.makeRead;

var makeWrite$1 = include.makeWrite;

var makeResponse$1 = include.makeResponse;

var read = include.read;

var write = include.write;

var $$do = include.$$do;

var Unsafe = include.Unsafe;

var Safe = include.Safe;

var value = include.value;

export {
  Database ,
  QueryDef ,
  makeRead$1 as makeRead,
  makeWrite$1 as makeWrite,
  makeResponse$1 as makeResponse,
  read ,
  write ,
  $$do ,
  Unsafe ,
  Safe ,
  value ,
  ActionLogging ,
}
/* Database Not a pure module */
