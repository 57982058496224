// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppSchema from "../../AppSchema.js";
import * as Inspection from "../../survey/Inspection.js";
import * as InspectionAPI from "../endpoints/InspectionAPI.js";
import * as ServerSyncPipeline from "../common/ServerSyncPipeline.js";
import * as ServerSyncPermissions from "./ServerSyncPermissions.js";

var convertInspection = ServerSyncPermissions.ReadServerState.convertInspection;

function convertInspections(state, inspections) {
  var getProcedure = function (extra) {
    return AppSchema.Procedures.get(state, extra);
  };
  return Curry._3(Prelude.$$Array.fold, inspections, state, (function (s, i) {
                return convertInspection(s, getProcedure, i);
              }));
}

function $$do(state) {
  var $$final = state.final;
  var initial = state.initial;
  var vessels = state.vessels;
  var user = state.user;
  var inspectionsIds = Inspection.ids(AppSchema.Inspections.all($$final));
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  return Prelude.then(Query.read((newrecord.draftCompanyFormSubmissions = {
                    TAG: "AnyOf",
                    _0: "vessel_id",
                    _1: vessels
                  }, newrecord)), (function (param) {
                var submissionsInspectionsIds = Curry._2(Prelude.$$Array.keep, Curry._1(Prelude.$$Array.concatMany, param.draftCompanyFormSubmissions.map(function (i) {
                              return i.inspections;
                            })), (function (inspectionId) {
                        return !Prelude.$$Array.includes(inspectionsIds, inspectionId);
                      }));
                if (Prelude.$$Array.isEmpty(submissionsInspectionsIds)) {
                  return Promise.resolve({
                              TAG: "Ok",
                              _0: {
                                user: user,
                                vessels: vessels,
                                initial: initial,
                                final: $$final
                              }
                            });
                } else {
                  return Prelude.PromisedResult.map(InspectionAPI.Read.getMany(undefined, undefined, user, submissionsInspectionsIds), (function (inspections) {
                                var $$final$1 = convertInspections($$final, inspections);
                                return {
                                        user: user,
                                        vessels: vessels,
                                        initial: initial,
                                        final: $$final$1
                                      };
                              }));
                }
              }));
}

var ReadServerState = {
  convertInspection: convertInspection,
  convertInspections: convertInspections,
  $$do: $$do
};

function $$do$1(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  newrecord.inspections = AppSchema.Inspections.getActions(initial, $$final);
  Query.ActionLogging.info("ServerSyncSubmissionsInspections" + ": db actions: ", newrecord);
  return Prelude.PromisedResult.map(Query.Safe.write(newrecord), (function (param) {
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var UpdateDB = {
  $$do: $$do$1
};

var FillInitialStatus = ServerSyncPipeline.MakeNoOpPipelineStep({});

var ApplyLocallyKnownState = ServerSyncPipeline.MakeNoOpPipelineStep({});

var views = [AppSchema.Inspections];

var CollectFiles = ServerSyncPipeline.MakeFilesCollector({
      views: views
    });

var CleanUpState = ServerSyncPipeline.MakeNoOpPipelineStep({});

var include = ServerSyncPipeline.MakeStandardAPIPipeline({
      name: "ServerSyncSubmissionsInspections",
      ReadServerState: {
        $$do: $$do
      },
      FillInitialStatus: FillInitialStatus,
      ApplyLocallyKnownState: ApplyLocallyKnownState,
      UpdateDB: UpdateDB,
      CollectFiles: CollectFiles,
      CleanUpState: CleanUpState
    });

var $$do$2 = include.$$do;

export {
  ReadServerState ,
  UpdateDB ,
  $$do$2 as $$do,
}
/* include Not a pure module */
