// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppSchema from "../../AppSchema.js";
import * as LevelsAPI from "../endpoints/LevelsAPI.js";
import * as ServerSyncPipeline from "../common/ServerSyncPipeline.js";

function convertLevelSubmissions(state, level, submissions) {
  return AppSchema.LevelSubmissions.updateMany(state, submissions.map(function (submission) {
                  return {
                          id: submission.id,
                          name: submission.name,
                          vessel_id: level.vessel_id,
                          level_id: level.id,
                          timestamp: submission.timestamp,
                          target_date: submission.target_date,
                          answers: submission.answers,
                          recurrence: level.recurrence,
                          isCompleted: true
                        };
                }));
}

function convertLevel(state, level) {
  var applevel_id = level.id;
  var applevel_parent_id = level.parent_id;
  var applevel_vessel_id = level.vessel_id;
  var applevel_guidance_document_id = level.guidance_document_id;
  var applevel_name = level.name;
  var applevel_path = level.path;
  var applevel_number = level.number;
  var applevel_depth = level.depth;
  var applevel_recurrence = level.recurrence;
  var applevel_due_date = level.due_date;
  var applevel_overdue_date = level.overdue_date;
  var applevel_ignore_overdue_status = level.ignore_overdue_status;
  var applevel_kind = level.kind;
  var applevel_risk_status = level.risk_status;
  var applevel = {
    id: applevel_id,
    parent_id: applevel_parent_id,
    vessel_id: applevel_vessel_id,
    guidance_document_id: applevel_guidance_document_id,
    name: applevel_name,
    path: applevel_path,
    number: applevel_number,
    depth: applevel_depth,
    recurrence: applevel_recurrence,
    due_date: applevel_due_date,
    overdue_date: applevel_overdue_date,
    ignore_overdue_status: applevel_ignore_overdue_status,
    kind: applevel_kind,
    risk_status: applevel_risk_status
  };
  AppSchema.Levels.update(state, applevel);
  return convertLevelSubmissions(state, applevel, level.last_submissions);
}

function convertLevels(state, levels) {
  return levels.reduce(convertLevel, state);
}

function $$do(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  return Prelude.PromisedResult.map(LevelsAPI.getAll(undefined, {
                  vessel__id__in: vessels.join(",")
                }, user), (function (levels) {
                var $$final$1 = convertLevels($$final, levels);
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final$1
                      };
              }));
}

var ReadServerState = {
  convertLevelSubmissions: convertLevelSubmissions,
  convertLevel: convertLevel,
  convertLevels: convertLevels,
  $$do: $$do
};

function $$do$1(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  return Prelude.PromisedResult.map(Query.Safe.read((newrecord.levels = {
                    TAG: "AnyOf",
                    _0: "vessel_id",
                    _1: vessels
                  }, newrecord)), (function (param) {
                Curry._2(Prelude.$$Array.forEach, param.levels, (function (extra) {
                        return AppSchema.Levels.update(initial, extra);
                      }));
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var FillInitialStatus = {
  $$do: $$do$1
};

function $$do$2(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  newrecord.levelSubmissions = AppSchema.LevelSubmissions.getActions(initial, $$final);
  newrecord.levels = AppSchema.Levels.getActions(initial, $$final);
  Query.ActionLogging.info("ServerSyncLevels" + ": db actions: ", newrecord);
  return Prelude.PromisedResult.map(Query.Safe.write(newrecord), (function (param) {
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var UpdateDB = {
  $$do: $$do$2
};

var ApplyLocallyKnownState = ServerSyncPipeline.MakeNoOpPipelineStep({});

var views = [
  AppSchema.Levels,
  AppSchema.LevelSubmissions
];

var CollectFiles = ServerSyncPipeline.MakeFilesCollector({
      views: views
    });

var CleanUpState = ServerSyncPipeline.MakeNoOpPipelineStep({});

var include = ServerSyncPipeline.MakeStandardAPIPipeline({
      name: "ServerSyncLevels",
      ReadServerState: {
        $$do: $$do
      },
      FillInitialStatus: FillInitialStatus,
      ApplyLocallyKnownState: ApplyLocallyKnownState,
      UpdateDB: UpdateDB,
      CollectFiles: CollectFiles,
      CleanUpState: CleanUpState
    });

var $$do$3 = include.$$do;

export {
  ReadServerState ,
  FillInitialStatus ,
  UpdateDB ,
  $$do$3 as $$do,
}
/* include Not a pure module */
