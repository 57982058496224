// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Forms from "../../common/Forms.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Utils from "../../../libs/Utils.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as DataList from "../../../libs/DataList.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as QuestionType from "../QuestionType.js";

var Id = Utils.MakeOpaqueIdentifier({});

function editInspection(vnode, $$event) {
  $$event.stopPropagation();
  var text = $$event.target.value;
  var state = vnode.state.data;
  vnode.state.data = {
    text: text,
    widgetId: state.widgetId
  };
  vnode.attrs.setText(text);
}

function make(param) {
  return Mithril.view(Mithril.onupdate(Mithril.oncreate(Mithril.oninit(Mithril.component({
                              text: "",
                              widgetId: Id.make()
                            }), (function (vnode) {
                            var match = vnode.state.data;
                            var inspection = vnode.attrs.inspection;
                            Mithril.setState(vnode, {
                                  text: Prelude.default(QuestionType.Value.getText(inspection.value), ""),
                                  widgetId: match.widgetId
                                });
                          })), Forms.Input.focus), Forms.Input.focus), (function (vnode) {
                var procedure = vnode.attrs.procedure;
                var match = vnode.state.data;
                var widgetId = match.widgetId;
                var text = match.text;
                var options = procedure.suggested_text_values;
                var tmp;
                var exit = 0;
                if (options !== undefined && options.length !== 0) {
                  tmp = [
                    React.jsx(DataList.make, {
                          options: options,
                          id: widgetId
                        }),
                    ReactDOM.jsx("input", {
                          className: "form-input",
                          id: "question-input-text",
                          list: widgetId,
                          name: procedure.id,
                          value: text,
                          onKeyUp: (function (extra) {
                              return editInspection(vnode, extra);
                            })
                        })
                  ];
                } else {
                  exit = 1;
                }
                if (exit === 1) {
                  tmp = [ReactDOM.jsx("textarea", {
                          children: text,
                          className: "form-input",
                          id: "question-input-text",
                          name: procedure.id,
                          rows: 3,
                          onKeyUp: (function (extra) {
                              return editInspection(vnode, extra);
                            })
                        })];
                }
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("label", {
                                    children: procedure.name,
                                    className: "form-label",
                                    htmlFor: "question-input-text"
                                  }),
                              tmp
                            ],
                            className: "form-group"
                          });
              }));
}

export {
  Id ,
  editInspection ,
  make ,
}
/* Id Not a pure module */
