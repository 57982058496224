// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril from "mithril";
import * as Settings from "../kaiko/Settings.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Browser from "@sentry/browser";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as Mithril__Request from "./rescript-mithril/src/Mithril__Request.js";

function withApi(url) {
  return Settings.apiBaseUrl + url;
}

function withAuth(request, user) {
  return Mithril__Request.addHeaders(request, [[
                "Authorization",
                "Token " + user.token
              ]]);
}

function MakeURLBuilder(D) {
  var urlParts = D.url.split("/");
  var _key = ((obj, name) => obj[name]);
  var build = function (params) {
    return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, params, (function (params) {
                      return urlParts.map(function (p) {
                                    return encodeURIComponent(Prelude.default(_key(params, ":" + p + ":"), p));
                                  }).join("/");
                    })), D.url);
  };
  return {
          url: D.url,
          build: build,
          $$encodeURIComponent: (function (prim) {
              return encodeURIComponent(prim);
            })
        };
}

function MakeReadonlyAPIEndpoint(D) {
  var urlParts = D.url.split("/");
  var _key = ((obj, name) => obj[name]);
  var build = function (params) {
    return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, params, (function (params) {
                      return urlParts.map(function (p) {
                                    return encodeURIComponent(Prelude.default(_key(params, ":" + p + ":"), p));
                                  }).join("/");
                    })), D.url);
  };
  var URL_url = D.url;
  var URL_encodeURIComponent = function (prim) {
    return encodeURIComponent(prim);
  };
  var $$URL = {
    url: URL_url,
    build: build,
    $$encodeURIComponent: URL_encodeURIComponent
  };
  var $$encodeURIComponent$1 = URL_encodeURIComponent;
  var fields = {
    TAG: "Object",
    _0: D.fields
  };
  var Deserializer = $$JSON.MakeDeserializer({
        fields: fields
      });
  var UnexpectedAPIPayload = /* @__PURE__ */Caml_exceptions.create("ApiMaker.MakeReadonlyAPIEndpoint(D).UnexpectedAPIPayload");
  var _getManyPage = function (params, payload, user, ids) {
    var url = build(params);
    var request = Mithril__Request.get(Settings.apiBaseUrl + url);
    var request$1 = Mithril__Request.addHeaders(request, [[
            "X-Kaiko-AppVersion",
            Settings.releaseVersion
          ]]);
    var request$2 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, user, (function (extra) {
                return withAuth(request$1, extra);
              })), request$1);
    var payload$1 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, ids, (function (ids) {
                return Caml_option.some(Object.assign(Prelude.default(payload, {}), {
                                id__in: Belt_SetString.toArray(Belt_SetString.fromArray(ids)).map(Curry.__1($$encodeURIComponent$1)).join(",")
                              }));
              })), payload);
    var request$3 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, payload$1, (function (payload) {
                return Mithril__Request.payload(request$2, payload);
              })), request$2);
    console.log("ApiMaker", "_getManyPage", {
          ids: ids,
          params: params,
          payload: payload$1,
          request: request$3
        });
    return Prelude.catchResolve(Prelude.PromisedResult.bind(Mithril__Request.$$do(request$3), (async function (response) {
                      var items = Js_json.classify(response);
                      if (typeof items !== "object") {
                        if (items === "JSONNull") {
                          return {
                                  TAG: "Ok",
                                  _0: []
                                };
                        }
                        throw {
                              RE_EXN_ID: UnexpectedAPIPayload,
                              _1: "Expected a list of items.",
                              Error: new Error()
                            };
                      } else {
                        switch (items.TAG) {
                          case "JSONObject" :
                              var parsed = Deserializer.fromJSON(response);
                              if (parsed.TAG === "Ok") {
                                return {
                                        TAG: "Ok",
                                        _0: [parsed._0]
                                      };
                              }
                              throw {
                                    RE_EXN_ID: UnexpectedAPIPayload,
                                    _1: "Could not parse the result from the API endpoint: " + parsed._0,
                                    Error: new Error()
                                  };
                          case "JSONArray" :
                              var items$1 = items._0;
                              if (items$1.length === 0) {
                                return {
                                        TAG: "Ok",
                                        _0: []
                                      };
                              }
                              var parsed$1 = Prelude.ManyResults.bail(items$1.map(function (param) {
                                        return Curry._1(Deserializer.fromJSON, param);
                                      }));
                              if (parsed$1.TAG === "Ok") {
                                return {
                                        TAG: "Ok",
                                        _0: parsed$1._0
                                      };
                              }
                              throw {
                                    RE_EXN_ID: UnexpectedAPIPayload,
                                    _1: "Could not parse some item in the list of the API endpoint: " + parsed$1._0,
                                    Error: new Error()
                                  };
                          default:
                            throw {
                                  RE_EXN_ID: UnexpectedAPIPayload,
                                  _1: "Expected a list of items.",
                                  Error: new Error()
                                };
                        }
                      }
                    })), (function (e) {
                  Browser.captureException(new Error(JSON.stringify(e)));
                  return {
                          TAG: "Error",
                          _0: e
                        };
                }));
  };
  var _getMany = function (params, payload, user, ids) {
    if (ids === undefined) {
      return _getManyPage(params, payload, user, undefined);
    }
    var ids$1 = Belt_SetString.toArray(Belt_SetString.fromArray(ids));
    var chunks = Prelude.$$Array.chunks(ids$1, 45);
    return Prelude.PromisedResult.map(Prelude.PromisedResult.bail(chunks.map(function (ids) {
                        return function () {
                          return _getManyPage(params, payload, user, ids);
                        };
                      })), (function (ls) {
                  return Curry._1(Prelude.$$Array.concatMany, ls);
                }));
  };
  var getMany = function (params, payload, user, ids) {
    return Prelude.thenResolve(_getMany(params, payload, user, ids), (function (result) {
                  if (result.TAG !== "Ok") {
                    return {
                            TAG: "Error",
                            _0: result._0
                          };
                  }
                  var ls = result._0;
                  console.log("ApiMaker", "getMany", {
                        params: params,
                        ids: ids,
                        user: Curry._2(Prelude.OptionExported.$$Option.map, user, (function (u) {
                                return u.email;
                              })),
                        "len()": ls.length
                      });
                  return {
                          TAG: "Ok",
                          _0: ls
                        };
                }));
  };
  var getAll = function (params, payload, user) {
    return Prelude.thenResolve(_getMany(params, payload, user, undefined), (function (result) {
                  if (result.TAG !== "Ok") {
                    return {
                            TAG: "Error",
                            _0: result._0
                          };
                  }
                  var ls = result._0;
                  console.log("ApiMaker", "getAll", {
                        params: params,
                        user: Curry._2(Prelude.OptionExported.$$Option.map, user, (function (u) {
                                return u.email;
                              })),
                        "len()": ls.length
                      });
                  return {
                          TAG: "Ok",
                          _0: ls
                        };
                }));
  };
  var get = function (params, payload, user, id) {
    var promise = id.length > 0 ? _getMany(params, payload, user, [id]) : _getMany(params, payload, user, undefined);
    return Prelude.catchResolve(Prelude.PromisedResult.map(Prelude.thenResolve(promise, (function (result) {
                          if (result.TAG !== "Ok") {
                            return {
                                    TAG: "Error",
                                    _0: result._0
                                  };
                          }
                          var ls = result._0;
                          console.log("ApiMaker", "get", {
                                params: params,
                                id: id,
                                user: Curry._2(Prelude.OptionExported.$$Option.map, user, (function (u) {
                                        return u.email;
                                      })),
                                "len()": ls.length
                              });
                          return {
                                  TAG: "Ok",
                                  _0: ls
                                };
                        })), (function (result) {
                      var len = result.length;
                      if (len === 1) {
                        return Caml_option.some(result[0]);
                      }
                      if (len === 0) {
                        return ;
                      }
                      throw {
                            RE_EXN_ID: UnexpectedAPIPayload,
                            _1: "Got more than one item, expecting 0 or 1",
                            Error: new Error()
                          };
                    })), (function (e) {
                  return {
                          TAG: "Error",
                          _0: e
                        };
                }));
  };
  var options = function (params, user) {
    var url = build(params);
    var request = Mithril__Request.options(Settings.apiBaseUrl + url);
    var request$1 = Mithril__Request.addHeaders(request, [[
            "X-Kaiko-AppVersion",
            Settings.releaseVersion
          ]]);
    var request$2 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, user, (function (extra) {
                return withAuth(request$1, extra);
              })), request$1);
    return Mithril__Request.$$do(request$2);
  };
  return {
          Deserializer: Deserializer,
          $$URL: $$URL,
          UnexpectedAPIPayload: UnexpectedAPIPayload,
          getMany: getMany,
          getAll: getAll,
          get: get,
          options: options
        };
}

function MakeAPIEndpoint(D) {
  var urlParts = D.url.split("/");
  var _key = ((obj, name) => obj[name]);
  var build = function (params) {
    return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, params, (function (params) {
                      return urlParts.map(function (p) {
                                    return encodeURIComponent(Prelude.default(_key(params, ":" + p + ":"), p));
                                  }).join("/");
                    })), D.url);
  };
  var URL_url = D.url;
  var URL_encodeURIComponent = function (prim) {
    return encodeURIComponent(prim);
  };
  var $$URL = {
    url: URL_url,
    build: build,
    $$encodeURIComponent: URL_encodeURIComponent
  };
  var $$encodeURIComponent$1 = URL_encodeURIComponent;
  var fields = {
    TAG: "Object",
    _0: D.fields
  };
  var Deserializer = $$JSON.MakeDeserializer({
        fields: fields
      });
  var UnexpectedAPIPayload = /* @__PURE__ */Caml_exceptions.create("ApiMaker.MakeReadonlyAPIEndpoint(D).UnexpectedAPIPayload");
  var _getManyPage = function (params, payload, user, ids) {
    var url = build(params);
    var request = Mithril__Request.get(Settings.apiBaseUrl + url);
    var request$1 = Mithril__Request.addHeaders(request, [[
            "X-Kaiko-AppVersion",
            Settings.releaseVersion
          ]]);
    var request$2 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, user, (function (extra) {
                return withAuth(request$1, extra);
              })), request$1);
    var payload$1 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, ids, (function (ids) {
                return Caml_option.some(Object.assign(Prelude.default(payload, {}), {
                                id__in: Belt_SetString.toArray(Belt_SetString.fromArray(ids)).map(Curry.__1($$encodeURIComponent$1)).join(",")
                              }));
              })), payload);
    var request$3 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, payload$1, (function (payload) {
                return Mithril__Request.payload(request$2, payload);
              })), request$2);
    console.log("ApiMaker", "_getManyPage", {
          ids: ids,
          params: params,
          payload: payload$1,
          request: request$3
        });
    return Prelude.catchResolve(Prelude.PromisedResult.bind(Mithril__Request.$$do(request$3), (async function (response) {
                      var items = Js_json.classify(response);
                      if (typeof items !== "object") {
                        if (items === "JSONNull") {
                          return {
                                  TAG: "Ok",
                                  _0: []
                                };
                        }
                        throw {
                              RE_EXN_ID: UnexpectedAPIPayload,
                              _1: "Expected a list of items.",
                              Error: new Error()
                            };
                      } else {
                        switch (items.TAG) {
                          case "JSONObject" :
                              var parsed = Deserializer.fromJSON(response);
                              if (parsed.TAG === "Ok") {
                                return {
                                        TAG: "Ok",
                                        _0: [parsed._0]
                                      };
                              }
                              throw {
                                    RE_EXN_ID: UnexpectedAPIPayload,
                                    _1: "Could not parse the result from the API endpoint: " + parsed._0,
                                    Error: new Error()
                                  };
                          case "JSONArray" :
                              var items$1 = items._0;
                              if (items$1.length === 0) {
                                return {
                                        TAG: "Ok",
                                        _0: []
                                      };
                              }
                              var parsed$1 = Prelude.ManyResults.bail(items$1.map(function (param) {
                                        return Curry._1(Deserializer.fromJSON, param);
                                      }));
                              if (parsed$1.TAG === "Ok") {
                                return {
                                        TAG: "Ok",
                                        _0: parsed$1._0
                                      };
                              }
                              throw {
                                    RE_EXN_ID: UnexpectedAPIPayload,
                                    _1: "Could not parse some item in the list of the API endpoint: " + parsed$1._0,
                                    Error: new Error()
                                  };
                          default:
                            throw {
                                  RE_EXN_ID: UnexpectedAPIPayload,
                                  _1: "Expected a list of items.",
                                  Error: new Error()
                                };
                        }
                      }
                    })), (function (e) {
                  Browser.captureException(new Error(JSON.stringify(e)));
                  return {
                          TAG: "Error",
                          _0: e
                        };
                }));
  };
  var _getMany = function (params, payload, user, ids) {
    if (ids === undefined) {
      return _getManyPage(params, payload, user, undefined);
    }
    var ids$1 = Belt_SetString.toArray(Belt_SetString.fromArray(ids));
    var chunks = Prelude.$$Array.chunks(ids$1, 45);
    return Prelude.PromisedResult.map(Prelude.PromisedResult.bail(chunks.map(function (ids) {
                        return function () {
                          return _getManyPage(params, payload, user, ids);
                        };
                      })), (function (ls) {
                  return Curry._1(Prelude.$$Array.concatMany, ls);
                }));
  };
  var getMany = function (params, payload, user, ids) {
    return Prelude.thenResolve(_getMany(params, payload, user, ids), (function (result) {
                  if (result.TAG !== "Ok") {
                    return {
                            TAG: "Error",
                            _0: result._0
                          };
                  }
                  var ls = result._0;
                  console.log("ApiMaker", "getMany", {
                        params: params,
                        ids: ids,
                        user: Curry._2(Prelude.OptionExported.$$Option.map, user, (function (u) {
                                return u.email;
                              })),
                        "len()": ls.length
                      });
                  return {
                          TAG: "Ok",
                          _0: ls
                        };
                }));
  };
  var getAll = function (params, payload, user) {
    return Prelude.thenResolve(_getMany(params, payload, user, undefined), (function (result) {
                  if (result.TAG !== "Ok") {
                    return {
                            TAG: "Error",
                            _0: result._0
                          };
                  }
                  var ls = result._0;
                  console.log("ApiMaker", "getAll", {
                        params: params,
                        user: Curry._2(Prelude.OptionExported.$$Option.map, user, (function (u) {
                                return u.email;
                              })),
                        "len()": ls.length
                      });
                  return {
                          TAG: "Ok",
                          _0: ls
                        };
                }));
  };
  var get = function (params, payload, user, id) {
    var promise = id.length > 0 ? _getMany(params, payload, user, [id]) : _getMany(params, payload, user, undefined);
    return Prelude.catchResolve(Prelude.PromisedResult.map(Prelude.thenResolve(promise, (function (result) {
                          if (result.TAG !== "Ok") {
                            return {
                                    TAG: "Error",
                                    _0: result._0
                                  };
                          }
                          var ls = result._0;
                          console.log("ApiMaker", "get", {
                                params: params,
                                id: id,
                                user: Curry._2(Prelude.OptionExported.$$Option.map, user, (function (u) {
                                        return u.email;
                                      })),
                                "len()": ls.length
                              });
                          return {
                                  TAG: "Ok",
                                  _0: ls
                                };
                        })), (function (result) {
                      var len = result.length;
                      if (len === 1) {
                        return Caml_option.some(result[0]);
                      }
                      if (len === 0) {
                        return ;
                      }
                      throw {
                            RE_EXN_ID: UnexpectedAPIPayload,
                            _1: "Got more than one item, expecting 0 or 1",
                            Error: new Error()
                          };
                    })), (function (e) {
                  return {
                          TAG: "Error",
                          _0: e
                        };
                }));
  };
  var options = function (params, user) {
    var url = build(params);
    var request = Mithril__Request.options(Settings.apiBaseUrl + url);
    var request$1 = Mithril__Request.addHeaders(request, [[
            "X-Kaiko-AppVersion",
            Settings.releaseVersion
          ]]);
    var request$2 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, user, (function (extra) {
                return withAuth(request$1, extra);
              })), request$1);
    return Mithril__Request.$$do(request$2);
  };
  var _injectParams = function (params, request) {
    if (params !== undefined) {
      return {
              url: request.url,
              method: request.method,
              params: Caml_option.valFromOption(params),
              body: request.body,
              headers: request.headers,
              background: request.background
            };
    } else {
      return request;
    }
  };
  var postMany = function (params, user, items) {
    var url = build(undefined);
    var request = Mithril__Request.post(Settings.apiBaseUrl + url);
    var request$1 = Mithril__Request.addHeaders(request, [[
            "X-Kaiko-AppVersion",
            Settings.releaseVersion
          ]]);
    var request$2 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, user, (function (extra) {
                return withAuth(request$1, extra);
              })), request$1);
    var request$3 = _injectParams(params, request$2);
    return Prelude.PromisedResult.mapError(Prelude.catchResolve(Prelude.PromisedResult.bind(Mithril__Request.$$do(Mithril__Request.payload(request$3, items)), (function (result) {
                          var items = Js_json.classify(result);
                          var res;
                          if (typeof items !== "object") {
                            if (items === "JSONNull") {
                              res = {
                                TAG: "Ok",
                                _0: []
                              };
                            } else {
                              throw {
                                    RE_EXN_ID: UnexpectedAPIPayload,
                                    _1: "Unexpected response",
                                    Error: new Error()
                                  };
                            }
                          } else {
                            switch (items.TAG) {
                              case "JSONObject" :
                                  res = Curry._2(Prelude.Result.map, Deserializer.fromJSON(result), (function (x) {
                                          return [x];
                                        }));
                                  break;
                              case "JSONArray" :
                                  res = Prelude.ManyResults.bail(items._0.map(function (param) {
                                            return Curry._1(Deserializer.fromJSON, param);
                                          }));
                                  break;
                              default:
                                throw {
                                      RE_EXN_ID: UnexpectedAPIPayload,
                                      _1: "Unexpected response",
                                      Error: new Error()
                                    };
                            }
                          }
                          if (res.TAG === "Ok") {
                            return Promise.resolve({
                                        TAG: "Ok",
                                        _0: res._0
                                      });
                          }
                          throw {
                                RE_EXN_ID: UnexpectedAPIPayload,
                                _1: res._0,
                                Error: new Error()
                              };
                        })), (function (e) {
                      return {
                              TAG: "Error",
                              _0: e
                            };
                    })), (function (error) {
                  try {
                    console.warn("ApiMaker", "postMany", {
                          url: url,
                          params: params,
                          user: Curry._2(Prelude.OptionExported.$$Option.map, user, (function (u) {
                                  return u.email;
                                })),
                          error: JSON.stringify(error)
                        });
                    Browser.captureException(new Error(JSON.stringify(error)));
                  }
                  catch (exn){
                    
                  }
                  return error;
                }));
  };
  var postOne = function (params, user, item) {
    var url = build(undefined);
    var request = Mithril__Request.post(Settings.apiBaseUrl + url);
    var request$1 = Mithril__Request.addHeaders(request, [[
            "X-Kaiko-AppVersion",
            Settings.releaseVersion
          ]]);
    var request$2 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, user, (function (extra) {
                return withAuth(request$1, extra);
              })), request$1);
    var request$3 = _injectParams(params, request$2);
    return Prelude.PromisedResult.mapError(Prelude.catchResolve(Prelude.PromisedResult.bind(Mithril__Request.$$do(Mithril__Request.payload(request$3, item)), (function (result) {
                          var match = Js_json.classify(result);
                          var res;
                          if (typeof match !== "object") {
                            if (match === "JSONNull") {
                              res = {
                                TAG: "Ok",
                                _0: undefined
                              };
                            } else {
                              throw {
                                    RE_EXN_ID: UnexpectedAPIPayload,
                                    _1: "Unexpected response",
                                    Error: new Error()
                                  };
                            }
                          } else if (match.TAG === "JSONObject") {
                            res = Curry._2(Prelude.Result.map, Deserializer.fromJSON(result), (function (x) {
                                    return Caml_option.some(x);
                                  }));
                          } else {
                            throw {
                                  RE_EXN_ID: UnexpectedAPIPayload,
                                  _1: "Unexpected response",
                                  Error: new Error()
                                };
                          }
                          if (res.TAG === "Ok") {
                            return Promise.resolve({
                                        TAG: "Ok",
                                        _0: res._0
                                      });
                          }
                          throw {
                                RE_EXN_ID: UnexpectedAPIPayload,
                                _1: res._0,
                                Error: new Error()
                              };
                        })), (function (e) {
                      return {
                              TAG: "Error",
                              _0: e
                            };
                    })), (function (error) {
                  try {
                    console.warn("ApiMaker", "postOne", {
                          url: url,
                          params: params,
                          user: Curry._2(Prelude.OptionExported.$$Option.map, user, (function (u) {
                                  return u.email;
                                })),
                          error: JSON.stringify(error)
                        });
                    Browser.captureException(new Error(JSON.stringify(error)));
                  }
                  catch (exn){
                    
                  }
                  return error;
                }));
  };
  var postForm = function (params, user, form) {
    var url = build(undefined);
    var request = Mithril__Request.post(Settings.apiBaseUrl + url);
    var request$1 = Mithril__Request.addHeaders(request, [[
            "X-Kaiko-AppVersion",
            Settings.releaseVersion
          ]]);
    var request$2 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, user, (function (extra) {
                return withAuth(request$1, extra);
              })), request$1);
    var request$3 = _injectParams(params, request$2);
    return Prelude.PromisedResult.mapError(Prelude.catchResolve(Prelude.PromisedResult.mapError(Prelude.thenResolve(Mithril.request(Mithril__Request.form(request$3, form)), Deserializer.fromJSON), (function (msg) {
                          throw {
                                RE_EXN_ID: UnexpectedAPIPayload,
                                _1: msg,
                                Error: new Error()
                              };
                        })), (function (e) {
                      return {
                              TAG: "Error",
                              _0: e
                            };
                    })), (function (error) {
                  try {
                    console.warn("ApiMaker", "postForm", {
                          url: url,
                          params: params,
                          user: Curry._2(Prelude.OptionExported.$$Option.map, user, (function (u) {
                                  return u.email;
                                })),
                          error: JSON.stringify(error)
                        });
                    Browser.captureException(new Error(JSON.stringify(error)));
                  }
                  catch (exn){
                    
                  }
                  return error;
                }));
  };
  return {
          Deserializer: Deserializer,
          $$URL: $$URL,
          UnexpectedAPIPayload: UnexpectedAPIPayload,
          getMany: getMany,
          get: get,
          getAll: getAll,
          options: options,
          postMany: postMany,
          postOne: postOne,
          postForm: postForm
        };
}

export {
  withApi ,
  withAuth ,
  MakeURLBuilder ,
  MakeReadonlyAPIEndpoint ,
  MakeAPIEndpoint ,
}
/* mithril Not a pure module */
