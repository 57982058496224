// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as Rating from "../../survey/Rating.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppSchema from "../../AppSchema.js";
import * as StylingRuleAPI from "../endpoints/StylingRuleAPI.js";
import * as ServerSyncPipeline from "../common/ServerSyncPipeline.js";

function $$do(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var ids = Curry._2(Prelude.$$Array.keepMap, AppSchema.Procedures.all($$final), (function (p) {
          return p.rating_styling_rules_id;
        }));
  if (ids.length !== 0) {
    return Prelude.PromisedResult.map(Prelude.PromisedResult.map(Prelude.PromisedResult.flatMapError(StylingRuleAPI.getMany(undefined, undefined, user, ids), (function (param) {
                          return {
                                  TAG: "Ok",
                                  _0: []
                                };
                        })), (function (rules) {
                      return Curry._3(Prelude.$$Array.fold, rules, $$final, (function (s, r) {
                                    var newrecord = Caml_obj.obj_dup(s);
                                    newrecord.ratingStylingRules = Rating.StylingRule.Identifier.$$Map.fromArray(Prelude.$$Array.append(Rating.StylingRule.Identifier.$$Map.toArray(s.ratingStylingRules), [
                                              r.id,
                                              r
                                            ]));
                                    return newrecord;
                                  }));
                    })), (function ($$final) {
                  return {
                          user: user,
                          vessels: vessels,
                          initial: initial,
                          final: $$final
                        };
                }));
  } else {
    return Promise.resolve({
                TAG: "Ok",
                _0: {
                  user: user,
                  vessels: vessels,
                  initial: initial,
                  final: $$final
                }
              });
  }
}

var ReadServerState = {
  $$do: $$do
};

function $$do$1(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  return Prelude.PromisedResult.map(Query.Safe.read((newrecord.ratingStylingRules = "All", newrecord)), (function (param) {
                var rulesIndex = Rating.StylingRule.Identifier.$$Set.fromArray(Curry._2(Prelude.$$Array.keepMap, AppSchema.Procedures.all($$final), (function (p) {
                            return p.rating_styling_rules_id;
                          })));
                Curry._2(Prelude.$$Array.forEach, Curry._2(Prelude.$$Array.keep, param.ratingStylingRules, (function (r) {
                            return Rating.StylingRule.Identifier.$$Set.has(rulesIndex, r.id);
                          })), (function (extra) {
                        return AppSchema.RatingRules.update(initial, extra);
                      }));
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var FillInitialStatus = {
  $$do: $$do$1
};

function $$do$2(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  newrecord.ratingStylingRules = AppSchema.RatingRules.getActions(initial, $$final);
  Query.ActionLogging.info("ServerSyncStylingRules" + ": db actions: ", newrecord);
  return Prelude.PromisedResult.map(Query.Safe.write(newrecord), (function (param) {
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var UpdateDB = {
  $$do: $$do$2
};

var ApplyLocallyKnownState = ServerSyncPipeline.MakeNoOpPipelineStep({});

var views = [AppSchema.RatingRules];

var Views = {
  views: views
};

var CollectFiles = ServerSyncPipeline.MakeFilesCollector(Views);

var CleanUpState = ServerSyncPipeline.MakeStandardCleanup(Views);

var include = ServerSyncPipeline.MakeStandardAPIPipeline({
      name: "ServerSyncStylingRules",
      ReadServerState: ReadServerState,
      FillInitialStatus: FillInitialStatus,
      ApplyLocallyKnownState: ApplyLocallyKnownState,
      UpdateDB: UpdateDB,
      CollectFiles: CollectFiles,
      CleanUpState: CleanUpState
    });

var $$do$3 = include.$$do;

export {
  ReadServerState ,
  FillInitialStatus ,
  UpdateDB ,
  $$do$3 as $$do,
}
/* include Not a pure module */
