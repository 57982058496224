// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as PageHeader from "./PageHeader.js";
import * as PageLayout from "./PageLayout.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.js";
import * as PageNavigation from "./PageNavigation.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var headerContent = Core__Option.getOr(vnode.attrs.headerContent, "Logo");
                var headerNav = Core__Option.getOr(vnode.attrs.headerNav, "empty");
                var disabled = Core__Option.getOr(vnode.attrs.navbarDisabled, false);
                var mod = Core__Option.getOr(vnode.attrs.navbarMod, "home");
                var match = headerNav === "withAvatar" ? [
                    "Empty",
                    "Avatar"
                  ] : (
                    headerNav === "empty" ? [
                        "Empty",
                        "Empty"
                      ] : (
                        headerNav === "all" ? [
                            "BackButton",
                            "Avatar"
                          ] : [
                            "BackButton",
                            "Empty"
                          ]
                      )
                  );
                return React.jsx(PageLayout.make, {
                            header: [
                              ReactDOM.jsx("div", {
                                    className: "tw-h-header tw-min-h-header tw-max-h-header"
                                  }),
                              React.jsx(PageHeader.make, {
                                    left: match[0],
                                    right: match[1],
                                    content: headerContent,
                                    fixed: true
                                  })
                            ],
                            footer: [
                              ReactDOM.jsx("div", {
                                    className: "tw-h-footer tw-min-h-footer tw-max-h-footer"
                                  }),
                              React.jsx(PageNavigation.make, {
                                    mod: mod,
                                    disabled: disabled,
                                    fixed: true
                                  })
                            ],
                            children: vnode.children
                          });
              }));
}

export {
  make ,
}
/* React Not a pure module */
