// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";

function StillLoading(props) {
  return Mithril.view(Mithril.component(undefined), (function (param) {
                return ReactDOM.jsx("div", {
                            children: ReactDOM.jsx("div", {
                                  children: ReactDOM.jsx("p", {
                                        children: "Loading...",
                                        className: "mt-2 text-center",
                                        style: {
                                          width: "100%"
                                        }
                                      }),
                                  className: "loading loading-lg"
                                }),
                            style: {
                              marginTop: "3rem",
                              width: "100%"
                            }
                          });
              }));
}

var make = StillLoading;

export {
  make ,
}
/* Mithril Not a pure module */
