// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$URL from "./URL.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Utils from "./Utils.js";
import * as Network from "./Network.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IDB__Migration__Database from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Database.js";

var UUID = Utils.MakeOpaqueIdentifier({});

function migrations() {
  return [
          (function (param) {
              return function (db, _transaction) {
                IDB__Migration__Database.createObjectStore(db, "metadata");
                IDB__Migration__Database.createObjectStore(db, "resource");
                return Promise.resolve({
                            TAG: "Ok",
                            _0: undefined
                          });
              };
            }),
          (function (param) {
              return function (db, transaction) {
                var metadatas = IDB__Migration__Database.createObjectStore(db, "metadatas");
                var oldMetadatas = transaction.objectStore("metadata");
                oldMetadatas.openCursor(undefined, "next").onsuccess = (function ($$event) {
                    var cursor = $$event.target.result;
                    if (!(cursor == null)) {
                      ((function (__x) {
                              return metadatas.put(__x);
                            })({
                              id: cursor.key,
                              size: cursor.value.size
                            }));
                      cursor.continue();
                      return ;
                    }
                    
                  });
                var resources = IDB__Migration__Database.createObjectStore(db, "resources");
                var oldResources = transaction.objectStore("resource");
                oldResources.openCursor(undefined, "next").onsuccess = (function ($$event) {
                    var cursor = $$event.target.result;
                    if (!(cursor == null)) {
                      ((function (__x) {
                              return resources.put(__x);
                            })({
                              id: cursor.key,
                              file: cursor.value
                            }));
                      cursor.continue();
                      return ;
                    }
                    
                  });
                return Promise.resolve({
                            TAG: "Ok",
                            _0: undefined
                          });
              };
            }),
          (function (param) {
              return function (_db, transaction) {
                var metadatas = transaction.objectStore("metadatas");
                metadatas.openCursor(undefined, "next").onsuccess = (function ($$event) {
                    var cursor = $$event.target.result;
                    if (cursor == null) {
                      return ;
                    }
                    var value = cursor.value;
                    metadatas.put({
                          id: cursor.key,
                          size: value.size,
                          compressed: false
                        });
                    cursor.continue();
                  });
                return Promise.resolve({
                            TAG: "Ok",
                            _0: undefined
                          });
              };
            }),
          (function (param) {
              return function (db, _transaction) {
                db.deleteObjectStore("metadata");
                db.deleteObjectStore("resource");
                return Promise.resolve({
                            TAG: "Ok",
                            _0: undefined
                          });
              };
            }),
          (function (param) {
              return function (_db, transaction) {
                transaction.objectStore("metadatas").createIndex("file", "file");
                transaction.objectStore("resources").createIndex("url", "url");
                return Promise.resolve({
                            TAG: "Ok",
                            _0: undefined
                          });
              };
            }),
          (function (param) {
              return function (_db, transaction) {
                transaction.objectStore("resources").deleteIndex("url");
                transaction.objectStore("metadatas").createIndex("access_time", "access_time");
                return Promise.resolve({
                            TAG: "Ok",
                            _0: undefined
                          });
              };
            }),
          (function (param) {
              return async function (_db, transaction) {
                transaction.objectStore("metadatas").deleteIndex("access_time");
                return {
                        TAG: "Ok",
                        _0: undefined
                      };
              };
            })
        ];
}

var Database = ReIndexed.MakeDatabase({
      migrations: migrations
    });

var MetadataDef = {};

ReIndexed.MakeModel(MetadataDef);

var ResourceDef = {};

ReIndexed.MakeModel(ResourceDef);

function makeRead() {
  return {
          metadatas: "NoOp",
          resources: "NoOp"
        };
}

function makeWrite() {
  return {
          metadatas: [],
          resources: []
        };
}

function makeResponse() {
  return {
          metadatas: [],
          resources: []
        };
}

var QueryDef = {
  makeRead: makeRead,
  makeWrite: makeWrite,
  makeResponse: makeResponse
};

var Query = Curry._1(Database.MakeQuery, QueryDef);

function deleteMany(ids) {
  console.log("LocalFile", "DELETE", ids);
  return Query.write({
              metadatas: ids.map(function (id) {
                    return {
                            TAG: "Delete",
                            _0: id
                          };
                  }),
              resources: ids.map(function (id) {
                    return {
                            TAG: "Delete",
                            _0: id
                          };
                  })
            });
}

async function allIds() {
  var init = Query.makeRead();
  var match = await Query.read({
        metadatas: "All",
        resources: init.resources
      });
  return match.metadatas.map(function (meta) {
              return meta.id;
            });
}

async function clean(sureOpt, nonlocalOpt) {
  var sure = sureOpt !== undefined ? sureOpt : false;
  var nonlocal = nonlocalOpt !== undefined ? nonlocalOpt : true;
  if (!sure) {
    return ;
  }
  if (nonlocal) {
    var init = Query.makeRead();
    var match = await Query.read({
          metadatas: {
            TAG: "NotNull",
            _0: "file"
          },
          resources: init.resources
        });
    await deleteMany(match.metadatas.map(function (meta) {
              return meta.id;
            }));
    return ;
  }
  await Query.write({
        metadatas: ["Clear"],
        resources: ["Clear"]
      });
}

async function getMetadata(id) {
  var query = {
    TAG: "Get",
    _0: id
  };
  var init = Query.makeRead();
  var match = await Query.read({
        metadatas: query,
        resources: init.resources
      });
  var metadatas = match.metadatas;
  var access_time = Caml_option.some(new Date());
  var init$1 = Query.makeWrite();
  await Query.write({
        metadatas: metadatas.map(function (m) {
              return {
                      TAG: "Save",
                      _0: {
                        id: m.id,
                        size: m.size,
                        compressed: m.compressed,
                        file: m.file,
                        access_time: access_time
                      }
                    };
            }),
        resources: init$1.resources
      });
  return Prelude.$$Array.first(metadatas);
}

async function getManyResources(ids) {
  var match = await Query.read({
        metadatas: {
          TAG: "In",
          _0: ids
        },
        resources: {
          TAG: "In",
          _0: ids
        }
      });
  var access_time = Caml_option.some(new Date());
  var updates = match.metadatas.map(function (m) {
        return {
                TAG: "Save",
                _0: {
                  id: m.id,
                  size: m.size,
                  compressed: m.compressed,
                  file: m.file,
                  access_time: access_time
                }
              };
      });
  var init = Query.makeWrite();
  await Query.write({
        metadatas: updates,
        resources: init.resources
      });
  return match.resources;
}

async function getResource(id) {
  var resources = await getManyResources([id]);
  return Prelude.$$Array.first(resources);
}

async function getWithMetadata(id) {
  var match = await Query.read({
        metadatas: {
          TAG: "Get",
          _0: id
        },
        resources: {
          TAG: "Get",
          _0: id
        }
      });
  var metadatas = match.metadatas;
  var access_time = Caml_option.some(new Date());
  var init = Query.makeWrite();
  await Query.write({
        metadatas: metadatas.map(function (m) {
              return {
                      TAG: "Save",
                      _0: {
                        id: m.id,
                        size: m.size,
                        compressed: m.compressed,
                        file: m.file,
                        access_time: access_time
                      }
                    };
            }),
        resources: init.resources
      });
  return [
          Prelude.$$Array.first(metadatas),
          Prelude.$$Array.first(match.resources)
        ];
}

async function store(id, url, compressedOpt, blob) {
  var compressed = compressedOpt !== undefined ? compressedOpt : false;
  var access_time = Caml_option.some(new Date());
  var url$1 = Curry._2(Prelude.OptionExported.$$Option.map, url, $$URL.Utils.withoutSearch);
  var id$1 = id !== undefined ? Caml_option.valFromOption(id) : UUID.make();
  console.log("LocalFile", "STORE", {
        id: id$1,
        size: blob.size,
        url: url$1,
        compressed: compressed
      });
  var resource = {
    id: id$1,
    file: blob
  };
  var match = await Query.write({
        metadatas: [{
            TAG: "Save",
            _0: {
              id: id$1,
              size: blob.size,
              compressed: compressed,
              file: url$1,
              access_time: access_time
            }
          }],
        resources: [{
            TAG: "Save",
            _0: resource
          }]
      });
  var metadatas = match.metadatas;
  if (metadatas.length === 1) {
    var metadata = metadatas[0];
    var $$window$1 = window;
    var $$event = new Event("local-file:store", {
          id: metadata.id,
          size: metadata.size,
          compressed: metadata.compressed,
          file: metadata.file
        });
    $$window$1.dispatchEvent($$event);
  }
  return resource;
}

async function clone(key) {
  var match = await getWithMetadata(key);
  var resource = match[1];
  var metadata = match[0];
  if (metadata === undefined) {
    return ;
  }
  if (resource === undefined) {
    return ;
  }
  var url = metadata.file;
  var compressed = metadata.compressed;
  var resource$1 = await store(undefined, url, compressed, resource.file);
  return Caml_option.some(resource$1.id);
}

async function updateFileURL(id, file) {
  var url = $$URL.Utils.withoutSearch(file);
  console.log("LocalFile", "Updated URL", {
        id: id,
        url: url
      });
  var match = await Query.$$do([
        {
          TAG: "Read",
          _0: (function (param) {
              return {
                      metadatas: {
                        TAG: "Get",
                        _0: id
                      },
                      resources: "NoOp"
                    };
            })
        },
        {
          TAG: "Write",
          _0: (function (param) {
              var metadata = Prelude.$$Array.first(param.metadatas);
              if (metadata === undefined) {
                return Query.makeWrite();
              }
              var init = Query.makeWrite();
              return {
                      metadatas: [{
                          TAG: "Save",
                          _0: {
                            id: metadata.id,
                            size: metadata.size,
                            compressed: metadata.compressed,
                            file: Caml_option.some(url),
                            access_time: metadata.access_time
                          }
                        }],
                      resources: init.resources
                    };
            })
        }
      ]);
  var match$1 = Prelude.$$Array.first(match.metadatas);
  if (match$1 !== undefined) {
    return {
            TAG: "Ok",
            _0: id
          };
  } else {
    return {
            TAG: "Error",
            _0: id
          };
  }
}

async function downloadRequest(id, request, compressed) {
  var url = $$URL.Utils.withoutSearch(request.url);
  var meta = await getMetadata(id);
  if (meta !== undefined) {
    return Caml_option.some(meta.id);
  }
  var payload = await Prelude.PromisedResult.warn(Network.downloadRequest(request));
  if (payload === undefined) {
    return ;
  }
  var r = await store(Caml_option.some(id), Caml_option.some(url), compressed, Caml_option.valFromOption(payload));
  return Caml_option.some(r.id);
}

function download(id, url, compressed) {
  return downloadRequest(id, Network.$$Request.make(url), compressed);
}

async function compressWith(key, fn) {
  var match = await getWithMetadata(key);
  var resource = match[1];
  var meta = match[0];
  if (resource === undefined) {
    return ;
  }
  if (meta !== undefined) {
    if (meta.compressed) {
      return resource;
    }
    var blob = await Prelude.PromisedResult.mapWithDefault(fn(resource.file), resource.file, (function (prim) {
            return prim;
          }));
    await store(Caml_option.some(key), meta.file, true, blob);
    return {
            id: key,
            file: blob
          };
  }
  var blob$1 = await Prelude.PromisedResult.mapWithDefault(fn(resource.file), resource.file, (function (prim) {
          return prim;
        }));
  await store(Caml_option.some(key), undefined, true, blob$1);
  return {
          id: key,
          file: blob$1
        };
}

function _getKeyRange(ids) {
  var min = Prelude.$$Array.firstUnsafe(ids);
  var max = Prelude.$$Array.lastUnsafe(ids);
  if (Caml_obj.equal(min, max)) {
    return IDBKeyRange.only(min);
  } else {
    return IDBKeyRange.bound(min, max, false, false);
  }
}

function _checkStore(transaction, storeName, ids, progress) {
  return new Promise((function (resolve, reject) {
                var store = transaction.objectStore(storeName);
                var range = _getKeyRange(ids);
                var request = store.openKeyCursor(Caml_option.some(range), "nextunique");
                var results = ids.map(function (id) {
                      return [
                              id,
                              false
                            ];
                    });
                var max = results.length;
                Prelude.OptionExported.$$Option.$$do(progress, (function (fn) {
                        fn(0, max);
                      }));
                request.onsuccess = (function ($$event) {
                    var cursor = $$event.target.result;
                    if (cursor == null) {
                      return resolve({
                                  TAG: "Ok",
                                  _0: results
                                });
                    }
                    var value = cursor.key;
                    var pos = ids.indexOf(value);
                    if (pos !== -1) {
                      Prelude.OptionExported.$$Option.$$do(progress, (function (fn) {
                              fn(pos, max);
                            }));
                      var item = results[pos];
                      if (Caml_obj.notequal(item, [
                              value,
                              false
                            ])) {
                        console.error("LocalFile", "Incorrect value", {
                              value: value,
                              item: item
                            });
                        throw {
                              RE_EXN_ID: Prelude.AssertionError,
                              _1: "Unexpected repeated value.",
                              Error: new Error()
                            };
                      }
                      results.splice(pos, 1, [
                            value,
                            true
                          ]);
                    }
                    cursor.continue();
                  });
              }));
}

async function _check(transaction, ids, progress) {
  var match = await Promise.all([
        _checkStore(transaction, "resources", ids, progress),
        _checkStore(transaction, "metadatas", ids, undefined)
      ]);
  var metadatas = match[1];
  var resources = match[0];
  if (resources.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: resources._0
          };
  }
  if (metadatas.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: metadatas._0
          };
  }
  var metadatas$1 = metadatas._0;
  var results = resources._0.map(function (param, index) {
        return [
                param[0],
                param[1] && metadatas$1[index][1]
              ];
      });
  return {
          TAG: "Ok",
          _0: results
        };
}

async function check(ids, progress) {
  if (ids.length === 0) {
    return {
            TAG: "Ok",
            _0: []
          };
  }
  var ids$1 = UUID.SortArray.stableSort(UUID.$$Set.toArray(UUID.$$Set.fromArray(ids)));
  var transaction = Database.Patterns.transaction([
        "metadatas",
        "resources"
      ], "readonly");
  if (transaction.TAG === "Ok") {
    return await _check(transaction._0, ids$1, progress);
  } else {
    return {
            TAG: "Error",
            _0: transaction._0
          };
  }
}

function UUID_fromString(prim) {
  return prim;
}

function UUID_toString(prim) {
  return prim;
}

function UUID_manyFromString(prim) {
  return prim;
}

function UUID_manyToString(prim) {
  return prim;
}

var UUID_make = UUID.make;

var UUID_null = UUID.$$null;

var UUID_zero = UUID.zero;

var UUID_Option = UUID.$$Option;

var UUID_Comparator = UUID.Comparator;

var UUID_Hasher = UUID.Hasher;

var UUID_Map = UUID.$$Map;

var UUID_Set = UUID.$$Set;

var UUID_MutableSet = UUID.MutableSet;

var UUID_Route = UUID.Route;

var UUID_Array = UUID.$$Array;

var UUID_SortArray = UUID.SortArray;

var UUID$1 = {
  fromString: UUID_fromString,
  toString: UUID_toString,
  manyFromString: UUID_manyFromString,
  manyToString: UUID_manyToString,
  make: UUID_make,
  $$null: UUID_null,
  zero: UUID_zero,
  $$Option: UUID_Option,
  Comparator: UUID_Comparator,
  Hasher: UUID_Hasher,
  $$Map: UUID_Map,
  $$Set: UUID_Set,
  MutableSet: UUID_MutableSet,
  Route: UUID_Route,
  $$Array: UUID_Array,
  SortArray: UUID_SortArray
};

var Database_disconnect = Database.disconnect;

var Database_drop = Database.drop;

var Database_connect = Database.connect;

var Database$1 = {
  disconnect: Database_disconnect,
  drop: Database_drop,
  connect: Database_connect
};

var Metadata = {
  make: (function (prim0, prim1, prim2, prim3) {
      return {
              id: prim0,
              size: prim1,
              compressed: prim2,
              file: prim3
            };
    })
};

var Resource = {
  make: (function (prim0, prim1) {
      return {
              id: prim0,
              file: prim1
            };
    })
};

var QueryDef$1 = {};

var Query$1 = {};

var Liveness = {
  check: check
};

export {
  UUID$1 as UUID,
  Database$1 as Database,
  MetadataDef ,
  Metadata ,
  ResourceDef ,
  Resource ,
  QueryDef$1 as QueryDef,
  Query$1 as Query,
  deleteMany ,
  allIds ,
  clean ,
  getMetadata ,
  getManyResources ,
  getResource ,
  getWithMetadata ,
  store ,
  clone ,
  updateFileURL ,
  downloadRequest ,
  download ,
  compressWith ,
  Liveness ,
}
/* UUID Not a pure module */
