// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as SectionId from "../../vessel/SectionId.js";
import * as NewInspection from "../../survey/NewInspection.js";
import * as ReviewUpload__SectionAccordion from "./ReviewUpload__SectionAccordion.js";

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    var ratingStatus = vnode.attrs.ratingStatus;
                    var inspections = vnode.attrs.inspections;
                    var inspectionsOfSection = SectionId.$$Array.getter(inspections, NewInspection.bySection);
                    var newrecord = Caml_obj.obj_dup(Query.makeRead());
                    Prelude.thenDo(Query.read((newrecord.sections = {
                                TAG: "In",
                                _0: inspections.map(NewInspection.bySection)
                              }, newrecord)), (function (param) {
                            Mithril.setState(vnode, {
                                  TAG: "Loaded",
                                  sectionsOfRatingStatus: param.sections,
                                  ratingStatus: ratingStatus,
                                  inspectionsOfSection: inspectionsOfSection
                                });
                          }));
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var sectionsOfRatingStatus = match.sectionsOfRatingStatus;
                if (sectionsOfRatingStatus.length === 0) {
                  return Mithril.empty;
                }
                var inspectionsOfSection = match.inspectionsOfSection;
                var description = NewInspection.RatingStatus.description(match.ratingStatus);
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsxs("div", {
                                    children: [
                                      ReactDOM.jsx("h5", {
                                            children: description.text,
                                            className: "text-" + description.color
                                          }),
                                      [sectionsOfRatingStatus.map(function (section) {
                                              var id = description.text + "_" + section.id;
                                              return React.jsxKeyed(ReviewUpload__SectionAccordion.make, {
                                                          id: id,
                                                          section: section,
                                                          inspections: inspectionsOfSection(section.id)
                                                        }, id, undefined);
                                            })]
                                    ],
                                    className: "ratingState card card-shadow p-2"
                                  }),
                              ReactDOM.jsx("div", {
                                    className: "divider blank"
                                  })
                            ]
                          });
              }));
}

var SectionAccordion;

export {
  SectionAccordion ,
  make ,
}
/* Query Not a pure module */
