// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as ListLayout from "../../common/views/ListLayout.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReviewUpload__UserProfiles from "./ReviewUpload__UserProfiles.js";
import * as ReviewUpload__CompanyFormsCard from "./ReviewUpload__CompanyFormsCard.js";
import * as ReviewUpload__FutureAttachment from "./ReviewUpload__FutureAttachment.js";
import * as ReviewUpload__NotificationsRead from "./ReviewUpload__NotificationsRead.js";
import * as ReviewUpload__CommentsStatusCard from "./ReviewUpload__CommentsStatusCard.js";
import * as ReviewUpload__InspectionPreviewCard from "./ReviewUpload__InspectionPreviewCard.js";
import * as ReviewUpload__LevelAnswersStatusCard from "./ReviewUpload__LevelAnswersStatusCard.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                var onConfirmUpload = match.onConfirmUpload;
                var requests = match.requests;
                var tmp;
                if (requests.length !== 0) {
                  var disabled = !ReviewUpload__UserProfiles.valid(requests) || !ReviewUpload__InspectionPreviewCard.valid(requests) || !ReviewUpload__LevelAnswersStatusCard.valid(requests) || !ReviewUpload__CommentsStatusCard.valid(requests) || !ReviewUpload__NotificationsRead.valid(requests) || !ReviewUpload__CompanyFormsCard.valid(requests) || !ReviewUpload__FutureAttachment.valid(requests);
                  tmp = ReactDOM.jsxs("button", {
                        children: [
                          React.jsx(Feather.make, {
                                icon: "upload"
                              }),
                          "Upload"
                        ],
                        className: "btn btn-block btn-success btn-shadow",
                        disabled: disabled,
                        onClick: (function (param) {
                            onConfirmUpload();
                          })
                      });
                } else {
                  tmp = Mithril.empty;
                }
                return React.jsx(ListLayout.make, {
                            children: requests.length !== 0 ? ReactDOM.jsxs("div", {
                                    children: [
                                      React.jsx(ReviewUpload__InspectionPreviewCard.make, {
                                            requests: requests
                                          }),
                                      React.jsx(ReviewUpload__LevelAnswersStatusCard.make, {
                                            requests: requests
                                          }),
                                      React.jsx(ReviewUpload__CommentsStatusCard.make, {
                                            requests: requests
                                          }),
                                      React.jsx(ReviewUpload__NotificationsRead.make, {
                                            requests: requests
                                          }),
                                      React.jsx(ReviewUpload__CompanyFormsCard.make, {
                                            requests: requests
                                          }),
                                      React.jsx(ReviewUpload__UserProfiles.make, {
                                            requests: requests
                                          }),
                                      React.jsx(ReviewUpload__FutureAttachment.make, {
                                            requests: requests
                                          })
                                    ]
                                  }) : ReactDOM.jsxs("div", {
                                    children: [
                                      ReactDOM.jsxs("div", {
                                            children: [
                                              React.jsx(Feather.make, {
                                                    icon: "upload",
                                                    className: "p-centered",
                                                    size: 45
                                                  }),
                                              ReactDOM.jsx("div", {
                                                    className: "divider blank xl"
                                                  }),
                                              ReactDOM.jsx("h4", {
                                                    children: "There is nothing to upload",
                                                    className: "text-gray text-bold"
                                                  })
                                            ],
                                            className: "text-center text-gray"
                                          }),
                                      ReactDOM.jsx("div", {})
                                    ],
                                    style: {
                                      marginTop: "50%"
                                    }
                                  }),
                            title: "Upload preview",
                            showBackButton: false,
                            overNavbar: Caml_option.some(tmp),
                            overNavbarHeight: "2rem",
                            showSettings: true,
                            section: "send"
                          });
              }));
}

export {
  make ,
}
/* React Not a pure module */
