// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppSchema from "../../AppSchema.js";
import * as DocumentsAPI from "../endpoints/DocumentsAPI.js";
import * as MarkdownDocumentId from "../../markdown/MarkdownDocumentId.js";
import * as ServerSyncPipeline from "../common/ServerSyncPipeline.js";

function $$do(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var docIds = MarkdownDocumentId.MutableSet.fromArray(Curry._2(Prelude.$$Array.concat, Curry._2(Prelude.$$Array.concat, Curry._2(Prelude.$$Array.keepMap, AppSchema.Sections.all($$final), (function (s) {
                      return s.guidance_document;
                    })), Curry._2(Prelude.$$Array.keepMap, AppSchema.Levels.all($$final), (function (l) {
                      return l.guidance_document_id;
                    }))), Curry._2(Prelude.$$Array.keepMap, AppSchema.Questions.all($$final), (function (l) {
                  return l.guidance_document_id;
                }))));
  var docIds$1 = MarkdownDocumentId.MutableSet.toArray(docIds);
  if (Prelude.$$Array.isEmpty(docIds$1)) {
    return Promise.resolve({
                TAG: "Ok",
                _0: {
                  user: user,
                  vessels: vessels,
                  initial: initial,
                  final: $$final
                }
              });
  } else {
    return Prelude.PromisedResult.map(Prelude.PromisedResult.map(DocumentsAPI.getMany(undefined, undefined, user, docIds$1), (function (extra) {
                      return AppSchema.Documents.updateMany($$final, extra);
                    })), (function ($$final) {
                  return {
                          user: user,
                          vessels: vessels,
                          initial: initial,
                          final: $$final
                        };
                }));
  }
}

var ReadServerState = {
  $$do: $$do
};

function $$do$1(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var sections = AppSchema.Sections.all(initial);
  var docIds = Curry._2(Prelude.$$Array.keepMap, sections, (function (s) {
          return s.guidance_document;
        }));
  if (docIds.length === 0) {
    return Promise.resolve({
                TAG: "Ok",
                _0: {
                  user: user,
                  vessels: vessels,
                  initial: initial,
                  final: $$final
                }
              });
  }
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  return Prelude.PromisedResult.map(Query.Safe.read((newrecord.documents = {
                    TAG: "In",
                    _0: docIds
                  }, newrecord)), (function (param) {
                Curry._2(Prelude.$$Array.forEach, param.documents, (function (extra) {
                        return AppSchema.Documents.update(initial, extra);
                      }));
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var FillInitialStatus = {
  $$do: $$do$1
};

function $$do$2(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  newrecord.documents = AppSchema.Documents.getActions(initial, $$final);
  Query.ActionLogging.info("ServerSyncDocuments" + ": db actions: ", newrecord);
  return Prelude.PromisedResult.map(Query.Safe.write(newrecord), (function (param) {
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var UpdateDB = {
  $$do: $$do$2
};

var ApplyLocallyKnownState = ServerSyncPipeline.MakeNoOpPipelineStep({});

var views = [AppSchema.Documents];

var Views = {
  views: views
};

var CollectFiles = ServerSyncPipeline.MakeFilesCollector(Views);

var CleanUpState = ServerSyncPipeline.MakeStandardCleanup(Views);

var include = ServerSyncPipeline.MakeStandardAPIPipeline({
      name: "ServerSyncDocuments",
      ReadServerState: ReadServerState,
      FillInitialStatus: FillInitialStatus,
      ApplyLocallyKnownState: ApplyLocallyKnownState,
      UpdateDB: UpdateDB,
      CollectFiles: CollectFiles,
      CleanUpState: CleanUpState
    });

var $$do$3 = include.$$do;

export {
  ReadServerState ,
  FillInitialStatus ,
  UpdateDB ,
  $$do$3 as $$do,
}
/* include Not a pure module */
